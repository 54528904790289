import React, { FC } from 'react'

interface PrivacyProps {
  className?: string
}

const Privacy: FC<PrivacyProps> = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className={className}>
    <g fill="none" fillRule="evenodd">
      <g>
        <g transform="translate(-1064 -1372) translate(1032 1345)">
          <g>
            <path d="M0 0H24V24H0z" transform="translate(32 27)" />
            <path
              fill="currentColor"
              fillRule="nonzero"
              d="M12 3.19l7 3.11V11c0 4.52-2.98 8.69-7 9.93-4.02-1.24-7-5.41-7-9.93V6.3l7-3.11M12 1L3 5v6c0 5.55 3.84 10.74 9 12 5.16-1.26 9-6.45 9-12V5l-9-4zm-1 6h2v2h-2V7zm0 4h2v6h-2v-6z"
              transform="translate(32 27)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default Privacy
