import React, { FC } from 'react'

interface Props {
  className?: string
}

const Svg: FC<Props> = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1280" height="824" viewBox="0 0 1280 824" className={className}>
    <g fill="none" fillRule="evenodd" opacity="1">
      <g stroke="currentColor" strokeWidth="1.2">
        <g>
          <g>
            <g>
              <path
                d="M472.278 72.546S547.228.052 666.708.052c101.397 0 170.91 34.81 195.998 102.599 24.513 66.169 56.918 127.634 176.556 97.738 149.847-37.423 240.32 14.582 240.32 14.582"
                transform="translate(71 -20) translate(200 335) translate(-271 -315)"
              />
              <path
                d="M449.542 101.24s74.95-72.494 194.43-72.494c101.397 0 170.91 34.81 195.999 102.599 24.46 66.17 56.865 127.634 176.555 97.738 149.847-37.423 240.32 14.582 240.32 14.582"
                transform="translate(71 -20) translate(200 335) translate(-271 -315)"
              />
              <path
                d="M426.754 129.986s74.95-72.493 194.43-72.493c101.397 0 170.91 34.81 195.999 102.598 24.46 66.17 56.865 127.635 176.555 97.738 149.847-37.422 240.32 14.583 240.32 14.583"
                transform="translate(71 -20) translate(200 335) translate(-271 -315)"
              />
              <path
                d="M404.018 158.68s74.95-72.493 194.43-72.493c101.397 0 170.91 34.81 195.999 102.599 24.46 66.169 56.865 127.634 176.555 97.737 149.847-37.422 240.32 14.583 240.32 14.583"
                transform="translate(71 -20) translate(200 335) translate(-271 -315)"
              />
              <path
                d="M381.23 187.427s74.95-72.494 194.43-72.494c101.397 0 170.91 34.81 195.999 102.6 24.46 66.168 56.865 127.633 176.555 97.737 149.847-37.423 240.32 14.582 240.32 14.582"
                transform="translate(71 -20) translate(200 335) translate(-271 -315)"
              />
              <path
                d="M358.442 216.12s74.95-72.492 194.43-72.492c101.397 0 170.91 34.809 195.999 102.598 24.46 66.17 56.865 127.634 176.555 97.738 149.847-37.423 240.32 14.582 240.32 14.582"
                transform="translate(71 -20) translate(200 335) translate(-271 -315)"
              />
              <path
                d="M335.706 244.867s74.95-72.493 194.43-72.493c101.397 0 170.911 34.81 195.999 102.599C750.595 341.142 783 402.607 902.69 372.71c149.847-37.422 240.32 14.583 240.32 14.583"
                transform="translate(71 -20) translate(200 335) translate(-271 -315)"
              />
              <path
                d="M312.918 273.561s74.95-72.493 194.43-72.493c101.397 0 170.91 34.81 195.999 102.599 24.46 66.169 56.865 127.634 176.555 97.738 149.847-37.423 240.32 14.582 240.32 14.582"
                transform="translate(71 -20) translate(200 335) translate(-271 -315)"
              />
              <path
                d="M290.182 302.308s74.95-72.493 194.43-72.493c101.397 0 170.911 34.809 195.999 102.598 24.46 66.222 56.866 127.634 176.555 97.738 149.847-37.423 240.32 14.582 240.32 14.582"
                transform="translate(71 -20) translate(200 335) translate(-271 -315)"
              />
              <path
                d="M267.394 331.002s74.95-72.493 194.43-72.493c101.397 0 170.911 34.81 195.999 102.598 24.46 66.17 56.865 127.635 176.555 97.738 149.847-37.422 240.32 14.583 240.32 14.583"
                transform="translate(71 -20) translate(200 335) translate(-271 -315)"
              />
              <path
                d="M244.658 359.748s74.95-72.493 194.43-72.493c101.397 0 170.911 34.81 195.999 102.599 24.46 66.169 56.866 127.634 176.555 97.738 149.848-37.423 240.32 14.582 240.32 14.582"
                transform="translate(71 -20) translate(200 335) translate(-271 -315)"
              />
              <path
                d="M221.87 388.495s74.95-72.493 194.43-72.493c101.397 0 170.911 34.809 195.999 102.598 24.46 66.17 56.866 127.634 176.555 97.738 149.848-37.423 240.32 14.582 240.32 14.582"
                transform="translate(71 -20) translate(200 335) translate(-271 -315)"
              />
              <path
                d="M199.134 417.19s74.95-72.494 194.43-72.494c101.397 0 170.911 34.81 196 102.598 24.46 66.17 56.865 127.635 176.554 97.738 149.848-37.422 240.32 14.583 240.32 14.583"
                transform="translate(71 -20) translate(200 335) translate(-271 -315)"
              />
              <path
                d="M176.346 445.935s74.95-72.493 194.43-72.493c101.397 0 170.911 34.81 195.999 102.599 24.46 66.169 56.866 127.634 176.555 97.738 149.848-37.423 240.32 14.582 240.32 14.582"
                transform="translate(71 -20) translate(200 335) translate(-271 -315)"
              />
              <path
                d="M153.61 474.63s74.95-72.494 194.43-72.494c101.397 0 170.911 34.81 196 102.599 24.46 66.169 56.865 127.634 176.555 97.738 149.847-37.423 240.32 14.582 240.32 14.582"
                transform="translate(71 -20) translate(200 335) translate(-271 -315)"
              />
              <path
                d="M136.938 508.446s74.95-72.493 194.43-72.493c101.396 0 170.91 34.809 195.998 102.598 24.46 66.17 56.866 127.634 176.556 97.738 149.847-37.423 240.32 14.582 240.32 14.582"
                transform="translate(71 -20) translate(200 335) translate(-271 -315)"
              />
              <path
                d="M114.15 537.14s74.95-72.493 194.43-72.493c101.396 0 170.91 34.81 195.998 102.598 24.46 66.17 56.866 127.635 176.556 97.738 149.9-37.422 240.32 14.583 240.32 14.583"
                transform="translate(71 -20) translate(200 335) translate(-271 -315)"
              />
              <path
                d="M91.414 565.886s74.95-72.493 194.43-72.493c101.397 0 170.91 34.81 195.998 102.599 24.461 66.169 56.866 127.634 176.556 97.738 149.847-37.423 240.32 14.582 240.32 14.582"
                transform="translate(71 -20) translate(200 335) translate(-271 -315)"
              />
              <path
                d="M68.626 594.58s74.95-72.493 194.43-72.493c101.396 0 170.91 34.81 195.998 102.599 24.46 66.169 56.866 127.634 176.556 97.738C785.457 685 875.93 737.006 875.93 737.006"
                transform="translate(71 -20) translate(200 335) translate(-271 -315)"
              />
              <path
                d="M45.89 623.327s74.95-72.493 194.43-72.493c101.397 0 170.91 34.81 195.998 102.598 24.461 66.17 56.866 127.635 176.556 97.738 149.847-37.422 240.32 14.583 240.32 14.583"
                transform="translate(71 -20) translate(200 335) translate(-271 -315)"
              />
              <path
                d="M23.102 652.021s74.95-72.493 194.43-72.493c101.397 0 170.91 34.81 195.998 102.599 24.461 66.169 56.866 127.634 176.556 97.737 149.847-37.422 240.32 14.583 240.32 14.583"
                transform="translate(71 -20) translate(200 335) translate(-271 -315)"
              />
              <path
                d="M.366 680.768s74.95-72.494 194.43-72.494c101.397 0 170.91 34.81 195.999 102.599 24.46 66.17 56.865 127.634 176.555 97.738 149.847-37.423 240.32 14.582 240.32 14.582"
                transform="translate(71 -20) translate(200 335) translate(-271 -315)"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default Svg
