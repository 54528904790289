import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import cx from 'classnames'
import Alert from 'components/Alert'
import { Link } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import { alertState } from 'recoil/alert'

import styles from './GlobalAlert.module.scss'

const visibleDurationMs = 5000
const transitionDurationMs = 250

export const GlobalAlert: FC = () => {
  const [isVisible, setIsVisible] = useState<boolean>(false)
  const [alert, setAlert] = useRecoilState(alertState)
  const alertId = useRef<number | string>()

  useEffect(() => {
    let didCancel = false
    if (alert?.message) {
      alertId.current = alert.id
      setTimeout(() => {
        didCancel || setIsVisible(true)
      }, 10)
      setTimeout(() => {
        didCancel || setIsVisible(false)
      }, visibleDurationMs)
      setTimeout(() => {
        // if id hasn't changed since 5 seconds ago, clear it, so it doesn't show on next url navigation
        if (alertId.current === alert.id && !didCancel) setAlert(null)
      }, visibleDurationMs + transitionDurationMs)
    }
    return () => {
      didCancel = true
    }
  }, [alert, setAlert])

  const handleCtaClick = useCallback(() => {
    setIsVisible(false)
  }, [])

  if (!alert) return null

  return (
    <div className={styles['global-alert']}>
      <div
        className={cx(styles['global-alert__alert'], {
          [styles['global-alert__alert--visible']]: isVisible,
        })}
      >
        <Alert className={styles.alert} type={alert.type}>
          <div className="tw-flex tw-justify-between tw-gap-1">
            <div>{alert.message}</div>
            {alert.ctaText && alert.ctaTo ? (
              <Link onClick={handleCtaClick} to={alert.ctaTo}>
                {alert.ctaText}
              </Link>
            ) : null}
          </div>
        </Alert>
      </div>
    </div>
  )
}

export default GlobalAlert
