import { useEffect, useCallback } from 'react'
import { atom, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import type { LocationDescriptor, Location } from 'history'
import { useParams, useRouteMatch, useLocation, generatePath, useHistory } from 'react-router-dom'

import sharedAction from 'utils/sharedAction'
import propertyPath from 'utils/propertyPath'

import PropertyPlanApi from 'apis/propertyPlanApi'

import { useProperties } from './properties'
import { listProjects, projectsState } from './projects'
import {
  listProjectTemplates,
  projectTemplatesState,
  projectPartsState,
  extractParts,
  ProjectTemplate,
  ProjectPart,
} from './projectTemplates'

export interface PropertyPlan {
  id: number
  property_id: number
  name: string
  score: number
  estimated_value: number
  square_footage: number
  cost_estimate: number
  cost_estimate_low: number
  cost_estimate_high: number
}

export interface CPropertyPlan {
  name?: string
}

export interface UPropertyPlan {
  name?: string
}

export const propertyPlansState = atom<Array<PropertyPlan | null> | null>({
  key: 'PropertyPlans',
  default: null,
})

const loadedPropertyPlanIdState = atom<number | null>({
  key: 'LoadedPropertyPlanId',
  default: null,
})

const lastSelectedPropertyPlanIdState = atom<number | null>({
  key: 'LastSelectedPropertyPlanIdState',
  default: null,
})

// Similar to a 'loading' state, but allows an outside actor
// to keep the usePropertyPlan in a 'loading' state
// until they're ready to release it.
// This state is used by usePropertyPlans implementors
// and not by usePropertyPlanLoader implementors.
const propertyPlanIsInvalidState = atom<number>({
  key: 'PropertyPlan_isInvalid',
  default: 0,
})

function constructRedirect(path: string | null, location: Location, action: string): LocationDescriptor | null {
  if (!path) return null
  let redirect: LocationDescriptor = {
    pathname: path,
    search: location.search,
    hash: location.hash,
  }
  if (location.state || action) {
    redirect = { ...redirect, state: Object.assign({ redirectOriginalAction: action }, location.state) }
  }
  return redirect
}

// Separate out accessing property plan and plans
// from the loader, so the loader doesn't need to be accessed in
// all components (which can cause performance issues)
const useGetPropertyPlans = (): {
  propertyPlans: Array<PropertyPlan | null> | null
  propertyPlan: PropertyPlan | null
  selectedPropertyPlanId: string
  isLoading: boolean
} => {
  const params = useParams<{ propertyPlanId: string }>()
  const propertyPlans = useRecoilValue(propertyPlansState)
  const lastSelectedPropertyPlanId = useRecoilValue(lastSelectedPropertyPlanIdState)
  const loadedPropertyPlanId = useRecoilValue(loadedPropertyPlanIdState)
  const selectedPropertyPlanId = params.propertyPlanId
  // Make sure propertyPlanId Is ok and valid.
  const propertyPlanId = selectedPropertyPlanId ? parseInt(selectedPropertyPlanId) : lastSelectedPropertyPlanId
  let propertyPlan = !isNaN(propertyPlanId || 0) ? propertyPlans?.find((p) => p?.id == propertyPlanId) : null
  // No current property plan? Then pick the first one in the list.
  // We should always have at least one.
  if (!propertyPlan) {
    propertyPlan = propertyPlans && propertyPlans[0]
  }

  return {
    propertyPlans,
    propertyPlan,
    selectedPropertyPlanId,
    isLoading: loadedPropertyPlanId != propertyPlan?.id,
  }
}

export const usePropertyPlanLoader = (): {
  isLoading: boolean
  propertyPlan: PropertyPlan | null
  redirect: LocationDescriptor | null
} => {
  const match = useRouteMatch()
  const location = useLocation()
  const history = useHistory()
  const { propertyPlan, selectedPropertyPlanId, isLoading } = useGetPropertyPlans()
  const { selectedProperty: property, properties } = useProperties()
  const [loadedPropertyPlanId, setLoadedPropertyPlanId] = useRecoilState(loadedPropertyPlanIdState)
  const setLastSelectedPropertyPlanId = useSetRecoilState(lastSelectedPropertyPlanIdState)
  const setProjects = useSetRecoilState(projectsState)
  const setProjectParts = useSetRecoilState(projectPartsState)
  const setProjectTemplates = useSetRecoilState(projectTemplatesState)

  const propertyPlanId = propertyPlan?.id

  // We will only need to redirect if our propertyPlanId isn't what
  // we said it should be.
  let redirect: string | null = null
  if (property && propertyPlanId && selectedPropertyPlanId != `${propertyPlanId}`) {
    redirect = generatePath(
      `${propertyPath(properties || [], property)}/property-plans/:propertyPlanId`,
      Object.assign({}, match.params, { propertyPlanId: propertyPlanId })
    )
  }

  // Any time propertyPlanId doesn't match the loadedPropertyPlanId,
  // we need to 'load' the propertyPlan and its resources.
  // Make sure we wait until after we've redirected though...
  useEffect(() => {
    if (!property) return
    let cancel = false
    if (loadedPropertyPlanId != propertyPlanId && !redirect) {
      console.debug(`loaded propertyPlan id: [${loadedPropertyPlanId}], propertyPlan id: [${propertyPlanId}]`)
      ;(async () => {
        if (cancel) return

        // Clear out any propertyPlan specific info
        setProjects(null)
        setProjectParts(null)
        setProjectTemplates(null)

        // Only load things if we have a propertyId set.
        if (propertyPlanId != null) {
          const results = await Promise.all([
            sharedAction(['listProjects', property.id, propertyPlanId], () =>
              listProjects(property.id, propertyPlanId)
            ),
            sharedAction(['listProjectTemplates', property.id, propertyPlanId], () =>
              listProjectTemplates(property.id, propertyPlanId)
            ),
          ])
          if (cancel) return // Check for cancel after each async call

          // I'm not suuuper happy about this logic not being isolated.
          const allParts: {
            templates: Array<ProjectTemplate>
            parts: Array<ProjectPart>
          } = extractParts(results[1])

          setProjects(results[0])
          setProjectParts(allParts.parts)
          setProjectTemplates(allParts.templates)

          // Always update our last selected when we change.
          setLastSelectedPropertyPlanId(propertyPlanId)
          // Last update the loadedPropertyPlanId
          setLoadedPropertyPlanId(propertyPlanId)
        }
      })()
    }

    return () => {
      cancel = true
    }
  }, [
    property,
    loadedPropertyPlanId,
    propertyPlanId,
    setLoadedPropertyPlanId,
    setProjects,
    setProjectParts,
    setProjectTemplates,
    setLastSelectedPropertyPlanId,
    redirect,
  ])

  return {
    isLoading,
    propertyPlan,
    redirect: constructRedirect(redirect, location, history.action),
  }
}

export const usePropertyPlans = (): {
  propertyPlans: Array<PropertyPlan | null> | null
  selectedPropertyPlan: PropertyPlan | null
  isLoading: boolean
  isSwitching: boolean
  setIsInvalid: (isInvalid: boolean) => void
  addPropertyPlan: (propertyPlan: CPropertyPlan) => Promise<PropertyPlan | null>
  removePropertyPlan: (propertyPlanId: number) => Promise<PropertyPlan | null>
  updatePropertyPlan: (propertyPlanId: number, propertyPlan: UPropertyPlan) => Promise<PropertyPlan | null>
  refreshPropertyPlan: (propertyPlanId?: number) => Promise<void>
} => {
  const { selectedProperty } = useProperties()
  const { propertyPlan, isLoading } = useGetPropertyPlans()
  const [propertyPlans, setPropertyPlans] = useRecoilState(propertyPlansState)
  const [isInvalid, setIsInvalid] = useRecoilState(propertyPlanIsInvalidState)

  const selectedPropertyPlan = propertyPlan

  const refreshPropertyPlan = useCallback(
    async (propertyPlanId?: number) => {
      if (!selectedPropertyPlan || !selectedProperty) return
      if (!propertyPlanId) propertyPlanId = selectedPropertyPlan.id
      const refreshedPropertyPlan = await sharedAction(['getPropertyPlan', selectedProperty.id, propertyPlanId], () =>
        getPropertyPlan(selectedProperty.id, propertyPlanId || 0)
      )
      setPropertyPlans(
        (plans) => plans?.map((p) => (p?.id == refreshedPropertyPlan.id ? refreshedPropertyPlan : p)) || null
      )
    },
    [selectedProperty, selectedPropertyPlan, setPropertyPlans]
  )

  const addPropertyPlan = useCallback(
    async (newPropertyPlan: CPropertyPlan): Promise<PropertyPlan | null> => {
      if (!selectedProperty) return null
      // Call our API
      const propertyPlan = await createPropertyPlan(selectedProperty.id, newPropertyPlan)

      // Adding a new property plan doesn't need to refresh anything specific,
      // like changing a property plan would.

      // We do need to add to our collection however.
      // It is possible we have had a property plan returned that is an existing one.
      // If so, do a replacement. Otherwise, do an add.
      setPropertyPlans((plans) => {
        const newPlans = plans?.concat([]) || []
        const index = newPlans.findIndex((plan) => plan?.id == propertyPlan.id)
        if (index > -1) newPlans[index] = propertyPlan
        else newPlans.push(propertyPlan)
        return newPlans
      })

      // Return the property plan added.
      return propertyPlan
    },
    [selectedProperty, setPropertyPlans]
  )

  const removePropertyPlan = useCallback(
    async (propertyPlanId: number): Promise<PropertyPlan | null> => {
      if (!selectedProperty) return null
      // Call our API
      const propertyPlan = await deletePropertyPlan(selectedProperty.id, propertyPlanId)

      // Removing an old property plan doesn't need to refresh anything specific,
      // like changing a property plan would.

      // We do need to remove from our collection however.
      setPropertyPlans((plans) => plans?.filter((plan) => plan?.id != propertyPlan?.id) || null)

      // Return the property plan deleted.
      return propertyPlan
    },
    [selectedProperty, setPropertyPlans]
  )

  const _updatePropertyPlan = useCallback(
    async (propertyPlanId: number, uPropertyPlan: UPropertyPlan): Promise<PropertyPlan | null> => {
      if (!selectedProperty) return null
      // Call our API
      const propertyPlan = await updatePropertyPlan(selectedProperty.id, propertyPlanId, uPropertyPlan)

      // Updating a property plan doesn't need to refresh anything specific,
      // like changing the projects in a property plan would.

      // We should refresh the copy in our collection however.
      setPropertyPlans((plans) => plans?.map((plan) => (plan?.id == propertyPlan.id ? propertyPlan : plan)) || null)

      // Return the property plan added.
      return propertyPlan
    },
    [selectedProperty, setPropertyPlans]
  )

  const _setIsInvalid = useCallback(
    (isInvalid: boolean) => {
      setIsInvalid((i) => i + (isInvalid ? 1 : -1))
    },
    [setIsInvalid]
  )

  return {
    isLoading: isLoading || isInvalid > 0,
    isSwitching: isLoading,
    propertyPlans,
    selectedPropertyPlan,
    addPropertyPlan,
    removePropertyPlan,
    updatePropertyPlan: _updatePropertyPlan,
    refreshPropertyPlan,
    setIsInvalid: _setIsInvalid,
  }
}

export const getPropertyPlan = async (propertyId: number, propertyPlanId: number): Promise<PropertyPlan> => {
  const api = new PropertyPlanApi(propertyId)
  return await api.getPropertyPlan(propertyPlanId)
}

export const listPropertyPlans = async (propertyId: number): Promise<Array<PropertyPlan>> => {
  const api = new PropertyPlanApi(propertyId)
  return await api.listPropertyPlans()
}

export const createPropertyPlan = async (propertyId: number, propertyPlan: CPropertyPlan): Promise<PropertyPlan> => {
  const api = new PropertyPlanApi(propertyId)
  return await api.createPropertyPlan(propertyPlan)
}

export const updatePropertyPlan = async (
  propertyId: number,
  propertyPlanId: number,
  propertyPlan: UPropertyPlan
): Promise<PropertyPlan> => {
  const api = new PropertyPlanApi(propertyId)
  return await api.updatePropertyPlan(propertyPlanId, propertyPlan)
}

export const deletePropertyPlan = async (propertyId: number, propertyPlanId: number): Promise<PropertyPlan | null> => {
  const api = new PropertyPlanApi(propertyId)
  return await api.deletePropertyPlan(propertyPlanId)
}
