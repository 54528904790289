import React, { FC, PropsWithChildren } from 'react'
import classNames from 'classnames'

import Lines from 'svgs/finance-hub/lines'

import styles from './styles.module.scss'

interface NeedMoreDetailsCardProps {
  className?: string
}
const NeedMoreDetailsCard: FC<PropsWithChildren<NeedMoreDetailsCardProps>> = ({ children, className }) => (
  <div className={classNames(styles.needMoreDetailsCard, className)}>
    <div className={styles.patterns}>
      <Lines className={styles.lines} />
    </div>
    <div className={styles.body}>{children}</div>
  </div>
)
export default NeedMoreDetailsCard
