import { useRef, useEffect } from 'react'

/**
 * Use this for when we have async gaps in a callback
 * that updates local state and potentially causes the
 * component to unmount (this should be rare).
 *
 * Usage:
 * const isMounted = useIsMounted()
 * // Then in a callback somewhere
 * if(isMounted()) {
 *   // do state update
 * }
 *
 */
const useIsMounted: () => () => boolean = () => {
  const isMountedRef = useRef(true)
  useEffect(() => {
    return () => {
      isMountedRef.current = false
    }
  }, [isMountedRef])
  return () => isMountedRef.current
}

export default useIsMounted
