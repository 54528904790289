import cx from 'classnames'
import React, {
  FC,
  forwardRef,
  ForwardRefExoticComponent,
  ForwardRefRenderFunction,
  HTMLProps,
  PropsWithoutRef,
  RefAttributes,
} from 'react'

import styles from './styles.module.scss'

type MenuItemProps = HTMLProps<HTMLLIElement>

const MenuItem: FC<MenuItemProps> = ({ children, ...props }) => {
  return (
    <li {...props} className={styles.menuItem} role="menuitem">
      {children}
    </li>
  )
}

const MenuDivider: FC = () => {
  return <li className={styles.menuDivider} />
}

type MenuProps = {
  className?: string
} & HTMLProps<HTMLUListElement>

const InnerMenu: ForwardRefRenderFunction<HTMLUListElement, MenuProps> = ({ children, className, ...props }, ref) => {
  return (
    <ul {...props} className={cx(styles.menu, className)} ref={ref} role="menu">
      {children}
    </ul>
  )
}

type MenuType = ForwardRefExoticComponent<PropsWithoutRef<MenuProps> & RefAttributes<HTMLUListElement>> & {
  Item: FC<MenuItemProps>
  Divider: FC
}

const Menu: MenuType = forwardRef(InnerMenu) as any
Menu.Item = MenuItem
Menu.Divider = MenuDivider

export default Menu
