import React, { FC, PropsWithChildren } from 'react'
import { useLocation, Redirect } from 'react-router-dom'

import { useAuth, LoginState } from 'non-rendering/Authentication'
import { useSetRecoilState } from 'recoil'
import { alertState } from 'recoil/alert'

import Loading from 'components/Loading'

interface Props {
  loadingContent?: () => React.ReactNode
  loggedOutContent?: () => React.ReactNode
}

const Secure: FC<PropsWithChildren<Props>> = ({ loadingContent, loggedOutContent, children }) => {
  const location = useLocation()
  const { checked, state, user } = useAuth()
  const setAlertState = useSetRecoilState(alertState)

  if (!checked) {
    if (loadingContent) {
      return <>{loadingContent()}</>
    } else {
      return <Loading />
    }
  } else if (state === LoginState.LoggedOut) {
    if (loggedOutContent) {
      return <>{loggedOutContent()}</>
    } else {
      setAlertState({ type: 'WARNING', id: 'login_required', message: 'You need to be logged in to do that.' })
      // At this point, we need to redirect them to login.
      return <Redirect to="/users/sign_in" />
    }
  }
  // If they haven't onboarded a property yet, then we need to
  // force them to onboard a property
  else if (!user?.entered_address && !location.pathname.startsWith('/onboarding')) {
    // At this point, we need to redirect them to onboarding
    return <Redirect to="/onboarding?n" />
  }
  return <>{children}</>
}

export default Secure
