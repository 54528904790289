import React, { FC /*, useCallback*/ } from 'react'
import { useRouteMatch } from 'react-router-dom'

import classNames from 'classnames'

import { Property } from 'recoil/properties'
import { formatCurrency } from 'utils/formatting'

import TrendingUp from 'svgs/trending-up'

import FinancialOverviewChart from 'components/FinancialOverviewChart'
import ArrowLink from 'components/ArrowLink'
import Tooltip from 'components/Tooltip'

import styles from './styles.module.scss'

interface Props {
  className?: string
  property: Property
  monthlyOverpayment: number | null
}

function fuzzyRange(number): string {
  const lowerBound = Math.round(number - 0.1 * number)
  const upperBound = Math.round(number + 0.1 * number)
  return `${formatCurrency(lowerBound)} - ${formatCurrency(upperBound)}`
}

const EquityOverview: FC<Props> = ({ className, property, monthlyOverpayment }) => {
  const { url } = useRouteMatch()

  return (
    <div className={classNames(styles.equityOverview, className)}>
      <div className={styles.financialOverview}>
        <div className={styles.chartContainer}>
          <FinancialOverviewChart
            className={styles.chart}
            equity={property.equity_state != 'missing_data' && property.equity_state != 'no_data' ? property.equity : 0}
            total={property.estimated_value}
            isLowData={property.equity_state != 'normal'}
            radius={140}
          />
          <div className={styles.equityLabels}>
            <div className={styles.line}>
              <div
                className={classNames(styles.value, {
                  [styles.lowData]: property.equity_state == 'low_data',
                  [styles.missingData]: property.equity_state == 'missing_data' || property.equity_state == 'no_data',
                })}
              >
                {property.equity_state != 'no_data' ? formatCurrency(property.equity) : 'Unknown'}
              </div>
              <div className={styles.label}>
                {property.equity_state == 'low_data' ? 'Estimated Equity' : 'Total Equity'}
              </div>
            </div>
            <div className={styles.line}>
              <div className={styles.value}>
                {property.estimated_value != null ? formatCurrency(property.estimated_value) : 'Unknown'}
              </div>
              <div className={styles.label}>{'Home Value'}</div>
            </div>
          </div>
        </div>
        {property.equity_state !== 'no_mortgage' && (
          <div className={styles.chartSideInfo}>
            <div
              className={classNames({
                [styles.negativeVal]: property.monthly_equity_change < 0,
                [styles.missingData]: property.equity_state == 'missing_data' || property.equity_state == 'no_data',
              })}
            >
              <Tooltip
                className={styles.toolTip}
                trackAs="equity-change"
                overlay="We track how your home value is changing over time based on local market trends. Our models update daily."
              />
              <div>
                <div className={styles.label}>{'Equity Change (Last 30 days)'}</div>
                <div
                  className={classNames(styles.value, {
                    [styles.missingData]:
                      property.equity_state == 'missing_data' ||
                      property.monthly_equity_change == null ||
                      property.monthly_equity_change == 0,
                  })}
                >
                  {property.monthly_equity_change == null || property.equity_state == 'no_data'
                    ? 'Unknown'
                    : property.monthly_equity_change > 0
                    ? `+${formatCurrency(property.monthly_equity_change)}`
                    : property.monthly_equity_change < 0
                    ? `-${formatCurrency(Math.abs(property.monthly_equity_change))}`
                    : '$0'}
                  {property.monthly_equity_change != null && property.monthly_equity_change != 0 && <TrendingUp />}
                </div>
              </div>
            </div>
            <div
              className={classNames(styles.poorValue, {
                [styles.missingData]: property.equity_state == 'missing_data' || property.equity_state == 'no_data',
              })}
            >
              <Tooltip
                className={styles.toolTip}
                trackAs="monthly-mortgage-overpayment"
                overlay="We search for the most competitive interest rate available based on your financial profile. Our overpayment estimate compares your current interest rate to  the best available option we found."
              />
              <div>
                <div className={styles.label}>{'Monthly Mortgage Overpayment'}</div>
                <div
                  className={classNames(styles.value, styles.badData, {
                    [styles.missingData]: property.equity_state == 'missing_data' || property.equity_state == 'no_data',
                  })}
                >
                  {monthlyOverpayment == null
                    ? 'Unknown'
                    : property.equity_state == 'missing_data' || property.equity_state == 'low_data'
                    ? `${fuzzyRange(monthlyOverpayment)}`
                    : property.equity_state != 'no_data'
                    ? `${formatCurrency(monthlyOverpayment)}`
                    : 'Unknown'}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {property.equity_state == 'low_data' && (
        <div className={styles.lowDataNotification}>
          <h2 className={styles.noAntdh2}>{'Seeing Estimated Equity?'}</h2>
          <p>{'We may be missing important loan information, please update to see more accurate data.'}</p>
          <ArrowLink className={styles.arrowLinkEquity} to={`${url}/financial-analysis`}>
            {'Update your financial details'}
          </ArrowLink>
        </div>
      )}
    </div>
  )
}

export default EquityOverview
