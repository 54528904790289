import { useCallback, useEffect } from 'react'
import type { LocationDescriptor, Location } from 'history'
import { useParams, useRouteMatch, useLocation, generatePath, useHistory } from 'react-router-dom'
import { atom, selector, useRecoilValue, useSetRecoilState, useRecoilState } from 'recoil'
import { postJSON, postFile } from 'utils/fetch'
import { propertyPlansState, listPropertyPlans } from 'recoil/propertyPlans'
import { projectsState } from 'recoil/projects'
import { projectTemplatesState, projectPartsState, ProjectTemplateBase, ProjectTemplate } from 'recoil/projectTemplates'
import {
  baseProjectTemplatesState,
  listProjectTemplates as listBaseProjectTemplates,
} from 'recoil/baseProjectTemplates'
import { packagePurchasesState } from 'recoil/packagePurchases'
import sharedAction from 'utils/sharedAction'
import { propertyFromParam, paramFromProperty, PROPERTY_PATH } from 'utils/propertyPath'
import PropertyAPI from 'apis/propertyApi'
import { PublicPropertyComparable } from 'recoil/publicProperty'
import { TimeToStart } from 'recoil/onboarding'

export interface Attachment {
  name: string
  id: number
  download_url: string
}

export interface Photo {
  id: number
  name: string
  url: string
}

export interface PropertyDocs {
  home_appraisal: Attachment
  home_inspection_report: Attachment
  hoa_ccrs: Attachment
}

export interface RentometerPayload {
  address: string
  latitude: string
  longitude: string
  bedrooms: number
  baths: string
  building_type: string
  mean: number
  median: number
  min: number
  max: number
  percentile_25: number
  percentile_75: number
  std_dev: number
  samples: number
  radius_miles: number
  quickview_url: string
}

export interface RelativeToNeighbors {
  value_position: number | null
  potential_position: number | null
}

export type PropertyFeature =
  | 'stainless_steel_appliances'
  | 'stone_counters'
  | 'custom_cabinets'
  | 'stainless_steel_counters'
  | 'stone_floors'
  | 'tile_floors'
  | 'wood_floors'
  | 'double_vanity'
  | 'soaking_tub'
  | 'jetted_tub'
  | 'walkin_shower'
  | 'steam_shower'
  | 'fire_pit'
  | 'home_gym'
  | 'fireplace'
  | 'deck'
  | 'patio'
  | 'finished_attic'
  | 'finished_basement'

export enum HomeownerGoal {
  AddSpace = 'add_space',
  UpdateExistingSpaces = 'upgrade_existing_spaces',
  EarnRentalIncome = 'earn_rental_income',
  SetBudget = 'set_budget',
  LearnFinances = 'learn_finances',
  CompareNeighborhood = 'compare_neighborhood',

  FindContractors = 'find_contractors',
  FindProjectCosts = 'find_project_costs',
  WorkWithAdvisor = 'work_with_advisor',
  SeeHomePossibilities = 'see_home_possibilities',
}

export interface Renovation {
  id: number
  year: number
  kind: string
}

export type RealtorStatus =
  | 'this-is-my-listing'
  | 'i-have-a-buyer'
  | 'im-trying-to-win-this-client'
  | 'i-live-here'
  | 'i-sold-this-home'

export interface PropertyHead {
  id: number
  attom_id: string
  name: string
  lat: string
  long: string
  delivery_line_1: string
  delivery_line_2: string | null
  street_name: string
  county: string
  city: string
  state: string
  zip5: string
  zip9: string
  cbsa_code: string
  bedrooms: number
  total_bathrooms: number
  square_footage: number
  potential_value: number | null
  estimated_value: number
  realtor_status: RealtorStatus
  fips_county_code: number
  _isHead: boolean
  avatar?: Photo
  marketplace: boolean
}

export interface TopProject extends ProjectTemplateBase {
  sq_footage: number
  project_template: ProjectTemplate
}

export interface Alert {
  id: number
  kind: string
  image_url: string
  message: string
  payload: any
  visible_at: string
  property_id?: number
}

export interface Contractor {
  propertyaddressstate: string
  statecountyfips: string
  propertyaddresscity: string
  contractor: string
  count: string
  project_1: string
  count_1: string
  project_2: string
  count_2: string
  project_3: string
  count_3: string
}

export interface Property extends PropertyHead {
  bathrooms: number | null
  half_bathrooms: number
  year_built: number
  lot_size: number
  additional_units_allowed: number | null
  max_adu_square_footage: number | null
  garage_car_count: number | null
  monthly_overpayment: number | null
  loan_max: number
  equity: number
  equity_state: 'normal' | 'low_data' | 'missing_data' | 'no_data' | 'no_mortgage'
  monthly_equity_change: number
  best_rate_payment: number
  best_rate: number
  attachments: Attachment[]
  existing_guest_suite_or_adu: boolean
  relative_to_neighbors: RelativeToNeighbors | null
  renovation_year: number
  average_nearby_renovation_year: number
  parcel_geometry_geom: GeoJSON.MultiPolygon | null
  primary_buildable_area_geom: GeoJSON.Polygon | null
  score: number | null
  qualification_status: string | null
  photos: Photo[]
  blended_mortgage: {
    monthly_payment: number | null
    principle: number | null
    rate: number | null
    term: number | null
  }
  top_projects: Array<TopProject>
  zoned_buildable_area: {
    remaining_square_footage: number | null
    allowed_floors: Array<number>
    caveats: Array<string>
  }
  number_of_stories: number
  hoa_name: string
  pool: 0 | 1
  docs: PropertyDocs
  version: number
  basement_square_footage: number | null
  features: PropertyFeature[]
  renovations: Renovation[]
  open_home_goal?: string
  home_goals: HomeownerGoal[]
  edit_state?: {
    dirty: boolean
    original_avm: number
  }
  updated_at: Date
  backyard_gym: boolean
  backyard_home_office: boolean
  deck: boolean
  fencing: boolean
  firepit: boolean
  hvac_central_air: boolean
  hvac_heating: boolean
  landscaping: boolean
  outdoor_kitchen: boolean
  solar: boolean
  water_heater: boolean
  attic: 0 | 1
  finished_attic: 0 | 1
  basement: 0 | 1
  finished_basement: 0 | 1
  activity: Alert[]
  comparables: PublicPropertyComparable[]
  contractors: Contractor[]
  fips_county_code: number
}

export interface CProperty {
  place_id?: string
  attom_id?: string
  property_id?: string
}

export enum CreatePropertyErrorType {
  CreateStartError,
  MissingPollToken,
  PollTimeout,
  PollError,
  RetrievalFailure,
  UnservicableProperty,
}

export interface UProperty {
  name?: string
  bedrooms?: number
  bathrooms?: number
  existing_guest_suite_or_adu?: boolean
  half_bathrooms?: number
  square_footage?: number
  number_of_stories?: number
  garage_car_count?: number
  hoa_name?: string
  lot_size?: number
  pool?: number
  backyard_gym?: boolean
  backyard_home_office?: boolean
  deck?: boolean
  fencing?: boolean
  firepit?: boolean
  hvac_central_air?: boolean
  hvac_heating?: boolean
  landscaping?: boolean
  outdoor_kitchen?: boolean
  solar?: boolean
  water_heater?: boolean
  attic?: number
  finished_attic?: number
  basement?: number
  finished_basement?: number
  features?: PropertyFeature[]
  home_goals?: HomeownerGoal[] | TimeToStart[]
  open_home_goal?: string
}

export const propertiesState = atom<Array<PropertyHead | Property> | null>({
  key: 'Properties',
  default: null,
})

export const propertiesErrorState = atom<any>({
  key: 'propertiesError',
  default: null,
})

const loadedPropertyIdState = atom<number | null>({
  key: 'LoadedPropertyId',
  default: null,
})

const lastPropertyState = atom<number | null>({
  key: 'LastPropertyId',
  default: null,
  effects_UNSTABLE: [
    ({ setSelf, onSet }) => {
      const key = 'lastPropertyId'
      const savedValue = localStorage.getItem(key)
      if (savedValue != null) {
        let nValue: number | null = parseInt(savedValue)
        if (isNaN(nValue)) nValue = null
        setSelf(nValue)
      }

      onSet((newValue) => {
        if (newValue == null) {
          localStorage.removeItem(key)
        } else {
          localStorage.setItem(key, newValue + '')
        }
      })
    },
  ],
})

function constructRedirect(path: string | null, location: Location, action: string): LocationDescriptor | null {
  if (!path) return null
  let redirect: string | LocationDescriptor = path
  // Preserve querystring
  if (location.search) redirect += location.search
  // And hash
  if (location.hash) redirect += location.hash
  // And state
  if (location.state || action) {
    redirect = {
      pathname: redirect,
      state: Object.assign({ redirectOriginalAction: action }, location.state),
    }
  }
  return redirect
}

export const usePropertyLoader = (): {
  isLoading: boolean
  property: Property | PropertyHead | null
  redirect: LocationDescriptor | null
} => {
  const { propertyId: sPropertyId } = useParams<{ propertyId: string }>()
  const match = useRouteMatch()
  const location = useLocation()
  const history = useHistory()
  const [properties, setProperties] = useRecoilState(propertiesState)
  const [loadedPropertyId, setLoadedPropertyId] = useRecoilState(loadedPropertyIdState)
  const setReportPurchases = useSetRecoilState(packagePurchasesState)
  const setPropertyPlans = useSetRecoilState(propertyPlansState)
  const setProjects = useSetRecoilState(projectsState)
  const setProjectParts = useSetRecoilState(projectPartsState)
  const setProjectTemplates = useSetRecoilState(projectTemplatesState)
  const setBaseProjectTemplates = useSetRecoilState(baseProjectTemplatesState)
  const setLastPropertyId = useSetRecoilState(lastPropertyState)

  // Make sure propertyId Is ok and valid.
  let property = propertyFromParam(properties || [], sPropertyId)

  let redirect: string | null = null

  // If we didn't find a property, check if they tried to load with a
  // property Id, and update the redirect path accordingly.
  if (!property) {
    const propertyId = parseInt(sPropertyId)
    if (!isNaN(propertyId)) {
      property = (properties || []).find((p) => p.id == propertyId) || null
      if (property) {
        // Use our addr as the id, and suggest the redirect.
        redirect = generatePath(
          match.path,
          Object.assign({}, match.params, { propertyId: paramFromProperty(properties || [], property) })
        )
      }
    }
  }
  // If we have a property, and it is a single property,
  // ensure our path is a single property path.
  if (property && (properties || []).length == 1) {
    if (match.path.startsWith(PROPERTY_PATH)) {
      redirect = generatePath(match.path.replace(PROPERTY_PATH, ''), match.params)
    }
  }

  const propertyId = property?.id

  // Any time propertyId doesn't match the loadedPropertyId,
  // we need to 'load' the property and its resources
  useEffect(() => {
    let cancel = false
    if (loadedPropertyId != propertyId) {
      console.debug(`loaded property id: [${loadedPropertyId}], property id: [${propertyId}]`)
      ;(async () => {
        if (cancel) return

        // Clear out any property specific info
        setReportPurchases(null)
        setPropertyPlans(null)
        setProjects(null)
        setProjectParts(null)
        setProjectTemplates(null)
        setBaseProjectTemplates(null)

        // Only load things if we have a propertyId set.
        if (propertyId != null) {
          const results = await Promise.all([
            sharedAction(['getProperty', propertyId], () => getProperty(propertyId)),
            sharedAction(['listPropertyPlans', propertyId], () => listPropertyPlans(propertyId)),
            sharedAction(['listProjectTemplates', propertyId], () => listBaseProjectTemplates(propertyId)),
          ])
          if (cancel) return // Check for cancel after each async call

          // Update the target property with the full property.
          setProperties((properties) => (properties || []).map((p) => (p.id == results[0].id ? results[0] : p)))
          setPropertyPlans(results[1])
          setBaseProjectTemplates(results[2])

          setLastPropertyId(propertyId)
        }
        // Last update the loadedPropertyId
        setLoadedPropertyId(propertyId || null)
      })()
    }

    return () => {
      cancel = true
    }
  }, [
    propertyId,
    loadedPropertyId,
    setProperties,
    setLoadedPropertyId,
    setPropertyPlans,
    setProjects,
    setProjectParts,
    setProjectTemplates,
    setBaseProjectTemplates,
    setReportPurchases,
    setLastPropertyId,
  ])

  // We probably need to return some sort of loading error as well.

  // We then can use usePropertyLoader in 'HasProperty' to 'load wait'
  // while we load the property; and can probably eliminate it from
  // authentication.

  // Can we use usePropertyLoader in useProperties?

  return {
    isLoading: propertyId != loadedPropertyId,
    property: property,
    redirect: constructRedirect(redirect, location, history.action),
  }
}

export const useProperties = (): {
  selectedProperty: Property | null
  lastProperty: Property | PropertyHead | null
  properties: Array<Property | PropertyHead> | null

  createProperty: (property: CProperty) => Promise<Property>
  updateSelectedProperty: (property: Partial<UProperty>) => Promise<Property | null>
  refreshProperty: (property: Property) => Promise<Property>
  deleteProperty: (propertyId: number) => Promise<Property | PropertyHead | null>
  attachDocToSelectedProperty: (type: keyof PropertyDocs | 'photos', file: File) => Promise<Property | null>
  deleteDocFromSelectedProperty: (type: keyof PropertyDocs | 'photos', fileId?: number) => Promise<Property | null>
  attachFilesToSelectedProperty: (files: File[]) => Promise<Property | null>
  deleteFileFromSelectedProperty: (fileId?: number) => Promise<Property | null>
} => {
  const { property } = usePropertyLoader()
  const properties = useRecoilValue(propertiesState)
  const setProperties = useSetRecoilState(propertiesState)
  const lastPropertyId = useRecoilValue(lastPropertyState)

  const setPropertyPlans = useSetRecoilState(propertyPlansState)
  const setProjects = useSetRecoilState(projectsState)
  const setProjectParts = useSetRecoilState(projectPartsState)
  const setProjectTemplates = useSetRecoilState(projectTemplatesState)
  const setBaseProjectTemplates = useSetRecoilState(baseProjectTemplatesState)

  const selectedProperty = property && !property._isHead ? (property as Property) : null

  const lastProperty = selectedProperty || (properties || []).find((prop) => prop.id == lastPropertyId) || null

  const _createProperty = useCallback(
    async (property: CProperty) => {
      const newProperty = await createProperty(property)
      setProperties((properties) => {
        // It is possible that 'create' returns to us a property
        // that already exists in our list. If so, we want to *replace*
        // that existing property rather than adding this new one in.
        properties = (properties || []).map((old_prop) => (old_prop.id == newProperty.id ? newProperty : old_prop))
        if (!properties.find((old_prop) => old_prop.id == newProperty.id)) {
          properties = properties.concat([newProperty])
        }
        return properties
      })
      return newProperty
    },
    [setProperties]
  )

  const updateSelectedProperty = useCallback(
    async (property: UProperty) => {
      if (!selectedProperty?.id) return null

      const newProperty = await updateProperty(selectedProperty.id, property)
      setProperties((properties) => (properties || []).map((p) => (p.id == newProperty.id ? newProperty : p)))

      // Updating a property can impact projects and values; these need to be refreshed.
      // First clear out any property specific info impacted
      setPropertyPlans(null)
      setProjects(null)
      setProjectParts(null)
      setProjectTemplates(null)
      setBaseProjectTemplates(null)

      // Reload them
      const results = await Promise.all([
        sharedAction(['listPropertyPlans', selectedProperty.id], () => listPropertyPlans(selectedProperty.id)),
        sharedAction(['listProjectTemplates', selectedProperty.id], () =>
          listBaseProjectTemplates(selectedProperty.id)
        ),
      ])

      setPropertyPlans(results[0])
      setBaseProjectTemplates(results[1])

      return newProperty
    },
    [
      selectedProperty,
      setBaseProjectTemplates,
      setProjectParts,
      setProjectTemplates,
      setProjects,
      setProperties,
      setPropertyPlans,
    ]
  )

  const refreshProperty = useCallback(
    async (property: Property) => {
      const refreshedProperty = await getProperty(property.id)
      setProperties((properties) =>
        (properties || []).map((p) => (p.id == refreshedProperty.id ? refreshedProperty : p))
      )
      return refreshedProperty
    },
    [setProperties]
  )

  const _deleteProperty = useCallback(
    async (propertyId: number) => {
      // Keep track of the property we're removing, and where
      const oldPropertyIndex = (properties || []).findIndex((p) => p.id != propertyId)
      const oldProperty = (properties || [])[oldPropertyIndex]
      // Remove property immediately from our list.
      setProperties((properties) => (properties || []).filter((p) => p.id != propertyId))
      // Then remove it for realsies.
      let deletedProperty: null | Property | PropertyHead = null
      try {
        deletedProperty = await deleteProperty(propertyId)
      } catch (e) {
        // if we failed, add it back in.
        setProperties((properties) => (properties || []).splice(oldPropertyIndex, 0, oldProperty))
        throw e
      }

      return deletedProperty
    },
    [setProperties, properties]
  )

  const attachDocToSelectedProperty = useCallback(
    async (type: keyof PropertyDocs | 'photos', file: File) => {
      if (!selectedProperty?.id) return null

      const newProperty = await attachDoc(selectedProperty.id, type, file)
      setProperties((properties) => (properties || []).map((p) => (p.id == newProperty.id ? newProperty : p)))
      return newProperty
    },
    [selectedProperty, setProperties]
  )

  const attachFilesToSelectedProperty = useCallback(
    async (files: File[]) => {
      if (!selectedProperty?.id) return null

      const newProperty = await attachFiles(selectedProperty.id, files)
      setProperties((properties) => (properties || []).map((p) => (p.id == newProperty.id ? newProperty : p)))
      return newProperty
    },
    [selectedProperty, setProperties]
  )

  const deleteFileFromSelectedProperty = useCallback(
    async (fileId?: number) => {
      if (!selectedProperty?.id) return null

      const newProperty = await deleteFile(selectedProperty.id, fileId)
      setProperties((properties) => (properties || []).map((p) => (p.id == newProperty.id ? newProperty : p)))
      return newProperty
    },
    [selectedProperty, setProperties]
  )

  const deleteDocFromSelectedProperty = useCallback(
    async (type: keyof PropertyDocs | 'photos', fileId?: number) => {
      if (!selectedProperty?.id) return null

      const newProperty = await deleteDoc(selectedProperty.id, type, fileId)
      setProperties((properties) => (properties || []).map((p) => (p.id == newProperty.id ? newProperty : p)))
      return newProperty
    },
    [selectedProperty, setProperties]
  )

  return {
    selectedProperty,
    lastProperty,
    properties,
    createProperty: _createProperty,
    updateSelectedProperty,
    refreshProperty,
    deleteProperty: _deleteProperty,
    attachDocToSelectedProperty,
    deleteDocFromSelectedProperty,
    attachFilesToSelectedProperty,
    deleteFileFromSelectedProperty,
  }
}

export const hasNoPropertiesState = selector({
  key: 'HasNoProperties',
  get: ({ get }) => {
    const properties = get(propertiesState)
    return (properties || []).length === 0
  },
})

export const listProperties = async (): Promise<Array<PropertyHead>> => {
  const api = new PropertyAPI()
  const properties = await api.listProperties()
  properties.forEach((prop) => (prop._isHead = true))
  return properties
}

export const getProperty = async (id: number): Promise<Property> => {
  const api = new PropertyAPI()
  const property = await api.getProperty(id)
  property._isHead = false
  return property
}

export const updateProperty = async (propertyId: number, property: UProperty): Promise<Property> => {
  const api = new PropertyAPI()
  const newProperty = await api.updateProperty(propertyId, property)
  newProperty._isHead = false
  return newProperty
}

const createProperty = async (property: CProperty): Promise<Property> => {
  const api = new PropertyAPI()
  const newProperty = await api.createProperty(property)
  newProperty._isHead = false
  return newProperty
}

const deleteProperty = async (propertyId: number): Promise<Property | null> => {
  const api = new PropertyAPI()
  const oldProperty = await api.deleteProperty(propertyId)
  if (oldProperty) oldProperty._isHead = false
  return oldProperty
}

const attachFiles = async (propertyId: number, files: File[]): Promise<Property> => {
  let res
  for (const file of files) {
    const formData = new FormData()
    formData.append('file', file)
    const lastRes = await postFile(`/api/v1/properties/${propertyId}/attach_file`, formData)
    if (lastRes.isError) {
      const error = new Error(`Non-200 status code: ${lastRes.code}.`)
      throw Object.assign(error, { body: lastRes.jsonBody })
    }
    res = lastRes
  }
  return res.jsonBody
}

const attachDoc = async (propertyId: number, type: keyof PropertyDocs | 'photos', file: File): Promise<Property> => {
  const formData = new FormData()
  formData.append('file', file)
  formData.append('type', type)
  const res = await postFile(`/api/v1/properties/${propertyId}/attach_file`, formData)

  if (res.isError) {
    const error = new Error(`Non-200 status code: ${res.code}.`)
    throw Object.assign(error, { body: res.jsonBody })
  }
  return res.jsonBody
}

const deleteFile = async (propertyId: number, fileId?: number): Promise<Property> => {
  const body = fileId ? { file_id: fileId } : {}
  const res = await postJSON(`/api/v1/properties/${propertyId}/delete_file`, body)

  if (res.isError) {
    const error = new Error(`Non-200 status code: ${res.code}.`)
    throw Object.assign(error, { body: res.jsonBody })
  }
  return res.jsonBody
}

const deleteDoc = async (
  propertyId: number,
  type: keyof PropertyDocs | 'photos',
  fileId?: number
): Promise<Property> => {
  const body = fileId ? { file_id: fileId, type: type } : { type: type }
  const res = await postJSON(`/api/v1/properties/${propertyId}/delete_file`, body)

  if (res.isError) {
    const error = new Error(`Non-200 status code: ${res.code}.`)
    throw Object.assign(error, { body: res.jsonBody })
  }
  return res.jsonBody
}
