import React, { FC, useMemo } from 'react'

import { Mortgage } from 'recoil/mortgages'

import CurrencyInput from 'components/CurrencyInput'
import Tooltip from 'components/Tooltip'
import Input, { styles as inputStyles } from 'components/Input'
import FancyButton from 'components/FancyButton'
import Spinner from 'components/Spinner'

import styles from './styles.module.scss'
import classnames from 'classnames'
import { FinancialAnalysisFormState } from 'pages/FinanceHub/FinancialAnalysis'
import Close from 'svgs/close'

interface FormField {
  name: keyof Mortgage | keyof FinancialAnalysisFormState['user']
  labelName: string
  dataType: 'currency' | 'text' | 'date' | 'number'
  tooltipContent?: string
  trackAsTag?: string
  placeholder?: string
  defaultValue?: string | number
  minVal?: number
  maxVal?: number
  stepVal?: number
}

interface OnDeleteFields {
  onDelete?: () => void
  descriptionLong?: string
  descriptionShort?: string
}

interface GeneralFormProps {
  inputProps: (input: keyof Mortgage | keyof FinancialAnalysisFormState['user']) => {
    onChange: (value: any) => void
    value: any
  }
  formFields: FormField[]
  onSave: () => void
  deleteFields?: OnDeleteFields
  saveDisabled?: boolean
  loading?: boolean
}

function groupFormFields(formFields: FormField[]): FormField[][] {
  const size = Math.round(formFields.length / 2)
  const group: FormField[][] = []
  for (let i = 0; i < size; i++) {
    const singleGrouping: FormField[] = []
    singleGrouping.push(formFields[i * 2])
    if (i * 2 + 1 < formFields.length) {
      singleGrouping.push(formFields[i * 2 + 1])
    }
    group.push(singleGrouping)
  }
  return group
}

const GeneralForm: FC<GeneralFormProps> = ({ inputProps, formFields, onSave, deleteFields, saveDisabled, loading }) => {
  const groupedFields = useMemo(() => groupFormFields(formFields), [formFields])

  function createFormField(formField: FormField) {
    const defaultValue = formField.defaultValue || ''

    function inputPropsWithDefault() {
      const { onChange, value } = inputProps(formField.name)
      return {
        onChange: onChange,
        value: value != null && (formField.dataType != 'number' || !isNaN(value)) ? value : defaultValue,
      }
    }

    function handleChange() {
      const { onChange, value } = inputProps(formField.name)
      return {
        onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
          if (
            formField.dataType != 'number' ||
            (formField.minVal == null && formField.maxVal == null) ||
            e.currentTarget.value == ''
          ) {
            onChange(e.currentTarget.value)
          } else {
            const inputVal = parseFloat(e.currentTarget.value)
            let greaterThanMin = false
            let lessThanMax = false
            if (formField.minVal == null || formField.minVal <= inputVal) {
              greaterThanMin = true
            }
            if (formField.maxVal == null || inputVal <= formField.maxVal) {
              lessThanMax = true
            }
            if (greaterThanMin && lessThanMax) {
              onChange(e.currentTarget.value)
            }
          }
        },
        value: value != null && (formField.dataType != 'number' || !isNaN(value)) ? value : defaultValue,
      }
    }
    if (formField.dataType == 'currency') {
      return (
        <CurrencyInput
          className={styles.input}
          label={formField.labelName}
          trackAs={formField.trackAsTag || ''}
          toolTipContent={formField.tooltipContent || ''}
          {...inputProps(formField.name)}
        />
      )
    } else if (formField.dataType == 'date') {
      return (
        <div className={classnames(inputStyles.input, styles.input)}>
          <label>{formField.labelName}</label>
          <Tooltip className={styles.inputTooltip} trackAs={formField.trackAsTag} overlay={formField.tooltipContent} />
          <Input type={formField.dataType} placeholder={formField.placeholder} {...inputPropsWithDefault()} />
        </div>
      )
    } else {
      return (
        <div className={classnames(inputStyles.input, styles.input)}>
          <label>{formField.labelName}</label>
          <Tooltip className={styles.inputTooltip} trackAs={formField.trackAsTag} overlay={formField.tooltipContent} />
          <input
            type={formField.dataType}
            placeholder={formField.placeholder}
            min={formField.minVal}
            max={formField.maxVal}
            step={formField.stepVal}
            {...handleChange()}
          />
        </div>
      )
    }
  }

  return (
    <>
      {groupedFields.map((groupedField, index) => {
        return (
          <div key={index} className={classnames(styles.row, { [styles.oneElementRow]: groupedField.length < 2 })}>
            {createFormField(groupedField[0])}
            {groupedField.length > 1 ? (
              createFormField(groupedField[1])
            ) : (
              <div className={classnames(styles.input, styles.hiddenDiv)}></div>
            )}
          </div>
        )
      })}
      <div className={styles.actionsRow}>
        <FancyButton className={styles.saveButton} disabled={saveDisabled || loading} onClick={onSave}>
          {'Save'}
          {loading && <Spinner />}
        </FancyButton>
        {deleteFields != null && (
          <button type="button" className={styles.deleteButton} onClick={deleteFields.onDelete}>
            <Close />
            {deleteFields.descriptionLong != null && (
              <span className={styles.long}>{deleteFields.descriptionLong}</span>
            )}
            {deleteFields.descriptionShort != null && (
              <span className={styles.short}>{deleteFields.descriptionShort}</span>
            )}
          </button>
        )}
      </div>
    </>
  )
}

export default GeneralForm
