import React, { FC, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

function useIsScrolling(): boolean {
  const [isAutoScrolling, setIsAutoScrolling] = useState(true) // Assume we're autoscrolling
  const [isScrolling, setIsScrolling] = useState(false)

  // Checking on general scrolling
  useEffect(() => {
    const timeouts: Array<any> = []
    let outsideScrolling = 0
    const onScroll = () => {
      outsideScrolling += 1
      if (outsideScrolling == 1) setIsScrolling(true)
      timeouts.push(
        setTimeout(() => {
          outsideScrolling -= 1
          if (outsideScrolling == 0) setIsScrolling(false)
        }, 50)
      )
    }
    window.addEventListener('scroll', onScroll)
    return () => {
      window.removeEventListener('scroll', onScroll)
      timeouts.forEach((timeout) => clearTimeout(timeout))
    }
  }, [])

  // Checking on forced mobile device scrolling (such as when a virtual
  // keyboard appears)
  useEffect(() => {
    let cancel = false
    let lastPosition: number | null = null
    let lastHeight: number | null = null
    let request
    const scrollCheckFn = () => {
      if (cancel) return
      if (lastPosition != window.pageYOffset || lastHeight != window.innerHeight) {
        lastPosition = window.pageYOffset
        lastHeight = window.innerHeight
        // We're not done scrolling/resizing, so continue checking.
        request = setTimeout(scrollCheckFn, 10)
      } else {
        // We now appear to be done scrolling
        setIsAutoScrolling(false)
      }
    }
    scrollCheckFn()
    return () => {
      cancel = true
      clearTimeout(request)
    }
  }, [])

  return isScrolling || isAutoScrolling
}

function scrollNow() {
  try {
    window.scrollTo({ top: 0, left: 0, behavior: 'instant' } as any)
  } catch {
    // Fallback in case the above isn't supported.
    window.scrollTo(0, 0)
  }
}

export const ScrollToTop: FC = () => {
  const { action } = useHistory()
  const { pathname, state } = useLocation()
  const [hasRun, setHasRun] = useState(false)
  const isScrolling = useIsScrolling()

  // Will run the first time, and every time isScrolling changes.
  // Conceptually, we want this to run once, then once more when isScrolling->false,
  // and then not again after.
  useEffect(
    () => {
      // Once we know we've forced them to the top, stop trying to scroll them.
      // This allows us to actually have routes (and navigate to them) inside a page
      // with a scroll to top, without accidentally triggering a scroll a second time.
      if (hasRun) return
      // We don't want to scroll if they're navigating
      // their browser history with back/forward.
      if (action == 'PUSH' || (state as any)?.redirectOriginalAction == 'PUSH') {
        // Try a scroll now. This runs on every attempt, and will provide
        // the smoothest experience if it works early.
        scrollNow()
        // Once we know they're done scrolling, signal to stop scrolling.
        if (!isScrolling) {
          setHasRun(true)
        }
      }
    },
    // We should execute every time we have a valid navigation
    // to this page, using a push type action.
    // Redirects will REPLACE instead of push, so check our
    // preserved action as well.
    [action, pathname, state, hasRun, isScrolling]
  )

  return <></>
}

export default ScrollToTop
