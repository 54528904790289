import { useEffect, useCallback, useMemo } from 'react'
import { atom, useRecoilState } from 'recoil'

import sharedAction from 'utils/sharedAction'

import PropertyPlanApi from 'apis/propertyPlanApi'

import { Property } from './properties'

import { ProjectTemplate } from './projectTemplates'

export const baseProjectTemplatesState = atom<Array<ProjectTemplate> | null>({
  key: 'BaseProjectTemplates',
  default: null,
})

const baseProjectTemplatesIsLoadingState = atom<number>({
  key: 'BaseProjectTemplates_isLoading',
  default: 0,
})

export const useBaseProjectTemplates = (
  property: Property | undefined
): {
  latestProjectTemplates: Array<ProjectTemplate>
  projectTemplates: Array<ProjectTemplate>
  isLoading: boolean
  refreshProjectTemplates: () => Promise<void>
} => {
  const [projectTemplates, setProjectTemplates] = useRecoilState(baseProjectTemplatesState)
  const [isLoading, setIsLoading] = useRecoilState(baseProjectTemplatesIsLoadingState)

  useEffect(() => {
    const runme = async () => {
      if (!property) return
      // If we don't have our templates yet...
      if (projectTemplates == null) {
        setIsLoading((p) => p + 1)

        // Get our templates from the API
        const newProjectTemplates = await sharedAction(['listProjectTemplates', property.id], () =>
          listProjectTemplates(property.id)
        )

        // Store in recoil
        setProjectTemplates(newProjectTemplates)

        setIsLoading((p) => p - 1)
      }
    }
    runme()
  }, [setProjectTemplates, projectTemplates, property, setIsLoading])

  const refreshProjectTemplates = useCallback(async () => {
    if (!property) return
    setIsLoading((p) => p + 1)

    // Get our templates from the API
    const newProjectTemplates = await sharedAction(['listProjectTemplates', property.id], () =>
      listProjectTemplates(property.id)
    )

    // Store in recoil
    setProjectTemplates(newProjectTemplates)

    setIsLoading((p) => p - 1)
  }, [setProjectTemplates, property, setIsLoading])

  const latestProjectTemplates = useMemo(() => {
    return (projectTemplates || []).filter((template) => !template.deprecated)
  }, [projectTemplates])

  return {
    latestProjectTemplates,
    projectTemplates: projectTemplates || [],
    isLoading: isLoading > 0,
    refreshProjectTemplates,
  }
}

export const useRecommendations = (property: Property | undefined): ProjectTemplate[] => {
  const { projectTemplates } = useBaseProjectTemplates(property)

  // Provide recommendations that are based off top projects
  // Top projects is already sorted and curated.
  const recommendations = useMemo(() => {
    return (
      property?.top_projects
        .filter((top) => top.additional_score >= 0)
        .reduce((prev, top) => {
          // We could have failed to get projectTemplates altogether.
          if (!projectTemplates) return prev

          const template = projectTemplates.find((pt) => pt.id == top.id)
          // If we failed to get projectTemplates, or there was a change between
          // getting the property and the templates, we may not find the template.
          if (template) {
            // Use score from top_projects, not the template.
            return prev.concat([Object.assign({}, template, { additional_score: top.additional_score }) as never])
          }
          return prev
        }, []) || []
    )
  }, [property, projectTemplates])

  return recommendations
}

export const listProjectTemplates = async (propertyId: number): Promise<ProjectTemplate[]> => {
  const api = new PropertyPlanApi(propertyId)
  return await api.listProjectTemplates()
}
