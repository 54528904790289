import React, { FC, PropsWithChildren } from 'react'
import classNames from 'classnames'

import styles from './BubbleList.module.scss'

export interface CardProps {
  className?: string
}

const BubbleList: FC<PropsWithChildren<CardProps>> = ({ children, className }) => (
  <ol className={classNames(styles.bubbleList, className)}>{children}</ol>
)

export default BubbleList
