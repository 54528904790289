import React, {
  cloneElement,
  FC,
  JSXElementConstructor,
  PropsWithChildren,
  ReactElement,
  useCallback,
  useEffect,
  useState,
} from 'react'
import type { ElementProps, Placement } from '@floating-ui/react'
import {
  FloatingPortal,
  safePolygon,
  useClick,
  useDismiss,
  useFloating,
  useHover,
  useInteractions,
  useRole,
  autoUpdate,
  shift,
  limitShift,
} from '@floating-ui/react'

interface DropdownProps {
  className?: string
  overlay: ReactElement<any, string | JSXElementConstructor<any>>
  placement?: Placement
  click?: boolean
  hover?: boolean
  menu?: boolean
  stopPropagation?: boolean
  open?: boolean
  setOpen?: (value: boolean) => void
  zIndex?: number
  useShiftMiddleware?: boolean
}

const Dropdown: FC<PropsWithChildren<DropdownProps>> = ({
  className,
  click,
  hover,
  menu,
  children,
  overlay,
  placement,
  stopPropagation,
  open: openProp,
  setOpen: setOpenProp,
  zIndex,
  useShiftMiddleware = true,
}) => {
  const [open, onOpenChange] = useState(false)
  const handleOpenChange = useCallback((value: boolean) => {
    // don't toggle while we have a new feature journey active, see useNewFeatureJourney
    if (document.querySelector('.driver-active')) return
    onOpenChange(value)
  }, [])
  const { context, x, y, refs, strategy } = useFloating({
    open,
    onOpenChange: handleOpenChange,
    placement,
    whileElementsMounted: autoUpdate,
    middleware: useShiftMiddleware
      ? [
          shift({
            crossAxis: true,
            limiter: limitShift({}),
            padding: 24,
          }),
        ]
      : undefined,
  })

  useEffect(() => {
    if (typeof openProp == 'boolean') onOpenChange(openProp)
  }, [openProp])

  useEffect(() => {
    if (setOpenProp) setOpenProp(open)
  }, [open, setOpenProp])

  const hoverHook = useHover(context, { handleClose: safePolygon(), restMs: 50 })
  const clickHook = useClick(context)
  const roleHook = useRole(context, { role: 'menu' })

  const interactions = [
    hover ? hoverHook : null,
    click ? clickHook : null,
    menu ? roleHook : null,
    useDismiss(context),
  ].filter((e) => e) as ElementProps[]

  const { getReferenceProps, getFloatingProps } = useInteractions(interactions)

  const overlayStyle = {
    position: strategy,
    top: y ?? '',
    left: x ?? '',
    zIndex: zIndex,
  }

  return (
    <>
      <div
        className={className}
        {...getReferenceProps({
          ref: refs.setReference,
          onClick(event) {
            if (stopPropagation) event.stopPropagation()
          },
        })}
      >
        {children}
      </div>
      <FloatingPortal>
        {open && cloneElement(overlay, getFloatingProps({ ref: refs.setFloating, style: overlayStyle }))}
      </FloatingPortal>
    </>
  )
}

export default Dropdown
