import React, { ButtonHTMLAttributes, FC } from 'react'
import { Link } from 'react-router-dom'
import type { LinkProps } from 'react-router-dom'
import cx from 'classnames'

import ArrowForward from 'svgs/arrow-circle-down'

import styles from './ArrowLink.module.scss'

interface ArrowLinkProps extends Partial<LinkProps> {
  className?: string
}

const ArrowLink: FC<ArrowLinkProps> = ({ className, children, href, ...otherProps }) => {
  if (href) {
    return (
      <a className={cx(styles.arrowLink, className)} href={href}>
        <ArrowForward className={styles.arrow} />
        <div className={styles.content}>{children}</div>
      </a>
    )
  }

  if (otherProps.to) {
    return (
      <Link className={cx(styles.arrowLink, className)} {...(otherProps as LinkProps)}>
        <ArrowForward className={styles.arrow} />
        <div className={styles.content}>{children}</div>
      </Link>
    )
  }

  return (
    <button
      type="button"
      className={cx(styles.arrowLink, className)}
      {...(otherProps as ButtonHTMLAttributes<HTMLButtonElement>)}
    >
      <ArrowForward className={styles.arrow} />
      <div className={styles.content}>{children}</div>
    </button>
  )
}

export default ArrowLink
