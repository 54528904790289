import React, { FC } from 'react'

interface Props {
  className?: string
}

const Svg: FC<Props> = ({ className }) => (
  <svg
    width="22px"
    height="22px"
    viewBox="0 0 22 22"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    className={className}
  >
    <path
      fill="currentColor"
      d="M16.2324022,8.8817842e-16 L16.0943513,0.139044072 L16.0913718,1.17492241 C16.0913718,4.08134078 15.7993793,4.37551831 12.8953445,4.37551831 L11.8574798,4.37551831 L11.7194289,4.51456238 L11.7194289,5.51925512 L11.8574798,5.65829919 L12.8933582,5.66108007 C15.7993793,5.66108007 16.0943513,5.95247672 16.0943513,8.85730602 L16.0943513,9.89199255 L16.2324022,10.0310366 L17.2374922,10.0310366 L17.3765363,9.89199255 L17.3795158,8.85631285 C17.3795158,5.94989448 17.6705152,5.65571695 20.5755431,5.65571695 L21.6134078,5.65571695 L21.7514587,5.51667287 L21.7514587,4.51317194 L21.6134078,4.37412787 L20.5775295,4.37134699 C17.6705152,4.37134699 17.3765363,4.07995034 17.3765363,1.17512104 L17.3765363,0.139044072 L17.2374922,8.8817842e-16 L16.2324022,8.8817842e-16 Z"
      id="Path"
    ></path>
    <path
      fill="currentColor"
      d="M6.99193048,6.65425202 L6.78336437,6.86242086 L6.77939168,8.42130354 C6.77939168,12.7950341 6.14078212,13.4380137 1.7698324,13.4380137 L0.208566108,13.4380137 L4.79616347e-14,13.6461825 L4.79616347e-14,14.7585351 L0.208566108,14.9669025 L1.76685289,14.9708752 C6.14078212,14.9708752 6.78336437,15.6098821 6.78336437,19.9812291 L6.78336437,21.5424953 L6.99193048,21.7506642 L8.10428305,21.7506642 L8.31185599,21.5424953 L8.31682185,19.9826195 C8.31682185,15.6088889 8.95543141,14.9659094 13.3263811,14.9659094 L14.8876474,14.9659094 L15.0962135,14.7577405 L15.0962135,13.645388 L14.8876474,13.4372191 L13.3293606,13.4332464 C8.95543141,13.4332464 8.31185599,12.7942396 8.31185599,8.42289261 L8.31185599,6.86162632 L8.10428305,6.65425202 L6.99193048,6.65425202 Z"
      id="Path"
    ></path>
  </svg>
)

export default Svg
