import AccessibleFunds, { FinancingOption, createFinancingOption } from './AccessibleFunds'
import ArrowLink from 'components/ArrowLink'
import { BrownBagMortgageResponse, cashOutRefiOption, determineTerm, fetchBest, helocOption } from 'utils/bbmApi'
import Card from 'components/Card'
import Default from 'layouts/Default'
import EquityOverview from './EquityOverview'
import FinancialAnalysis from './FinancialAnalysis'
import FinancialDetails from './FinancialDetails'
import HasProperty from 'layouts/HasProperty'
import MortgageAnalysis, { MortgageDetails } from './MortgageAnalysis'
import NeedMoreDetailsCard from './NeedMoreDetailsCard'
import PageHeader from 'components/PageHeader'
import PageTitle from 'components/PageTitle'
import Privacy from 'svgs/finance-hub/privacy'
import { Property, useProperties } from 'recoil/properties'
import React, { FC, useEffect, useCallback, useState } from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import ScrollToTop from 'non-rendering/ScrollToTop'
import Secure from 'layouts/Secure'
import Spinner from 'components/Spinner'
import { useMortgages } from 'recoil/mortgages'
import { useRecoilValue } from 'recoil'
import { userState } from 'recoil/user'
import { postJSON } from 'utils/fetch'
import IntercomCta from 'components/IntercomCta'

import styles from './styles.module.scss'

export const createMortgageDetails = (property: Property | null, best?: BrownBagMortgageResponse): MortgageDetails => {
  const mortgage = property?.blended_mortgage || { term: null, rate: null, principle: null, monthly_payment: null }
  const details: MortgageDetails = {
    currentMonthlyPayment: mortgage.monthly_payment || 0,
    currentPrinciple: mortgage.principle || 0,
    currentRate: mortgage.rate || 0,
    currentTerm: mortgage.term || 0,
  }
  if (
    mortgage.term == null ||
    mortgage.rate == null ||
    mortgage.principle == null ||
    mortgage.monthly_payment == null
  ) {
    return details
  }
  const backendTotal = (property?.best_rate_payment || 0) * 30 * 12
  let bbmTotal: number | null = null
  if (best) {
    bbmTotal =
      parseInt(best.MONTHLY_PAYMENT.replace(/[()$,]/g, '')) *
      (determineTerm(property?.blended_mortgage.term || 0)?.years || 0) *
      12
  }
  if (bbmTotal !== null && bbmTotal <= backendTotal) {
    details.alternativeMonthlyPayment = parseInt(best?.MONTHLY_PAYMENT?.replace(/[()$,]/g, '') || '')
    details.alternativeRate = parseFloat(best?.RATE?.replace(/%/g, '') || '')
    details.alternativeTerm = determineTerm(property?.blended_mortgage?.term || 0)?.years || 0
  } else {
    if (property && property.monthly_overpayment != 0 && isAlternativeBackendMortgageCheaper(property)) {
      details.alternativeMonthlyPayment = property.best_rate_payment
      details.alternativeRate = property.best_rate
      details.alternativeTerm = 30
    } else {
      details.alternativeMonthlyPayment = details.currentMonthlyPayment
      details.alternativeRate = details.currentRate
      details.alternativeTerm = details.currentTerm
    }
  }
  return details
}

// excludes any penalty becuase it's just a sanity check to gaurantee that the raw loan is better
function isAlternativeBackendMortgageCheaper(property: Property): boolean {
  if (property.best_rate_payment == null || property.best_rate == null) {
    return false
  }
  return (
    property.best_rate_payment * 30 * 12 <
    (property.blended_mortgage?.monthly_payment || 0) * (property.blended_mortgage?.term || 0) * 12
  )
}

const Inner: FC = () => {
  const { url } = useRouteMatch()

  const user = useRecoilValue(userState)
  const { selectedProperty: property, refreshProperty } = useProperties()
  const { mortgages, isLoading: isLoadingMortgages } = useMortgages()

  const [bestMortgageDetails, setBestMortgageDetails] = useState<MortgageDetails>(createMortgageDetails(property))
  const [financingOptions, setFinancingOptions] = useState<FinancingOption[]>([])

  const handleSetNoMortgage = useCallback(async () => {
    if (!property?.id) return

    await postJSON(`/api/v1/properties/${property.id}/set_free_of_mortgage`, {})
    await postJSON(`/api/v1/properties/${property.id}/finances`, {})
    await refreshProperty(property)
  }, [property, refreshProperty])

  useEffect(() => {
    const _ = async () => {
      if (!property) return

      const creditScore = user?.credit_score || 0
      const best = await fetchBest(property, creditScore)
      setBestMortgageDetails(createMortgageDetails(property, best || undefined))

      const rate = bestMortgageDetails?.alternativeRate
      const promises: Array<any> = []
      promises.push(createFinancingOption(helocOption(property, rate, creditScore)))
      promises.push(createFinancingOption(cashOutRefiOption(property, rate, creditScore)))
      const options = await Promise.all(promises)
      setFinancingOptions(options.filter((x) => x))
    }

    _()
  }, [bestMortgageDetails?.alternativeRate, property, user?.credit_score, setFinancingOptions])

  if (isLoadingMortgages) {
    return (
      <div className="tw-text-center tw-mt-5">
        <Spinner size="xl" />
      </div>
    )
  }

  if (!property) return null

  const monthlyOverpayment =
    bestMortgageDetails.alternativeMonthlyPayment != null
      ? bestMortgageDetails.currentMonthlyPayment - bestMortgageDetails.alternativeMonthlyPayment
      : null

  return (
    <div>
      <PageTitle title="Equity" />
      <ScrollToTop />
      <div className={styles.content}>
        <section>
          <PageHeader
            header={'Manage your home wealth'}
            subheader={
              "Realm's equity page helps you identify ways to access funds, lower your monthly mortgage payment, and lower your total interest."
            }
          />
          <div />
        </section>
        <div className={styles.nonTitleContent}>
          <div className={styles.mainContent}>
            {property.equity_state != 'normal' && property.equity_state != 'no_mortgage' && (
              <NeedMoreDetailsCard className={styles.contentChunk}>
                <h2>{'Looks like we need some more details'}</h2>
                <p>
                  {'With just a touch more information Realm can analyze your financial details allowing us to tell you how' +
                    ' your home equity is tracking, and help you identify ways to access funds.'}
                </p>
                <div className="tw-flex tw-gap-4">
                  <ArrowLink className={styles.arrowLinkEquity} to={`${url}/financial-analysis`}>
                    {'Update your financial details'}
                  </ArrowLink>
                  <ArrowLink className={styles.arrowLinkEquity} onClick={handleSetNoMortgage}>
                    {'No mortgage or home loan'}
                  </ArrowLink>
                </div>
              </NeedMoreDetailsCard>
            )}
            <div className={styles.contentChunk}>
              <div className={styles.labelContainer}>
                <h2>{property.delivery_line_1}</h2>
                <p>{`${property.city}, ${property.state}, ${property.zip5}`}</p>
              </div>
              <Card className={styles.equityOverview}>
                <EquityOverview property={property} monthlyOverpayment={monthlyOverpayment} />
              </Card>
            </div>
            <div className={styles.contentChunk}>
              <div className={styles.labelContainer}>
                <h2>{'Accessible funds estimate'}</h2>
                <p>{'After assessing your credit position, you qualify for the following cash-out lending options.'}</p>
              </div>
              <Card className={styles.accessibleFunds}>
                <AccessibleFunds financingOptions={financingOptions} equityState={property.equity_state} />
              </Card>
            </div>
            {property.equity_state !== 'no_mortgage' && (
              <div className={styles.contentChunk}>
                <div className={styles.labelContainer}>
                  <h2>{'Mortgage Analysis'}</h2>
                  <p>{"By refinancing, you'll save on total interest as well as your monthly mortgage payments."}</p>
                </div>
                <Card className={styles.mortgageAnalysis}>
                  <MortgageAnalysis equityState={property.equity_state} details={bestMortgageDetails} />
                </Card>
              </div>
            )}
          </div>
          <div className={styles.sideContent}>
            <Card className={styles.details}>
              <FinancialDetails mortgages={mortgages} equityState={property.equity_state} />
            </Card>
            {/* <Card className={styles.reportUpsell}>
              <h2>{'Get a property report'}</h2>
              <p>{'Our property analysts use exclusive data to provide you with a personalized home assessment.'}</p>
              <ArrowLink to="reports">{'Get your property report'}</ArrowLink>
              <img className={styles.reportImg} src={UpsellReportsImage} alt="Reports" />
            </Card> */}
            {property.marketplace && (
              <div className={styles.helpBox}>
                {'Questions about your home wealth?'}
                <IntercomCta className={styles.advisorCta} location="in-page">
                  {'Chat with a Realm Advisor'}
                </IntercomCta>
              </div>
            )}
            <Card className={styles.privacy}>
              <h2>
                <Privacy />
                {'Our Privacy Promise'}
              </h2>
              <p>
                {
                  'We use your financial data to provide personalized recommendations for your projects. We keep this data private and do not share it with other customers or 3rd party partners.'
                }
              </p>
            </Card>
          </div>
        </div>
      </div>
    </div>
  )
}

const FinanceHub: FC = () => {
  const { path } = useRouteMatch()

  return (
    <Secure>
      <HasProperty>
        <Default>
          <Switch>
            <Route path={`${path}/financial-analysis`} component={FinancialAnalysis} />
            <Route path={path} component={Inner} />
          </Switch>
        </Default>
      </HasProperty>
    </Secure>
  )
}

export default FinanceHub
