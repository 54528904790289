import React, { FC } from 'react'
import cx from 'classnames'

import styles from './PageHeader.module.scss'

interface PageHeaderProps {
  header: string
  subheader?: string
  className?: string
}

const PageHeader: FC<PageHeaderProps> = ({ header, className, subheader }) => {
  return (
    <div className={cx(styles.pageHeader, className)}>
      <h1 className={styles.head}>{header}</h1>
      <p className={styles.subhead}>{subheader}</p>
    </div>
  )
}

export default PageHeader
