import React, { FC } from 'react'
import { Alert as IAlert } from 'recoil/properties'

export interface AlertProps {
  alert: IAlert
}

const Alert: FC<AlertProps> = ({ alert }) => {
  return (
    <div className="tw-flex tw-items-center">
      <img alt="" src={alert.image_url} className="tw-w-16 tw-h-16 tw-object-cover tw-rounded-2xl" />
      <div className="tw-ml-4">{alert.message}</div>
    </div>
  )
}

export default Alert
