import Alert from '../Alert'
import ArrowLink from 'components/ArrowLink'
import { BbmObject } from 'utils/bbmApi'
import classnames from 'classnames'
import { formatCurrency } from 'utils/formatting'
import { Link, useRouteMatch } from 'react-router-dom'
import React, { FC, useState, useCallback } from 'react'
import Tooltip from 'components/Tooltip'
import { useRecoilValue } from 'recoil'
import { userState } from 'recoil/user'

import styles from './styles.module.scss'

export const createFinancingOption = async (bbmPromise: Promise<BbmObject | null>): Promise<FinancingOption | null> => {
  const bbmObject = await bbmPromise
  if (bbmObject == null) return null
  const { name, response } = bbmObject
  if (!response) return null

  const { fundsLow, fundsHigh, lowRate, highRate } = response

  let finalRate: string
  if (Math.round(lowRate * 100) / 100 == Math.round(highRate * 100) / 100) {
    finalRate = `${Math.round(lowRate * 100 - 10) / 100}% - ${Math.round(lowRate * 100 + 10) / 100}%`
  } else {
    finalRate = `${Math.round(lowRate * 100) / 100}% - ${Math.round(highRate * 100) / 100}%`
  }

  return {
    optionName: name,
    fundsLow,
    fundsHigh,
    availableFundsEstimate: `${formatCurrency(fundsLow)} - ${formatCurrency(fundsHigh)}`,
    rate: finalRate,
  }
}

export interface FinancingOption {
  optionName: string
  fundsLow: number
  fundsHigh: number
  availableFundsEstimate: string | null
  rate: string | null
}

interface Props {
  financingOptions: FinancingOption[]
  equityState: 'normal' | 'low_data' | 'missing_data' | 'no_data' | 'no_mortgage'
}

const AccessibleFunds: FC<Props> = ({ financingOptions, equityState }) => {
  const user = useRecoilValue(userState)
  const [isAlertVisible, setIsAlertVisible] = useState<boolean>(true)
  const { url } = useRouteMatch()
  const closeAlert = useCallback(() => {
    setIsAlertVisible(false)
  }, [setIsAlertVisible])
  return (
    <div className={styles.accessibleFunds}>
      {financingOptions.length > 0 && (
        <>
          <Tooltip
            className={styles.toolTip}
            trackAs="accessibile-funds"
            overlay="Based on your current equity, we search for the minimum and maximum interest rates available to you. Market rates are refreshed daily."
          />
          <div className={styles.fundRows}>
            <div className={styles.fields}>
              <div className={styles.keepDisplayed}>{'Financing option'}</div>
              <div>{'Available funds estimate'}</div>
              <div>{'Rate'}</div>
            </div>
            {financingOptions != null &&
              financingOptions.map((option) => (
                <div key={option.optionName}>
                  <div>{option.optionName}</div>
                  <div>
                    <div className={styles.fieldName}>{'Available funds estimate'}</div>
                    <div className={styles.funds}>{option.availableFundsEstimate}</div>
                  </div>
                  <div>
                    <div className={styles.fieldName}>{'Rate'}</div>
                    <div>{option.rate}</div>
                  </div>
                </div>
              ))}
          </div>
        </>
      )}
      {(user?.credit_score == null || equityState != 'normal') && isAlertVisible && (
        <Alert
          className={classnames(styles.alert, { [styles.noDataAlert]: financingOptions.length == 0 })}
          closeAlert={closeAlert}
        >
          {equityState != 'normal' && equityState != 'no_mortgage' ? (
            'We are missing important loan information, please update to see more accurate data.'
          ) : (
            <>
              {'By '}
              <Link to={`${url}/financial-analysis`}>{'adding'}</Link>
              {' your Credit Score we can more accurately display available funds estimates.'}
            </>
          )}
        </Alert>
      )}
      <div className={styles.bottomLinksContainer}>
        <ArrowLink className={styles.arrowLink} href="/rate-table">
          {'See Rates'}
        </ArrowLink>
      </div>
    </div>
  )
}

export default AccessibleFunds
