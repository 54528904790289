const sharedActionMap: { [key: string]: Array<{ resolve: any; reject: any }> } = {}

function combineKeys(keys: Array<string | number>): string {
  return keys.join('_')
}

/**
 * Allows only one call to 'fn' at a time for a given 'key'.
 * Subsequent calls made before 'fn' finishes will be blocked, and
 * given the results of 'fn' at the time 'fn' finishes executing.
 * @param key The Key to use to group this sharedAction together
 * @param fn The function we wish to execute.
 */
function sharedAction<T>(key: string, fn: () => Promise<T>): Promise<T>
/**
 * Allows only one call to 'fn' at a time for a given 'key' set.
 * Subsequent calls made before 'fn' finishes will be blocked, and
 * given the results of 'fn' at the time 'fn' finishes executing.
 * @param keys The Keys to use to group this sharedAction together.
 *             all keys will be used to create a unique group. Keys will
 *             not overlap with a similar set of keys. For example,
 *             ["a","b","c"] will NOT lock out (or be locked out by)
 *             ["a","b"]
 * @param fn The function we wish to execute.
 */
function sharedAction<T>(keys: Array<string | number>, fn: () => Promise<T>): Promise<T>
function sharedAction<T>(keys: string | Array<string | number>, fn: () => Promise<T>): Promise<T> {
  const finalKeys = typeof keys === 'string' ? keys : combineKeys(keys)

  return new Promise((resolve, reject) => {
    if (sharedActionMap[finalKeys]) sharedActionMap[finalKeys].push({ resolve, reject })
    else {
      sharedActionMap[finalKeys] = [{ resolve, reject }]
      fn()
        // Ideally we would use 'finally()' to delete the map entry,
        // instead of trapping the exception to ensure we clean up.
        // However some actively used browser implementations still don't
        // have 'finally' (in this case, safari 10.x)
        .then((result) => {
          // Trap any error and rethrow it after we delete the key.
          let trap = null
          try {
            sharedActionMap[finalKeys].map((r) => r.resolve(result))
          } catch (err) {
            trap = err
          }
          delete sharedActionMap[finalKeys]
          if (trap) throw trap
        })
        .catch((err) => {
          // Trap any error and rethrow it after we delete the key.
          let trap = null
          try {
            sharedActionMap[finalKeys].map((r) => r.reject(err))
          } catch (err) {
            trap = err
          }
          delete sharedActionMap[finalKeys]
          if (trap) throw trap
        })
    }
  })
}

export interface AttemptResult<T> {
  success: boolean
  results?: T
}

/**
 * Similar to sharedAction, this call first tests if the key is locked. If it is
 * it will wait and return the results wrapped in an AttemptResult, with 'success'
 * set to true. If the key is not locked, it will immediately return with an
 * empty AttemptResult, with 'success' set to false.
 * @param key The Key to use to group this sharedAction together
 */
export function attemptSharedAction<T>(keys: string): Promise<AttemptResult<T>>
/**
 * Similar to sharedAction, this call first tests if the key is locked. If it is
 * it will wait and return the results wrapped in an AttemptResult, with 'success'
 * set to true. If the key is not locked, it will immediately return with an
 * empty AttemptResult, with 'success' set to false.
 * @param keys The Keys to use to group this sharedAction together.
 *             all keys will be used to create a unique group. Keys will
 *             not overlap with a similar set of keys. For example,
 *             ["a","b","c"] will NOT lock out (or be locked out by)
 *             ["a","b"]
 */
export function attemptSharedAction<T>(keys: Array<string | number>): Promise<AttemptResult<T>>
export function attemptSharedAction<T>(keys: string | Array<string | number>): Promise<AttemptResult<T>> {
  const finalKeys = typeof keys === 'string' ? keys : combineKeys(keys)

  return new Promise((resolve, reject) => {
    if (sharedActionMap[finalKeys])
      sharedActionMap[finalKeys].push({ resolve: (results: T) => ({ success: true, results }), reject })
    else {
      // If there is no entry for this key, we can resolve immediately as unsuccessful
      resolve({ success: false })
    }
  })
}

export default sharedAction
