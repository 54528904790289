import React, { FC, PropsWithChildren } from 'react'

import ScrollToTop from 'non-rendering/ScrollToTop'

import GlobalAlert from '../components/GlobalAlert'
import OverlayMount from '../components/OverlayMount'
import Navigation from '../components/Navigation'
import Footer from '../components/Footer'

interface Props {
  propertyAgnostic?: boolean
}

export const Default: FC<PropsWithChildren<Props>> = ({ propertyAgnostic, children }) => {
  return (
    <>
      <ScrollToTop />
      <GlobalAlert />
      <div className="tw-flex tw-flex-col tw-min-h-screen">
        <OverlayMount>
          <Navigation propertyAgnostic={propertyAgnostic} />
          <div className="tw-container tw-mx-auto tw-px-4 sm:tw-px-6 lg:tw-px-8 tw-grow">{children}</div>
          <Footer />
        </OverlayMount>
      </div>
    </>
  )
}

export default Default
