import { useEffect, useCallback, useMemo } from 'react'
import { atom, useRecoilState } from 'recoil'
//import { getJSON } from 'utils/fetch'

import sharedAction from 'utils/sharedAction'

import PropertyPlanApi from 'apis/propertyPlanApi'

import { useProperties } from './properties'
import { usePropertyPlans } from './propertyPlans'

export enum AvailableErrorType {
  Duplicate = 'duplicate',
  ExceedsSqFootage = 'exceeds_sq_footage',
  ExceedsSqFootageFloor1 = 'exceeds_sq_footage_floor_1',
  ExceedsSqFootageFloor2 = 'exceeds_sq_footage_floor_2',
  NoGarage = 'no_garage',
  NoBasement = 'no_basement',
  NoAttic = 'no_attic',
  ExceedsADUCount = 'exceeds_adu_count',
  ExceedsADUSize = 'exceeds_adu_size',
}

export interface ProjectTemplateBase {
  id: number
  kind: string
  name: string
  icon?: string
  icon_svg?: string
  deprecated: boolean

  additional_score: number
  additional_home_value: number
  cost_estimate: number
  cost_estimate_low: number
  cost_estimate_high: number

  plural_copy: string
  determiner_copy: string
  action_copy: string
  singular_copy: string
  possessive_copy: string
  general_copy: string
}

export interface ProjectTemplate extends ProjectTemplateBase {
  description?: string

  available: {
    success: boolean
    error?: {
      code: AvailableErrorType
      message?: string
    }
  }

  expires_in?: number
  is_related_to_renovation?: boolean

  tags: Array<string>

  default_option: ProjectCustomizationOption
  position?: number
}

export interface ProjectPart {
  id: string
  size_type: SizeType
  size: { min: number; avg: number; max: number }
  scale?: { min: number; avg: number; max: number }
  customizations?: Array<ProjectCustomization>
}
export enum SizeType {
  UnitCount = 'unit_count',
  Length = 'length',
  Area = 'area',
}

export interface ProjectCostLine extends ProjectPart {
  customizations: never
}
export interface ProjectSpecification extends ProjectPart {
  customizations: Array<ProjectCustomization>
}

export interface ProjectCustomization {
  id: number
  key: string
  name: string
  description: string
  details?: string

  inclusive: boolean
  required: boolean
  invisible: boolean
  show_in_overview: boolean

  options: Array<ProjectCustomizationOption>
}

export interface ProjectCustomizationOption {
  id: number
  name: string
  description?: string
  show_chat_cta: boolean
  show_size: boolean
  show_scale: boolean
  size_name?: string
  size_description?: string
  lock: LockType
  part: ProjectPart
  defaults?: Array<ProjectDefaultSelection>
  image?: string
}
export enum LockType {
  Unlocked = 'unlocked',
  DefaultsOnly = 'defaults_only',
  Locked = 'locked',
}

export interface ProjectDefaultSelection {
  id: number
  name: string
  description: string
  is_default: boolean
  select_default: boolean
  selection: ProjectSelection
  image?: string
}

export interface ProjectSelection {
  id?: number // Optional here, because the client will construct selections
  key: string
  value: string
  size: [number, number]
  scale?: number
  selections?: Array<ProjectSelection>
  cost_estimate?: number // Read only value. Don't use for equality.
}

export function cloneProjectSelection(selection: ProjectSelection): ProjectSelection {
  // Leaving the Id in is fine, even if we're clone from a defaultSelection's
  // selection; any selections (even named) on the server side that don't exist
  // on the parent already, will be treated as new selections and given new ids.
  const ret = {
    ...selection,
    size: [selection.size[0], selection.size[1]] as [number, number],
  }
  if (selection.selections) ret.selections = selection.selections.map(cloneProjectSelection)
  return ret
}

export function generateProjectSelection(key: string, option: ProjectCustomizationOption): ProjectSelection {
  // If there are some defaults for this option, then go with one of those.
  if (option.defaults && option.defaults.length > 0) {
    const preferred: any = option.defaults.find((d) => d.is_default) || option.defaults[0]
    delete preferred.id // Might as well delete the ID in this explicit case, even if we don't need to.
    return cloneProjectSelection(preferred.selection)
  }
  // If there are no defaults, we need to construct a selection from scratch
  const ret: ProjectSelection = {
    key: key,
    value: option.part.id,
    size: [option.part.size.avg, 1], // For now, always provide the identity for y
  }
  if (option.part.scale) ret.scale = option.part.scale.avg
  if (option.part.customizations && option.part.customizations.length > 0) {
    // If there are customizations for the part, then generate selections for them.
    const subSelections = option.part.customizations
      .map((customization) => {
        if (customization.options && customization.options.length) {
          return generateProjectSelection(customization.key, customization.options[0])
        }
        return null
      })
      .filter((subSelection) => subSelection != null)
    ret.selections = subSelections as any
  }
  return ret
}

export const projectTemplatesState = atom<Array<ProjectTemplate> | null>({
  key: 'ProjectTemplates',
  default: null,
})

const projectTemplatesIsLoadingState = atom<number>({
  key: 'ProjectTemplates_isLoading',
  default: 0,
})

export const projectPartsState = atom<Array<ProjectPart> | null>({
  key: 'ProjectParts',
  default: null,
})

export function extractParts(rawTemplates: Array<ProjectTemplate>): {
  templates: Array<ProjectTemplate>
  parts: Array<ProjectPart>
} {
  const walkParts = (prev: { [id: string]: ProjectPart }, part: ProjectPart): { [id: string]: ProjectPart } => {
    if (part.customizations) {
      // Leaves will be added first.

      // Find all possible (parts) children
      const children = part.customizations.reduce((cPrev, customization) => {
        return customization.options.reduce((oPrev: any, option) => oPrev.concat([option.part]), cPrev)
      }, [])
      // And walk down them
      prev = children.reduce(walkParts, prev)
    }

    // Add this part.
    return Object.assign({}, prev, { [part.id]: part })
  }

  // Go through each raw template, extract any specifications and cost lines, replace
  // them with references to the known objects
  const results = rawTemplates.reduce(
    (prev, template) => {
      // If it isn't customizable, nothing to extract.
      if (!template.default_option) return { templates: prev.templates.concat(template), parts: prev.parts }
      // Otherwise, grab our parts.
      const allParts = walkParts({}, template.default_option.part)
      // And send them back.
      return {
        // make sure this is the part our template uses.
        templates: prev.templates.concat([
          Object.assign({}, template, { part: allParts[template.default_option.part.id] }),
        ]),
        parts: allParts,
      }
    },
    { templates: [], parts: {} } as {
      templates: Array<ProjectTemplate>
      parts: { [id: string]: ProjectPart }
    }
  )
  return {
    templates: results.templates,
    parts: Object.keys(results.parts).map((key) => results.parts[key]),
  }
}

export const useProjectTemplates = (): {
  latestProjectTemplates: Array<ProjectTemplate>
  projectTemplates: Array<ProjectTemplate>
  projectParts: Array<ProjectPart>
  isLoading: boolean
  refreshProjectTemplates: () => Promise<void>
} => {
  const { selectedProperty } = useProperties()
  const { selectedPropertyPlan } = usePropertyPlans()
  const [projectTemplates, setProjectTemplates] = useRecoilState(projectTemplatesState)
  const [projectParts, setProjectPart] = useRecoilState(projectPartsState)
  const [isLoading, setIsLoading] = useRecoilState(projectTemplatesIsLoadingState)

  useEffect(() => {
    if (!selectedProperty || !selectedPropertyPlan) return
    const _ = async () => {
      // If we don't have our templates yet...
      if (projectTemplates == null) {
        setIsLoading((p) => p + 1)

        // Get our templates from the API
        const newProjectTemplates = await sharedAction(
          ['listProjectTemplates', selectedProperty.id, selectedPropertyPlan.id],
          () => listProjectTemplates(selectedProperty.id, selectedPropertyPlan.id)
        )

        const allParts: {
          templates: Array<ProjectTemplate>
          parts: Array<ProjectPart>
        } = extractParts(newProjectTemplates)

        // Store in recoil
        setProjectPart(allParts.parts)
        setProjectTemplates(allParts.templates)

        setIsLoading((p) => p - 1)
      }
    }
    _()
  }, [setProjectTemplates, setProjectPart, projectTemplates, selectedProperty, selectedPropertyPlan, setIsLoading])

  const refreshProjectTemplates = useCallback(async () => {
    if (!selectedProperty || !selectedPropertyPlan) return
    setIsLoading((p) => p + 1)

    // Get our templates from the API
    const newProjectTemplates = await sharedAction(
      ['listProjectTemplates', selectedProperty.id, selectedPropertyPlan.id],
      () => listProjectTemplates(selectedProperty.id, selectedPropertyPlan.id)
    )

    const allParts: {
      templates: Array<ProjectTemplate>
      parts: Array<ProjectPart>
    } = extractParts(newProjectTemplates)

    // Store in recoil
    setProjectPart(allParts.parts)
    setProjectTemplates(allParts.templates)

    setIsLoading((p) => p - 1)
  }, [setProjectTemplates, setProjectPart, selectedProperty, selectedPropertyPlan, setIsLoading])

  const latestProjectTemplates = useMemo(() => {
    return (projectTemplates || []).filter((template) => !template.deprecated)
  }, [projectTemplates])

  return {
    latestProjectTemplates: latestProjectTemplates,
    projectTemplates: projectTemplates || [],
    projectParts: projectParts || [],
    isLoading: isLoading > 0,
    refreshProjectTemplates,
  }
}

export const listProjectTemplates = async (propertyId: number, propertyPlanId: number): Promise<ProjectTemplate[]> => {
  const api = new PropertyPlanApi(propertyId)
  return await api.listProjectTemplates(propertyPlanId)
}
