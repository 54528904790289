import React, { FC, useEffect, useRef, useState } from 'react'
import PieChart from './PieChart'

interface Props {
  className?: string
  equity: number
  total: number
  isLowData: boolean
  radius?: number | null
}

const FinancialOverviewChart: FC<Props> = ({ className, equity, total, isLowData, radius }) => {
  const ratio = equity == null || total == null ? null : equity / total

  const [isChartShown, setIsChartShown] = useState<boolean>(false)

  const ref = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if ('IntersectionObserver' in window) {
      const observer = new IntersectionObserver((entries) =>
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsChartShown(true)
          }
        })
      )
      if (ref.current) observer.observe(ref.current)

      return () => observer?.disconnect()
    } else {
      setIsChartShown(true)
    }
  }, [])

  return (
    <div ref={ref}>
      {isChartShown && <PieChart className={className} ratio={ratio} disabled={isLowData} radius={radius} />}
    </div>
  )
}

export default FinancialOverviewChart
