import React, { FC, useState, useEffect, useCallback, useRef } from 'react'
import classNames from 'classnames'

import styles from './PieChart.module.scss'

const STROKE_PRIMARY = 20
const STROKE_SECONDARY = 2
const MAX_ANIMATION_TIME = 1500

interface Props {
  className?: string
  disabled?: boolean
  ratio?: number | null
  radius?: number | null
  strokePrimary?: number
  strokeSecondary?: number
  animated?: boolean
}

function calculateAnimationRatio(startTime, targetTime) {
  return Math.min(MAX_ANIMATION_TIME, targetTime - startTime) / MAX_ANIMATION_TIME
}

const easeInOut = (t) => (t < 0.5 ? 8 * t * t * t * t : 1 - 8 * --t * t * t * t)
//const easeOut = (t) => 1 + --t * t * t * t * t

function calculateShowRatio(lastRatio, targetRatio, animationRatio, maxRatio) {
  // Keep our target ratio in bounds
  targetRatio = Math.max(0, Math.min(maxRatio, targetRatio || 0))

  // Find out how far we need the ratio to change.
  const change = targetRatio - lastRatio

  // Apply easing
  return lastRatio + change * easeInOut(animationRatio)
}

const PieChart: FC<Props> = ({
  className,
  disabled,
  ratio,
  radius = 110,
  strokePrimary = STROKE_PRIMARY,
  strokeSecondary = STROKE_SECONDARY,
  animated = true,
}) => {
  let maxRatio = 0.97
  if ((radius || 0) > 130) maxRatio = 0.98

  const defaultRatio = animated ? 0 : ratio

  const diameter = (radius || 0) * 2
  const width = (radius || 0) * 2 + strokePrimary

  const height = width
  const previousRatio = useRef<number>(0)
  const animationFrame = useRef<number>()
  const [lastRatio, setLastRatio] = useState(defaultRatio)
  const [showTime, setShowTime] = useState(0)
  const [, setFrame] = useState<number>(0)

  const strokePrimaryHalf = strokePrimary / 2
  const offsetPrimary = strokePrimaryHalf
  const offsetSecondary = strokePrimaryHalf
  const ballRadius = strokePrimaryHalf

  const animate = useCallback(() => {
    if (Date.now() - showTime < MAX_ANIMATION_TIME) {
      setFrame((prev) => prev + 1)
      animationFrame.current = requestAnimationFrame(animate)
    }
  }, [showTime, setFrame])

  useEffect(() => {
    // If we change ratio...
    if (previousRatio.current != ratio) {
      // Update what our previous ratio was
      setLastRatio(previousRatio.current || defaultRatio)
      // Update our show time, which will trigger an animation.
      setShowTime(Date.now()) // Update show time.
    }
    previousRatio.current = ratio || 0
  }, [ratio, setLastRatio, setShowTime, defaultRatio])

  useEffect(() => {
    // Animate us.
    animate()

    return () => {
      if (animationFrame.current != null) {
        cancelAnimationFrame(animationFrame.current)
      }
    }
  }, [showTime, animate])

  const showRatio = calculateShowRatio(lastRatio, ratio, calculateAnimationRatio(showTime, Date.now()), maxRatio)
  const angle = 2 * Math.PI * showRatio // In Radians

  let position1: (number | null)[] | null = null
  let position2: (number | null)[] | null = null
  let ballPosition: (number | null)[] | null = null
  if (showRatio >= 0.5) {
    position1 = [radius, diameter]
    ballPosition = position1
    if (showRatio > 0.5) {
      position2 = [(1 - Math.sin(angle)) * (radius || 0), (1 - Math.cos(angle)) * (radius || 0)]
      ballPosition = position2
    }
  } else if (showRatio > 0) {
    position1 = [(1 - Math.sin(angle)) * (radius || 0), (1 - Math.cos(angle)) * (radius || 0)]
    ballPosition = position1
  } else if (showRatio == 0) {
    ballPosition = [radius, 0]
  }

  let arcD = `M ${radius} ${0}`
  if (position1) arcD += ` A ${radius} ${radius} 0 0 0 ${position1[0]} ${position1[1]}`
  if (position2) arcD += ` A ${radius} ${radius} 0 0 0 ${position2[0]} ${position2[1]}`

  let arcBackground = `conic-gradient(
    from ${-angle}rad, 
    var(--c_arc-1), 
    ${angle / 2}rad, 
    var(--c_arc-2)
  )`
  if (disabled) {
    arcBackground = `conic-gradient(
      from ${-angle}rad, 
      var(--c_arc-disabled-1), 
      ${angle / 2}rad, 
      var(--c_arc-disabled-2)
    )`
  }

  const showBall = showRatio > 0 || ratio != null

  return (
    <svg
      className={classNames(styles.pieChart, className, disabled ? styles.disabled : null)}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
    >
      <g fill="none" fillRule="evenodd">
        {/* Base Circle */}
        <circle
          className={styles.baseCircle}
          transform={`translate(${offsetSecondary} ${offsetSecondary})`}
          strokeWidth={strokeSecondary}
          cx={radius || 0}
          cy={radius || 0}
          r={radius || 0}
        />
        {/* Chart Arcs  */}
        <defs>
          <mask id="PiChart_clip">
            <path
              transform={`translate(${offsetPrimary} ${offsetPrimary})`}
              stroke="white"
              strokeWidth={strokePrimary}
              strokeLinecap="round"
              d={arcD}
            />
          </mask>
        </defs>
        <foreignObject width={width} height={height} mask="url(#PiChart_clip)">
          <div
            className={styles.chartArc}
            style={{
              background: arcBackground,
            }}
          />
        </foreignObject>

        {/* Positional Ball */}
        {showBall ? (
          <circle
            className={styles.positionBall}
            transform={`translate(${ballPosition && ballPosition[0]} ${ballPosition && ballPosition[1]})`}
            cx={strokePrimaryHalf}
            cy={strokePrimaryHalf}
            r={ballRadius}
          />
        ) : null}
      </g>
    </svg>
  )
}
export default PieChart
