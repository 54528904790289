import React, { FC } from 'react'

interface Props {
  className?: string
}

const Svg: FC<Props> = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1280" height="1280" viewBox="0 0 1280 1280" className={className}>
    <g fill="none" fillRule="evenodd">
      <g stroke="currentColor" strokeWidth="1.6">
        <g>
          <g>
            <path
              d="M0 0L1280 1280M49.28 0L1280 1230.72M98.432 0L1280 1181.568M147.712 0L1280 1132.288M196.864 0L1280 1083.136M246.144 0L1280 1033.856M295.424 0L1280 984.576M344.576 0L1280 935.424M393.856 0L1280 886.144M443.136 0L1280 836.864M492.288 0L1280 787.712M541.568 0L1280 738.432M590.72 0L1280 689.28M640 0L1280 640M689.28 0L1280 590.72M738.432 0L1280 541.568M787.712 0L1280 492.288M836.864 0L1280 443.136M886.144 0L1280 393.856M935.424 0L1280 344.576M984.576 0L1280 295.424M1033.856 0L1280 246.144M1083.136 0L1280 196.864M1132.288 0L1280 147.712M1181.568 0L1280 98.432M1230.72 0L1280 49.28M0 1230.72L49.28 1280M0 1181.568L98.432 1280M0 1132.288L147.712 1280M0 1083.136L196.864 1280M0 1033.856L246.144 1280M0 984.576L295.424 1280M0 935.424L344.576 1280M0 886.144L393.856 1280M0 836.864L443.136 1280M0 787.712L492.288 1280M0 738.432L541.568 1280M0 689.28L590.72 1280M0 640L640 1280M0 590.72L689.28 1280M0 541.568L738.432 1280M0 492.288L787.712 1280M0 443.136L836.864 1280M0 393.856L886.144 1280M0 344.576L935.424 1280M0 295.424L984.576 1280M0 246.144L1033.856 1280M0 196.864L1083.136 1280M0 147.712L1132.288 1280M0 98.432L1181.568 1280M0 49.28L1230.72 1280"
              transform="translate(-200 -901) translate(200 901)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default Svg
