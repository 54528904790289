import { generatePath } from 'react-router-dom'

import { PropertyHead, Property } from 'recoil/properties'

export const PROPERTY_ROOT = '/properties'
export const PROPERTY_ID_PATH_PART = 'propertyId'
export const PROPERTY_PATH = `${PROPERTY_ROOT}/:${PROPERTY_ID_PATH_PART}`

function encodeProperty(property: PropertyHead, withId = false): string {
  // Pull the info we'll use.
  let value = property.delivery_line_1.toLowerCase()

  // First do text replacements
  value = value.replace(/\s/g, '-').replace(/[^a-z0-9-]/g, '')

  // Then uri encode to cover the rest (shouldn't catch anything)
  value = encodeURIComponent(value)

  return withId ? `${value}-${property.id}` : value
}

export function paramFromProperty(allProperties: Array<PropertyHead>, property: PropertyHead): string {
  // Encode our address
  const param = encodeProperty(property)
  const paramWithId = encodeProperty(property, true)

  // If there is another property who's two possible params would match
  // either of our two possible param, then use the param with the id.
  if (
    allProperties.some(
      (p) =>
        p.id != property.id &&
        ([param, paramWithId].includes(encodeProperty(p)) || [param, paramWithId].includes(encodeProperty(p, true)))
    )
  )
    return paramWithId
  return param
}

export function propertyFromParam(
  allProperties: Array<PropertyHead | Property>,
  param: string
): PropertyHead | Property | null {
  // If no param was supplied, they may be a single property user
  if (!param) {
    if (allProperties.length == 1) return allProperties[0]
    else return null
  }

  // Make sure param is all lowercase.
  param = param.toLowerCase()

  // First try to find it with the name and id.
  const matchesWithId = allProperties.filter((p) => encodeProperty(p, true) == param)

  // If we find one, that has to be our match.
  if (matchesWithId.length == 1) return matchesWithId[0]
  // If we find more than one, treat it as not finding any

  // If we didn't find any, then it must be name without the id
  const matchesWithoutId = allProperties.filter((p) => encodeProperty(p) == param)

  // If we find one, that has to be our match.
  if (matchesWithoutId.length == 1) return matchesWithoutId[0]
  // If we find more than one, treat it as not finding any

  // If we didn't find any, then no match exists.
  return null
}

export default function propertyPath(allProperties: Array<PropertyHead>, property: PropertyHead): string {
  // If we only have one property, the root is easy enough
  if (allProperties.length == 1) return ''
  return generatePath(PROPERTY_PATH, {
    [PROPERTY_ID_PATH_PART]: paramFromProperty(allProperties, property),
  })
}
