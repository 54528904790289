import React, { FC, useMemo } from 'react'
import { Link, NavLink, useLocation } from 'react-router-dom'
import cx from 'classnames'

import propertyPath from 'utils/propertyPath'

import { PropertyHead, useProperties } from 'recoil/properties'

import AccountIcon from 'svgs/account'
import DropdownArrow from 'svgs/dropdown-arrow'
import MenuIcon from 'svgs/menu'
import LogoIcon from 'svgs/logo'
import LogoPrideIcon from 'svgs/logo-pride'
import SparklesIcon from 'svgs/sparkles-two'

import Dropdown from 'components/Dropdown'
import Menu from 'components/Menu'
import { PropertyAvatar } from 'components/PropertyAvatar'
import IntercomCta from 'components/IntercomCta'

import styles from './styles.module.scss'

const linkDashboard = (property: PropertyHead, properties: Array<PropertyHead>) => (
  <NavLink
    activeClassName={styles.active}
    className={styles.link}
    to={`${propertyPath(properties, property)}/dashboard`}
  >
    {'Dashboard'}
  </NavLink>
)

const linkEditProperty = (property: PropertyHead, properties: Array<PropertyHead>) => (
  <NavLink activeClassName={styles.active} className={styles.link} to={`${propertyPath(properties, property)}/edit`}>
    {'Edit'}
  </NavLink>
)

const linkResources = () => (
  <a className={styles.link} href="/resources/library">
    {'Resources'}
  </a>
)

const linkPropertyPlan = (property: PropertyHead, properties: Array<PropertyHead>) => (
  <NavLink
    activeClassName={styles.active}
    className={styles.link}
    to={`${propertyPath(properties, property)}/property-plans`}
  >
    {'Project Planner'}
  </NavLink>
)

const linkEquity = (property: PropertyHead, properties: Array<PropertyHead>) => (
  <NavLink
    activeClassName={styles.active}
    className={styles.link}
    to={`${propertyPath(properties, property)}/finance-hub`}
  >
    {'Equity'}
  </NavLink>
)

const linkReports = (property: PropertyHead, properties: Array<PropertyHead>) => (
  <NavLink activeClassName={styles.active} className={styles.link} to={`${propertyPath(properties, property)}/reports`}>
    {'Advisory'}
  </NavLink>
)

const navMobile = (property: PropertyHead, properties: Array<PropertyHead>) => (
  <Menu className={cx('tw-shadow-lg', styles.menu)}>
    <Menu.Item>{linkDashboard(property, properties)}</Menu.Item>
    <Menu.Item>{linkPropertyPlan(property, properties)}</Menu.Item>
    <Menu.Item>{linkEquity(property, properties)}</Menu.Item>
    {property.marketplace && <Menu.Item>{linkReports(property, properties)}</Menu.Item>}
    <Menu.Item>{linkEditProperty(property, properties)}</Menu.Item>
    <Menu.Item>{linkResources()}</Menu.Item>
  </Menu>
)

const navDesktop = (property: PropertyHead, properties: Array<PropertyHead>) => (
  <>
    {linkDashboard(property, properties)}
    {linkPropertyPlan(property, properties)}
    {linkEquity(property, properties)}
    {property.marketplace && linkReports(property, properties)}
    {linkEditProperty(property, properties)}
    {linkResources()}
  </>
)

const profileMenu = (
  <Menu className={cx('tw-shadow-lg', styles.menu)}>
    <Menu.Item>
      <NavLink to="/properties">{'Properties'}</NavLink>
    </Menu.Item>
    <Menu.Item>
      <NavLink to="/payments">{'Payments'}</NavLink>
    </Menu.Item>
    <Menu.Item>
      <NavLink to="/profile">{'Profile'}</NavLink>
    </Menu.Item>
    <Menu.Divider />
    <Menu.Item>
      <a href="/users/sign_out">{'Sign out'}</a>
    </Menu.Item>
  </Menu>
)

interface NavigationProps {
  blank?: boolean
  propertyAgnostic?: boolean
}

const Navigation: FC<NavigationProps> = ({ blank, propertyAgnostic }) => {
  const { selectedProperty, lastProperty, properties } = useProperties()
  const { pathname } = useLocation()

  const property = propertyAgnostic ? null : selectedProperty || lastProperty || (properties || [])[0]

  const showPropertyMenu = property != null && pathname !== '/properties' && (properties || []).length > 1

  return (
    <header className={styles.header}>
      <nav>
        {!blank && property ? (
          <div className={styles.navMobile}>
            <Dropdown overlay={navMobile(property, properties || [])} hover menu placement="bottom-start">
              <div className={styles.menuIcon}>
                <MenuIcon />
              </div>
            </Dropdown>
          </div>
        ) : null}
        {/* Using an a element rather than Link so that we actually end up on
            the marketing page, when clicking it from the auth pages. */}
        <a href="/">
          {process.env.PRIDE_MODE == 'true' ? (
            <LogoPrideIcon className={styles.logo} />
          ) : (
            <LogoIcon className={styles.logo} />
          )}
        </a>
        {!blank && property ? <div className={styles.navDesktop}>{navDesktop(property, properties || [])}</div> : null}
      </nav>
      {!blank && (
        <div className={styles.menus}>
          {property && (
            <IntercomCta className={styles.advisorCta} location="header">
              <SparklesIcon />
              {'Chat with a Realm Advisor'}
            </IntercomCta>
          )}
          {showPropertyMenu && <PropertyMenu selectedProperty={property as PropertyHead} />}
          <div className={styles.profile}>
            <Dropdown overlay={profileMenu} hover menu placement="bottom-end">
              <div className={styles.user}>
                <AccountIcon />
              </div>
            </Dropdown>
          </div>
          <div className={styles.profileMobile}>
            <Dropdown overlay={profileMenu} click menu placement="bottom-end">
              <div className={styles.user}>
                <AccountIcon />
              </div>
            </Dropdown>
          </div>
        </div>
      )}
    </header>
  )
}

const PropertyMenu: FC<{ selectedProperty: PropertyHead }> = ({ selectedProperty }) => {
  const { properties } = useProperties()

  const sortedProperties = useMemo(() => {
    return (selectedProperty
      ? [selectedProperty, ...(properties || []).filter((p) => p.id !== selectedProperty?.id)]
      : properties || []
    ).slice(0, 6)
  }, [properties, selectedProperty])

  if (!selectedProperty) return null

  return (
    <div className={styles.propertyMenu}>
      <div className={styles.propertyMenuContainer}>
        <PropertyMenuItem property={selectedProperty} />
        <DropdownArrow className={styles.dropdownArrow} />
      </div>
      <div className={cx(styles.propertyMenuContainer, styles.allProperties)}>
        {sortedProperties.map((property) => (
          <PropertyMenuItem key={property.id} property={property} />
        ))}
        <Link className={styles.viewAllProperties} to="/properties">
          {'View all'}
        </Link>
      </div>
    </div>
  )
}

const PropertyMenuItem: FC<{ property: PropertyHead }> = ({ property }) => {
  const { properties } = useProperties()
  const path = `${propertyPath(properties || [], property)}/dashboard`

  return (
    <Link to={path} className={styles.propertyMenuItem}>
      <PropertyAvatar className={styles.propertyMenuItemImage} property={property} width={144} height={144} mapbox />
      <div className={styles.propertyMenuItemLabel}>{property.delivery_line_1}</div>
    </Link>
  )
}

export default Navigation
