import cx from 'classnames'
import React, { FC } from 'react'

interface MenuIconProps {
  className?: string
}

const MenuIcon: FC<MenuIconProps> = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    className={cx('menuicon', className)}
  >
    <path d="M0 0h24v24H0V0z" fill="none" />
    <path fill="currentColor" d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z" />
  </svg>
)

export default MenuIcon
