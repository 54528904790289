import React, { FC } from 'react'

const Svg: FC<any> = (props) => (
  <svg width="83" height="32" viewBox="0 0 83 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <mask id="mask0_13_45" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="83" height="32">
      <path
        d="M76.5039 10.2297L76.3659 10.3687L76.3629 11.4046C76.3629 14.311 76.0709 14.6052 73.1669 14.6052H72.129L71.991 14.7442V15.7489L72.129 15.888L73.1649 15.8908C76.0709 15.8908 76.3659 16.1822 76.3659 19.087V20.1217L76.5039 20.2607H77.509L77.6481 20.1217L77.6511 19.086C77.6511 16.1796 77.9421 15.8854 80.8471 15.8854H81.8849L82.023 15.7463V14.7428L81.8849 14.6038L80.8491 14.601C77.9421 14.601 77.6481 14.3096 77.6481 11.4048V10.3687L77.509 10.2297H76.5039Z"
        fill="white"
      />
      <path
        d="M67.2637 16.8839L67.0551 17.0921L67.0511 18.651C67.0511 23.0247 66.4125 23.6677 62.0416 23.6677H60.4803L60.2717 23.8758V24.9882L60.4803 25.1966L62.0386 25.2005C66.4125 25.2005 67.0551 25.8395 67.0551 30.2109V31.7722L67.2637 31.9803H68.376L68.5836 31.7722L68.5886 30.2123C68.5886 25.8385 69.2272 25.1956 73.5981 25.1956H75.1594L75.3679 24.9874V23.875L75.1594 23.6669L73.6011 23.6629C69.2272 23.6629 68.5836 23.0239 68.5836 18.6526V17.0913L68.376 16.8839H67.2637Z"
        fill="white"
      />
      <path
        d="M39.5232 0.180757V17.518L39.704 17.6989H41.5989L41.7797 17.518V0.180757L41.5989 0H39.704L39.5232 0.180757Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.2435 14.2254L25.2266 14.2737C24.8423 15.4059 24.1937 16.3486 23.2899 17.0089C22.3862 17.6691 21.2321 18.0418 19.8426 18.0418C18.0281 18.0418 16.5483 17.363 15.5243 16.1879C14.5004 15.0128 13.9382 13.3534 13.9382 11.3984C13.9382 9.44348 14.5004 7.78329 15.5243 6.60897C16.5483 5.43464 18.0281 4.75491 19.8436 4.75491C21.6592 4.75491 23.0764 5.39611 24.0408 6.54918C25.0051 7.70225 25.5027 9.34873 25.5027 11.3494V11.5132L25.0776 11.9389H16.8224L16.2732 12.5114C16.3983 13.4485 16.734 14.3345 17.3081 14.988C17.8891 15.6496 18.7184 16.0787 19.8426 16.0787C21.392 16.0787 22.3901 15.2947 22.988 14.1942L22.995 14.1817L23.2055 13.9708H25.0618L25.2435 14.2254ZM16.9446 10.1226H22.5937L23.139 9.57637C22.9682 8.70496 22.6464 7.99782 22.1279 7.506C21.6025 7.00624 20.8636 6.71841 19.8436 6.71841C18.8614 6.71841 18.1086 7.02908 17.5464 7.54394C16.9952 8.04926 16.6218 8.7576 16.4023 9.57935L16.9446 10.1226Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.9161 9.06131C34.8386 8.3496 34.5893 7.76462 34.1662 7.35524C33.7352 6.9381 33.1135 6.69378 32.2752 6.69378C31.5204 6.69378 30.8848 6.86799 30.3922 7.19216C29.8995 7.51633 29.547 7.99226 29.3573 8.60902L29.3503 8.63245L29.1328 8.85095H27.3501L27.1713 8.60226L27.1802 8.55975C27.3928 7.53738 27.8794 6.58453 28.7097 5.88753C29.54 5.19052 30.711 4.75531 32.2732 4.75531C33.9735 4.75531 35.1902 5.28984 35.9817 6.16045C36.7733 7.03106 37.1249 8.22009 37.1249 9.51161V17.518L36.9461 17.6983H35.1733L34.9945 17.5174V16.4243H34.4036C33.5971 17.336 32.2842 17.9935 30.6842 17.9935C29.4695 17.9935 28.4475 17.7021 27.7265 17.1261C27.0015 16.5476 26.5923 15.6905 26.5923 14.5851C26.5923 13.4926 26.9518 12.6131 27.7622 11.9089C28.5677 11.2097 29.8121 10.6905 31.5691 10.297L34.3788 9.66694L34.9161 9.06131ZM29.403 15.7517C29.7684 16.0135 30.3147 16.1528 31.0268 16.1528C32.2633 16.1528 33.2336 15.7209 33.8951 14.9905C34.5496 14.2675 34.9111 13.2426 34.919 12.0309L34.4026 11.5566L32.1997 12.0597V12.0597C30.7219 12.4019 29.8856 12.7833 29.4189 13.201C28.96 13.6114 28.8488 14.0641 28.8488 14.5853C28.8488 15.1065 29.0434 15.4935 29.403 15.7517Z"
        fill="white"
      />
      <path
        d="M53.7375 6.42165C54.5887 5.31884 55.8063 4.75491 57.167 4.75491C58.5346 4.75491 59.5704 5.15993 60.2617 5.96936C60.9529 6.7788 61.2837 7.96723 61.2837 9.51121V17.518L61.1029 17.6989H59.2079L59.0272 17.518V9.75633C59.0272 8.54009 58.7839 7.80057 58.3518 7.36278C57.9198 6.92499 57.2812 6.76688 56.432 6.76688C55.6623 6.76688 55.0326 7.03802 54.5927 7.62776C54.1477 8.22247 53.8865 9.15347 53.8865 10.4913V17.518L53.7058 17.6989H51.8108L51.63 17.518V9.75633C51.63 8.54009 51.3867 7.80057 50.9547 7.36278C50.5227 6.92499 49.884 6.76688 49.0349 6.76688C48.2652 6.76688 47.6355 7.03802 47.1955 7.62776C46.7506 8.22247 46.4894 9.15347 46.4894 10.4913V17.518L46.3086 17.6989H44.4146L44.2339 17.518V5.27891L44.4146 5.09815H46.3096L46.4904 5.27891V5.95606H47.0287C47.6425 5.19131 48.6624 4.75491 49.7698 4.75491C50.615 4.75491 51.2934 4.91044 51.8436 5.20482C52.3789 5.49085 52.7861 5.90441 53.1079 6.42165H53.7375Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.208566 17.6288L0 17.4202V0.236566L0.208566 0.0281982H7.22533C8.98125 0.0281982 10.4551 0.47016 11.493 1.35428C12.5309 2.2384 13.1178 3.55793 13.1178 5.26619C13.1178 6.59088 12.7573 7.6498 12.1127 8.4642C11.4831 9.25874 10.5902 9.81213 9.52353 10.1595L9.32886 10.7433L13.9749 17.3112L13.7475 17.629H11.4672L11.2377 17.4653L6.37219 10.566L6.21726 10.4799H3.04606L2.52762 10.9984V17.4202L2.31906 17.6286L0.208566 17.6288ZM3.04606 8.26755H6.98299C8.19069 8.26755 9.0766 8.0276 9.65959 7.54452C10.2356 7.06621 10.5415 6.32749 10.5415 5.26619C10.5415 4.20488 10.2525 3.46219 9.71322 2.98467C9.16996 2.50398 8.34662 2.26442 7.22533 2.26442H3.04606L2.52762 2.78306V7.74892L3.04606 8.26755Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_13_45)">
      <g filter="url(#filter0_f_13_45)">
        <ellipse
          cx="47.955"
          cy="-2.30859"
          rx="27.4799"
          ry="25.7448"
          transform="rotate(-75 47.955 -2.30859)"
          fill="#004F9D"
        />
      </g>
      <g filter="url(#filter1_f_13_45)">
        <ellipse
          cx="67.955"
          cy="42.6914"
          rx="27.4799"
          ry="25.7448"
          transform="rotate(-75 67.955 42.6914)"
          fill="#004F9D"
        />
      </g>
      <g filter="url(#filter2_f_13_45)">
        <ellipse
          cx="5.97872"
          cy="24.7326"
          rx="28.2576"
          ry="28.7208"
          transform="rotate(-75 5.97872 24.7326)"
          fill="#F9BE03"
        />
      </g>
      <g filter="url(#filter3_f_13_45)">
        <ellipse cx="9.4195" cy="-16.0076" rx="26.5" ry="25.5" transform="rotate(-75 9.4195 -16.0076)" fill="#FA5701" />
      </g>
      <g filter="url(#filter4_f_13_45)">
        <ellipse
          cx="24.9212"
          cy="32.4355"
          rx="25.8207"
          ry="25.0933"
          transform="rotate(-75 24.9212 32.4355)"
          fill="#679B5F"
        />
      </g>
      <g filter="url(#filter5_f_13_45)">
        <ellipse cx="84.686" cy="11.8839" rx="20.6394" ry="24" transform="rotate(-75 84.686 11.8839)" fill="#7F3D9F" />
      </g>
      <g filter="url(#filter6_f_13_45)">
        <circle cx="-11.3085" cy="3.50167" r="13.5" transform="rotate(-75 -11.3085 3.50167)" fill="#FC001A" />
      </g>
    </g>
    <defs>
      <filter
        id="filter0_f_13_45"
        x="2.08374"
        y="-49.6822"
        width="91.7427"
        height="94.7472"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="10" result="effect1_foregroundBlur_13_45" />
      </filter>
      <filter
        id="filter1_f_13_45"
        x="22.0837"
        y="-4.68216"
        width="91.7427"
        height="94.7472"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="10" result="effect1_foregroundBlur_13_45" />
      </filter>
      <filter
        id="filter2_f_13_45"
        x="-42.7183"
        y="-23.5634"
        width="97.394"
        height="96.5918"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="10" result="effect1_foregroundBlur_13_45" />
      </filter>
      <filter
        id="filter3_f_13_45"
        x="-36.1552"
        y="-62.4482"
        width="91.1493"
        height="92.8812"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="10" result="effect1_foregroundBlur_13_45" />
      </filter>
      <filter
        id="filter4_f_13_45"
        x="-20.2278"
        y="-13.3434"
        width="90.2981"
        height="91.5577"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="10" result="effect1_foregroundBlur_13_45" />
      </filter>
      <filter
        id="filter5_f_13_45"
        x="40.8909"
        y="-29.0031"
        width="87.5901"
        height="81.774"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="10" result="effect1_foregroundBlur_13_45" />
      </filter>
      <filter
        id="filter6_f_13_45"
        x="-44.8118"
        y="-30.0017"
        width="67.0066"
        height="67.0067"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="10" result="effect1_foregroundBlur_13_45" />
      </filter>
    </defs>
  </svg>
)

export default Svg
