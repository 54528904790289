import { useState, useEffect, useCallback } from 'react'
import { atom, useRecoilState } from 'recoil'

import sharedAction from 'utils/sharedAction'

import PackageAPI from 'apis/packageApi'

import { useProperties } from './properties'

import { PackagePrice } from './packages'
import { Payment } from 'recoil/payments'

export interface PackagePurchase {
  id: number
  created_at: string
  status: PackagePurchaseStatusType
  price: PackagePrice
  payments: Payment[]
  package_id: number
  package: {
    id: number
    name: string
    description: string
    mobile_card_image?: string
    web_card_image?: string
  }
}

export enum PackagePurchaseStatusType {
  Requested = 'requested',
  Pending = 'pending',
  Completed = 'completed',
  Canceled = 'canceled',
  PastDue = 'past_due',
}

export interface CPackagePurchase {
  package_id: number
}

export interface UPackagePurchase {
  status: PackagePurchaseStatusType
}

export const packagePurchasesState = atom<Array<PackagePurchase | null> | null>({
  key: 'packagePurchases',
  default: null,
})

export const usePackagePurchases = (): {
  packagePurchases: Array<PackagePurchase | null>
  isLoading: boolean
  addPackagePurchase: (project: CPackagePurchase) => Promise<PackagePurchase | null>
  updatePackagePurchase: (
    projectId: number,
    project: UPackagePurchase,
    paymentId: number
  ) => Promise<PackagePurchase | null>
} => {
  const { selectedProperty } = useProperties()
  const [packagePurchases, setPackagePurchases] = useRecoilState(packagePurchasesState)

  const [isLoading, setIsLoading] = useState(false)

  const addPackagePurchase = useCallback(
    async (newPackagePurchase: CPackagePurchase): Promise<PackagePurchase | null> => {
      if (!selectedProperty) return null
      // Call our API
      const packagePurchase = await createPackagePurchase(selectedProperty.id, newPackagePurchase)

      // Add it to packagePurchases
      setPackagePurchases((packagePurchases) => (packagePurchases || []).concat([packagePurchase]))

      // Return the project object added.
      return packagePurchase
    },
    [selectedProperty, setPackagePurchases]
  )

  const _updatePackagePurchase = useCallback(
    async (
      packagePurchaseId: number,
      newPackagePurchase: UPackagePurchase,
      paymentId: number
    ): Promise<PackagePurchase | null> => {
      if (!selectedProperty) return null
      // Call our API
      const packagePurchase = await updatePackagePurchase(
        selectedProperty.id,
        packagePurchaseId,
        newPackagePurchase,
        paymentId
      )

      // Update it in package purchases
      setPackagePurchases((packagePurchases) =>
        (packagePurchases || []).map((rp) => (rp?.id == packagePurchase.id ? packagePurchase : rp))
      )

      // Return the project object added.
      return packagePurchase
    },
    [selectedProperty, setPackagePurchases]
  )

  useEffect(() => {
    if (!selectedProperty) return
    const runme = async () => {
      // If we don't have our packagePurchases yet...
      if (packagePurchases == null) {
        setIsLoading(true)

        // Get our packagePurchases from the API
        const newPackagePurchases = await sharedAction(['listPackagePurchases', selectedProperty.id], () =>
          listPackagePurchases(selectedProperty.id)
        )

        // Store in recoil
        setPackagePurchases(newPackagePurchases)

        setIsLoading(false)
      }
    }
    runme()
  }, [setPackagePurchases, packagePurchases, selectedProperty])

  return {
    packagePurchases: packagePurchases || [],
    isLoading,
    addPackagePurchase: addPackagePurchase,
    updatePackagePurchase: _updatePackagePurchase,
  }
}

export const listPackagePurchases = async (propertyId: number): Promise<Array<PackagePurchase>> => {
  const api = new PackageAPI(propertyId)
  return await api.listPackagePurchases()
}

export const createPackagePurchase = async (
  propertyId: number,
  packagePurchase: CPackagePurchase
): Promise<PackagePurchase> => {
  const api = new PackageAPI(propertyId)
  return await api.createPackagePurchase(packagePurchase)
}

export const updatePackagePurchase = async (
  propertyId: number,
  packagePurchaseId: number,
  packagePurchase: UPackagePurchase,
  paymentId: number
): Promise<PackagePurchase> => {
  const api = new PackageAPI(propertyId)
  return await api.updatePackagePurchase(packagePurchaseId, packagePurchase, paymentId)
}
