import React, { FC, useCallback } from 'react'

import { useSetRecoilState } from 'recoil'

import { mortgagesState, Mortgage, getMortgages } from 'recoil/mortgages'
import { Property } from 'recoil/properties'
import { LifecycleStatuses } from 'recoil/model'
import { alertState } from 'recoil/alert'

import { FinancialAnalysisFormAction, parseNumber } from '..'

import GeneralForm from 'components/GeneralForm'

import { deleteJSON, patchJSON, postJSON } from 'utils/fetch'
import { track } from 'utils/analytics'

interface MortgageFormProps {
  mortgage: Mortgage
  mortgageProps: (input: keyof Mortgage) => { onChange: (value: any) => void; value: any }
  dispatch: React.Dispatch<FinancialAnalysisFormAction>
  property: Property
  refreshProperty: (property: Property) => Promise<Property>
  isRemovable: boolean
  loading?: boolean
}

const MortgageForm: FC<MortgageFormProps> = ({
  mortgage,
  mortgageProps,
  dispatch,
  property,
  refreshProperty,
  isRemovable,
  loading,
}) => {
  const setAlert = useSetRecoilState(alertState)
  const setMortgages = useSetRecoilState(mortgagesState)

  const handleRemoveMortgage = async () => {
    track('submit form', { section: 'finance', form: 'mortgage-details', action: 'remove' })

    if (mortgage.lifecycle_status != LifecycleStatuses.NeedsCreated) {
      return await deleteJSON(`/api/v1/properties/${property.id}/mortgages/${mortgage.id}`)
    }
    return null
  }

  async function handleSave() {
    track('submit form', { section: 'finance', form: 'mortgage-details', action: 'save' })

    let mortgageResponse
    if (mortgage.lifecycle_status == LifecycleStatuses.NeedsCreated) {
      mortgageResponse = await postJSON(`/api/v1/properties/${property.id}/mortgages`, {
        mortgage: {
          amount: parseNumber(mortgage.amount || 0),
          rate: mortgage.rate,
          origination_date: mortgage.origination_date,
          term: mortgage.term,
        },
      })
    } else {
      mortgageResponse = await patchJSON(`/api/v1/properties/${property.id}/mortgages/${mortgage.id}`, {
        mortgage: {
          amount: parseNumber(mortgage.amount || 0),
          rate: mortgage.rate,
          origination_date: mortgage.origination_date,
          term: mortgage.term,
        },
      })
    }
    return mortgageResponse
  }

  const generalUpdate = useCallback(
    (specificUpdate: () => any, checkValid: boolean, deleteMortgage: boolean) => {
      return async () => {
        dispatch({ type: 'setLoading' })
        const errors = {}
        let isValid = true
        if (checkValid) {
          const requiredFields = ['amount', 'rate', 'origination_date', 'term']
          requiredFields.forEach((key) => {
            if (mortgage[key] == null || mortgage[key] == '') {
              errors[`missing key`] = [`${key} is requried`]
              isValid = false
            }
          })
        }
        if (isValid) {
          const mortgageResponse = await specificUpdate()
          if (mortgageResponse != null && mortgageResponse.isError && mortgageResponse.jsonBody) {
            Object.keys(mortgageResponse.jsonBody).forEach((key) => {
              errors[key] = mortgageResponse.jsonBody[key]
            })
          } else if (deleteMortgage) {
            dispatch({
              type: 'removeMortgage',
              payload: {
                mortgageId: mortgage.id,
              },
            })
          } else {
            dispatch({
              type: 'setMortgage',
              payload: {
                originalMortgageId: mortgage.id,
                mortgage: mortgageResponse.jsonBody,
              },
            })
          }
        }
        if (Object.keys(errors).length > 0) {
          dispatch({
            type: 'setState',
            payload: {
              errors: errors,
              isLoading: false,
            },
          })
          track('equity calc estimates error', { errors, propertyId: property.id })
          setAlert({ type: 'ERROR', message: 'Error updating your loan info.', id: Date.now() })
          return
        }
        // Sync the updated mortgages to recoil
        const mortgages = await getMortgages(property.id)
        setMortgages(mortgages)

        // Post an empty update to finances, so we can do any necessary recalculations.
        await postJSON(`/api/v1/properties/${property.id}/finances`, {})

        dispatch({
          type: 'setState',
          payload: {
            errors: {},
            isLoading: false,
          },
        })

        track('equity save calc estimates', { propertyId: property.id })
        setAlert({
          type: 'SUCCESS',
          message: 'Successfully updated your loan info.',
          id: Date.now(),
          ctaText: 'View details',
          ctaTo: '../finance-hub',
        })

        await refreshProperty(property)
      }
    },
    [dispatch, property, mortgage, setMortgages, refreshProperty, setAlert]
  )

  return (
    <GeneralForm
      inputProps={mortgageProps}
      onSave={generalUpdate(handleSave, true, false)}
      deleteFields={
        isRemovable
          ? {
              descriptionLong: 'Remove this loan',
              descriptionShort: 'Remove loan',
              onDelete: generalUpdate(handleRemoveMortgage, false, true),
            }
          : undefined
      }
      formFields={[
        {
          name: 'amount',
          labelName: 'Mortgage Amount',
          dataType: 'currency',
          tooltipContent: 'The total amount of your primary mortgage',
          trackAsTag: 'edit-mortgages-amount',
        },
        {
          name: 'rate',
          labelName: 'Current Mortgage Rate',
          dataType: 'number',
          tooltipContent:
            'This is the current interest rate of your current mortgage loan, pulled from historical data. Please confirm or input the correct value. If you do not currently have a mortgage balance, this should say 0%.',
          trackAsTag: 'edit-mortgages-rate',
          placeholder: 'e.g. 3.8',
          minVal: 1,
          maxVal: 9.9,
          stepVal: 0.1,
        },
        {
          name: 'origination_date',
          labelName: 'Origination Date',
          dataType: 'date',
          tooltipContent: 'The date that your mortgage was funded, or the date that you signed the mortgage deed',
          trackAsTag: 'edit-mortgages-date',
        },
        {
          name: 'term',
          labelName: 'Term',
          dataType: 'number',
          tooltipContent: 'The length of your mortgage, which is usually a 15 or 30 year term',
          trackAsTag: 'edit-mortgages-term',
          minVal: 1,
          maxVal: 30,
        },
      ]}
      loading={loading}
    />
  )
}

export default MortgageForm
