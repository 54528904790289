import React, { Dispatch, FC, useCallback } from 'react'
import { Mortgage } from 'recoil/mortgages'
import { useProperties } from 'recoil/properties'
import MortgageForm from 'pages/FinanceHub/FinancialAnalysis/Forms/MortgageForm'
import { FinancialAnalysisFormAction, FinancialAnalysisFormState } from 'pages/FinanceHub/FinancialAnalysis'
import FunkyCard from 'components/FunkyCard'
import FancyButton from 'components/FancyButton'
import Circles from 'svgs/edit-property/patterns-circles'

import styles from './styles.module.scss'

type MortgageCardProps = {
  state: FinancialAnalysisFormState
  dispatch: Dispatch<FinancialAnalysisFormAction>
  mortgage: Mortgage
  index: number
  handleAddMortgage: () => void
  handleSetNoMortgage: () => Promise<void>
}

const MortgageCard: FC<MortgageCardProps> = ({
  state,
  dispatch,
  mortgage,
  index,
  handleAddMortgage,
  handleSetNoMortgage,
}) => {
  const { selectedProperty: property, refreshProperty } = useProperties()
  const handleMortgageChange = useCallback(
    (mortgage: Mortgage, f: keyof Mortgage) => {
      return (val) => {
        dispatch({
          type: 'setMortgageField',
          payload: { mortgageId: mortgage.id, f, val },
        })
      }
    },
    [dispatch]
  )

  const mortgageInputProps = useCallback(
    (field: keyof Mortgage) => {
      const mortgageProps = (mortgage: Mortgage, f: keyof Mortgage): { onChange: (value: any) => void; value: any } => {
        return {
          onChange: handleMortgageChange(mortgage, f),
          value: mortgage[f],
        }
      }
      return mortgageProps(mortgage, field)
    },
    [handleMortgageChange, mortgage]
  )

  if (!property) return null

  return (
    <FunkyCard
      key={mortgage.id}
      className={styles.card}
      title="Mortgage details"
      description="Add or edit your existing mortgage details, add additional loans if necessary"
      background={<Circles className={styles.circles} />}
    >
      <MortgageForm
        mortgage={mortgage}
        mortgageProps={mortgageInputProps}
        dispatch={dispatch}
        property={property}
        refreshProperty={refreshProperty}
        isRemovable
        loading={state.isLoading}
      />
      {index == state.mortgages.length - 1 && (
        <div className={styles.addMortgageContainer}>
          <FancyButton onClick={handleAddMortgage} className={styles.addMortgageLarge}>
            {'Add another mortgage or home loan'}
          </FancyButton>
          <FancyButton onClick={handleAddMortgage} className={styles.addMortgageSmall}>
            {'Add another mortgage'}
          </FancyButton>
          {index === 0 && property.equity_state !== 'no_mortgage' && (
            <FancyButton onClick={handleSetNoMortgage}>{'No mortgage or home loan'}</FancyButton>
          )}
        </div>
      )}
    </FunkyCard>
  )
}
export default MortgageCard
