import { useState, useEffect, useCallback } from 'react'
import { atom, useRecoilState } from 'recoil'
//import { getJSON } from 'utils/fetch'

import sharedAction from 'utils/sharedAction'
import useIsMounted from 'utils/useIsMounted'

import PropertyPlanApi from 'apis/propertyPlanApi'

import { useProperties } from './properties'

import { usePropertyPlans } from './propertyPlans'
import {
  useProjectTemplates,
  ProjectTemplate,
  ProjectSelection,
  AvailableErrorType,
  cloneProjectSelection,
} from './projectTemplates'

export interface Project {
  id: number
  // property_plan_id: number // Right now, we don't care what property_plan it belongs to.

  project_template_id: number

  square_footage?: number
  square_footage_from_lot: number
  increases_sq_ft: boolean
  exempt_from_zoning: boolean

  kind: string
  icon?: string
  icon_svg?: string
  name: string
  description?: string
  customized: boolean

  tier?: string
  size?: string
  scope?: string

  additional_score: number
  additional_home_value: number
  cost_estimate_low: number
  cost_estimate_high: number
  cost_estimate: number

  available: {
    success: boolean
    error?: {
      code: AvailableErrorType
      message?: string
    }
  }

  selection?: ProjectSelection
}

export interface CProject {
  project_template_id: number
}

export interface UProject {
  name?: string
  customized?: boolean
  selection?: ProjectSelection
}

export interface Update {
  id: number
  method: 'delete'
}

export interface UpdateResult {
  status: number
  error?: string
  project?: Project
}

export interface TempProject extends Project {
  _temporary: boolean
}

export const projectsState = atom<Array<Project | null> | null>({
  key: 'projects',
  default: null,
})

const deletingProjectsState = atom<Array<number>>({
  key: 'deletingProjects',
  default: [],
})

const tempProjectsState = atom<Array<Project | null>>({
  key: 'tempProjects',
  default: [],
})

function createTempProject(id: number, template?: ProjectTemplate): TempProject | null {
  if (template && template.default_option) {
    return createTempFromCustomizable(id, template)
  }
  return null
}

function createTempFromCustomizable(id: number, template: ProjectTemplate): TempProject {
  // Find the default selection
  const defaultSelection = template?.default_option?.defaults
    ? template.default_option.defaults.find((d) => d.is_default) || template.default_option.defaults[0]
    : { selection: {} }

  return Object.assign(
    {
      id: id,

      project_template_id: template.id,

      square_footage_from_lot: 0,
      increases_sq_ft: false,
      exempt_from_zoning: false,

      kind: template.kind,
      icon: template.icon,
      icon_svg: template.icon_svg,
      name: template.name,
      customized: false,
      description: template.description,

      additional_score: template.additional_score,
      additional_home_value: 0,
      cost_estimate_low: 0,
      cost_estimate_high: 0,
      cost_estimate: 0,

      selection: cloneProjectSelection(defaultSelection.selection as any),

      // Assume availability from template
      available: Object.assign({}, template.available),
    },
    { _temporary: true }
  )
}

export const useProjects = (): {
  projects: Array<Project>
  isLoading: boolean
  addProject: (project: CProject) => Promise<Project | null>
  removeProject: (projectId: number) => Promise<Project | null>
  removeProjects: (projectIds: Array<number>) => Promise<Array<Project | null> | null>
  updateProject: (projectId: number, project: UProject) => Promise<Project | null>
  refreshProjects: () => Promise<Array<Project> | null>
} => {
  const isMounted = useIsMounted()
  const { selectedProperty } = useProperties()
  const { selectedPropertyPlan, refreshPropertyPlan, setIsInvalid: setPropertyPlanAsInvalid } = usePropertyPlans()
  const [projects, setProjects] = useRecoilState(projectsState)
  const [deletingProjects, setDeletingProjects] = useRecoilState(deletingProjectsState)
  const [tempProjects, setTempProjects] = useRecoilState(tempProjectsState)
  const { projectTemplates: templates, refreshProjectTemplates } = useProjectTemplates()

  const [isLoading, setIsLoading] = useState(false)

  const refreshProjects = useCallback(async () => {
    if (!selectedProperty || !selectedPropertyPlan) return null
    if (isMounted()) setIsLoading(true)

    // Get our projects from the API
    const newProjects = await sharedAction(['listProjects', selectedProperty.id, selectedPropertyPlan.id], () =>
      listProjects(selectedProperty.id, selectedPropertyPlan.id)
    )

    // Store in recoil
    setProjects(newProjects)

    if (isMounted()) setIsLoading(false)

    return newProjects
  }, [setProjects, selectedProperty, selectedPropertyPlan, isMounted])

  const addProject = useCallback(
    async (project: CProject): Promise<Project | null> => {
      if (!selectedProperty || !selectedPropertyPlan) return null
      // Insert a temporary place holder. We can make a guess based on
      // the templates we already have.
      const template = templates.find((t) => t.id == project.project_template_id)
      // Find out next available temp Id
      const nextTempId = tempProjects.reduce((prev, p) => Math.min(prev, p?.id || 0), 0) - 1
      let tempNewProject: TempProject | null = createTempProject(nextTempId, template)

      if (tempNewProject) {
        setTempProjects((projects) => [...projects, tempNewProject])
      }

      // Also lets invalidate propertyPlan to indicate that we're loading in a new one
      setPropertyPlanAsInvalid(true)
      try {
        // Call our API
        const newProject = await createProject(selectedProperty.id, selectedPropertyPlan.id, project)

        // In order that we do not show a 'duplicate' project temporarilly,
        // Update our temp project so that our temporary id matches our new
        // project's Id.
        const oldTempId = tempNewProject?.id
        tempNewProject = Object.assign({}, tempNewProject, { id: newProject.id })
        setTempProjects((projects) => projects.map((p) => (p?.id == oldTempId ? tempNewProject : p)))

        await Promise.all([
          // Now we need to also fetch the updated property plan
          refreshPropertyPlan(),
          // And ALL the projects
          refreshProjects(),
          // And more templates
          refreshProjectTemplates(),
        ])
        // Return the project added.
        return newProject
      } finally {
        // Succeed or fail, we need to remove our temp project.
        setTempProjects((projects) =>
          // Don't include any of our temps
          projects.filter((p) => p?.id != tempNewProject?.id)
        )

        // Always return the PP to a valid state when we're done.
        setPropertyPlanAsInvalid(false)
      }
    },
    [
      selectedProperty,
      selectedPropertyPlan,
      refreshProjectTemplates,
      refreshProjects,
      refreshPropertyPlan,
      setPropertyPlanAsInvalid,
      setTempProjects,
      tempProjects,
      templates,
    ]
  )

  const _updateProject = useCallback(
    async (projectId: number, projectUpdate: UProject): Promise<Project | null> => {
      if (!selectedProperty || !selectedPropertyPlan) return null
      // Find and save the one we're updating
      const preUpdateProject = projects?.find((p) => p?.id == projectId)
      // If we found the project, update it now locally. If not
      // we'll just add the updated project afterwards.
      if (preUpdateProject) {
        // Find the template
        const template = templates.find((t) => t.id == preUpdateProject.project_template_id)
        let temp: TempProject
        if (template?.default_option) {
          const selection = projectUpdate.selection || preUpdateProject.selection
          const name = projectUpdate.name || preUpdateProject.name
          const customized = projectUpdate.customized || preUpdateProject.customized

          temp = Object.assign({}, preUpdateProject, {
            project_template_id: template.id,

            selection: selection,

            kind: template.kind,
            name: name,
            description: template.description,
            customized: customized,

            /* The subsequent scores can't be guess until we get stuff back
              so don't override them. We'll indicate they're calculating in
              the UI eventually
            */
            _temporary: true,
            // additional_score:
            // additional_home_value:
            // cost_estimate_low:
            // cost_estimate_high:
            // cost_estimate:
          })
        }
        // Create a temp copy with a modified copy of it now.
        setTempProjects((projects) => [...projects, temp])
      }
      // Also lets invalidate propertyPlan to indicate that we're loading in a new one
      setPropertyPlanAsInvalid(true)
      try {
        // Call our API
        const updatedProject = await updateProject(
          selectedProperty.id,
          selectedPropertyPlan.id,
          projectId,
          projectUpdate
        )

        await Promise.all([
          // if we succeeded in updating the project, then we need to also fetch the
          // updated property plan and replace the temp with our updated project
          refreshPropertyPlan(selectedPropertyPlan.id),
          // And ALL the projects
          refreshProjects(),
          // And more templates
          refreshProjectTemplates(),
        ])

        // Return the project object updated.
        return updatedProject
      } finally {
        // Succeed or fail, remove the temp.
        setTempProjects((projects) => projects.filter((p) => p?.id != preUpdateProject?.id))

        // Always return the PP to a valid state when we're done.
        setPropertyPlanAsInvalid(false)
      }
    },
    [
      projects,
      setPropertyPlanAsInvalid,
      templates,
      setTempProjects,
      selectedProperty,
      selectedPropertyPlan,
      refreshPropertyPlan,
      refreshProjects,
      refreshProjectTemplates,
    ]
  )

  const removeProject = useCallback(
    async (projectId: number): Promise<Project | null> => {
      if (!selectedProperty || !selectedPropertyPlan) return null
      // Lets invalidate propertyPlan to indicate that we're loading in a new one
      setPropertyPlanAsInvalid(true)
      // Add projectId to the list of deleted projects.
      setDeletingProjects((ids) => [...ids, projectId])
      try {
        // Call our API
        const deletedProject = await deleteProject(selectedProperty.id, selectedPropertyPlan.id, projectId)

        // These should done concurrently.
        try {
          await Promise.all([
            // if we succeeded in removing the project, then we need to also
            // fetch the updated property plan
            refreshPropertyPlan(selectedPropertyPlan.id),
            // And ALL the projects
            refreshProjects(),
            // And more templates
            refreshProjectTemplates(),
          ])
        } catch (err) {
          // I mean... right now we don't have the best error handling.
          // If we failed to fetch the updated plan, lets at least log this
          console.error('Failed to refresh plan info during "removeProject":', err)
        }

        // Return the project object deleted.
        return deletedProject
      } finally {
        // Try or fail, we need to drop the id from our delete list.
        setDeletingProjects((ids) => ids.filter((id) => id != projectId))
        // Always return the PP to a valid state when we're done.
        setPropertyPlanAsInvalid(false)
      }
    },
    [
      selectedProperty,
      selectedPropertyPlan,
      refreshProjectTemplates,
      refreshProjects,
      refreshPropertyPlan,
      setDeletingProjects,
      setPropertyPlanAsInvalid,
    ]
  )

  const removeProjects = useCallback(
    async (projectIds: Array<number>): Promise<Array<Project | null> | null> => {
      if (!selectedProperty || !selectedPropertyPlan) return null
      // Lets invalidate propertyPlan to indicate that we're loading in a new one
      setPropertyPlanAsInvalid(true)

      // Add all project ids to our list of deletes.
      setDeletingProjects((ids) => [...ids, ...projectIds])

      try {
        // Call our API
        const deleteResults = await batchUpdateProject(
          selectedProperty.id,
          selectedPropertyPlan.id,
          projectIds.map((id) => ({
            id: id,
            method: 'delete',
          }))
        )
        const isFailure = (r) => r.status != 200 && r.status != 404
        const isSuccess = (r) => !isFailure(r)

        // if we succeeded in removing any one project, then we need to also
        // fetch the updated property plan
        if (deleteResults.some(isSuccess)) {
          // These should done concurrently.
          try {
            await Promise.all([
              refreshPropertyPlan(selectedPropertyPlan.id),
              // And ALL the projects
              refreshProjects(),
              // And more templates
              refreshProjectTemplates(),
            ])
          } catch (err) {
            // I mean... right now we don't have the best error handling.
            // If we failed to fetch the updated plan, lets at least log this
            console.error('Failed to refresh plan info during "removeProjects":', err)
          }
        }

        // Return the project objects deleted (null for those that don't)
        if (deleteResults) return deleteResults.map((r) => (isSuccess(r) ? r.project || null : null))
        // If we failed the entire request, just return all null.
        return projectIds.map(() => null)
      } finally {
        // Try or fail, we need to drop the ids from our delete list.
        setDeletingProjects((ids) => ids.filter((id) => projectIds.indexOf(id) == -1))

        // Always return the PP to a valid state when we're done.
        setPropertyPlanAsInvalid(false)
      }
    },
    [
      selectedProperty,
      selectedPropertyPlan,
      refreshProjectTemplates,
      refreshProjects,
      refreshPropertyPlan,
      setDeletingProjects,
      setPropertyPlanAsInvalid,
    ]
  )

  useEffect(() => {
    const runme = async () => {
      // If we don't have our projects yet...
      if (projects == null) {
        await refreshProjects()
      }
    }
    runme()
  }, [projects, refreshProjects])

  // Construct our list of projects based on the temps and deletes.
  const origProjects = projects || []
  const retProjects = origProjects
    // Replace existing with temps
    .map((project) => tempProjects.find((t) => t?.id == project?.id) || project)
    // Add unfound temps at end.
    .concat(tempProjects.filter((t) => origProjects.findIndex((p) => p?.id == t?.id) == -1))
    // Remove deleted projects
    .filter((project) => deletingProjects.indexOf(project?.id || 0) == -1)

  return {
    projects: retProjects as any,
    isLoading,
    addProject,
    removeProject,
    removeProjects,
    updateProject: _updateProject,
    refreshProjects,
  }
}

export const listProjects = async (propertyId: number, propertyPlanId: number): Promise<Array<Project>> => {
  const api = new PropertyPlanApi(propertyId)
  return await api.listProjects(propertyPlanId)
}

export const createProject = async (
  propertyId: number,
  propertyPlanId: number,
  project: CProject
): Promise<Project> => {
  const api = new PropertyPlanApi(propertyId)
  return await api.createProject(propertyPlanId, project)
}

export const updateProject = async (
  propertyId: number,
  propertyPlanId: number,
  projectId: number,
  project: UProject
): Promise<Project> => {
  const api = new PropertyPlanApi(propertyId)
  return await api.updateProject(propertyPlanId, projectId, project)
}

export const deleteProject = async (
  propertyId: number,
  propertyPlanId: number,
  projectId: number
): Promise<Project | null> => {
  const api = new PropertyPlanApi(propertyId)
  return await api.deleteProject(propertyPlanId, projectId)
}

export const batchUpdateProject = async (
  propertyId: number,
  propertyPlanId: number,
  updates: Array<Update>
): Promise<Array<UpdateResult>> => {
  const api = new PropertyPlanApi(propertyId)
  return await api.batchUpdateProjects(propertyPlanId, updates)
}
