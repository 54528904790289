import jsonpcallback from 'jsonp'
import { Property } from 'recoil/properties'

interface BbmO {
  fundsLow: number
  fundsHigh: number
  lowRate: number
  highRate: number
}

export interface BbmObject {
  name: string
  response: BbmO | null
}

export interface BrownBagMortgageRequest {
  state: string
  city: string
  zip: string
  loan: number
  period?: string
  Loan_Type: string
  transaction: number
  fico: number | null
  cashout?: number
  ltv?: number
  heloc?: string
}

export interface BrownBagMortgageResponse {
  RATE: string
  MONTHLY_PAYMENT: string
  FEES: string
  financingOption?: string
  totalCost?: number
}

export interface Term {
  termName: string
  years: number
}

export const PURCHASE = 52
export const CASH_OUT_REFINANCE = 53
export const REFINANCE = 54

const DEFAULT_TERM: Term = {
  termName: 'PERIOD_FIXED_30YEARS',
  years: 30,
}

const LOAN_TYPE = 'Conv'
const RETURN_TYPE = 'json'

const terms: Term[] = [
  {
    termName: 'PERIOD_FIXED_10YEARS',
    years: 10,
  },
  {
    termName: 'PERIOD_FIXED_15YEARS',
    years: 15,
  },
  {
    termName: 'PERIOD_FIXED_20YEARS',
    years: 20,
  },
  {
    termName: 'PERIOD_FIXED_30YEARS',
    years: 30,
  },
  {
    termName: 'PERIOD_FIXED_40YEARS',
    years: 40,
  },
]

async function jsonp<T>(url: string): Promise<T> {
  return new Promise((resolve, reject) => {
    jsonpcallback(url, (error, data) => {
      if (error != null) reject(error)
      else resolve(data.result)
    })
  })
}

export function determineTerm(mortgageTerm: number | null): Term | undefined {
  if (mortgageTerm == null) {
    return DEFAULT_TERM
  }
  let closest: Term | undefined = undefined
  terms.forEach((term) => {
    if (closest == null || Math.abs(term.years - mortgageTerm) < Math.abs(closest.years - mortgageTerm)) {
      closest = term
    }
  })
  return closest
}

export function brownBagRequestBuilder(
  property: Property,
  creditScore: number,
  loan: number,
  transaction: number,
  cashout = 0,
  ltv: number | null = null,
  heloc = 'No',
  defaultTerm = true
): BrownBagMortgageRequest {
  const request: BrownBagMortgageRequest = {
    state: property.state,
    city: property.city,
    zip: property.zip5,
    loan: loan,
    Loan_Type: LOAN_TYPE,
    transaction: transaction,
    fico: creditScore,
    heloc: heloc,
    cashout: cashout,
  }
  if (defaultTerm == null) {
    request.period = determineTerm(property.blended_mortgage.term)?.termName
  }
  if (ltv != null) {
    request.ltv = ltv
  }
  return request
}

function parseBrownBagApiSearchRequest(request: BrownBagMortgageRequest): string {
  const searchParams = new URLSearchParams()
  searchParams.append('siteid', process.env.BROWN_BAG_SITE_ID || '')
  searchParams.append('type', RETURN_TYPE)
  Object.entries(request).forEach(([key, val]) => {
    if (val != null) {
      searchParams.append(key, val)
    }
  })
  return searchParams.toString()
}

export function runBrownBagApiRequest(request: BrownBagMortgageRequest): Promise<BrownBagMortgageResponse[]> {
  const urlQuery = parseBrownBagApiSearchRequest(request)
  return jsonp<BrownBagMortgageResponse[]>(`${process.env.BROWN_BAG_URL}?${urlQuery}`)
}

export const fetchFinancingOption = async (
  name: string,
  property: Property,
  alternativeRate: number | undefined,
  creditScore: number,
  low: number,
  high: number,
  transaction: number,
  cashoutLow = 0,
  cashoutHigh = 0,
  ltvLow: number | null = null,
  ltvHigh: number | null = null,
  heloc = 'No',
  defaultTerm = true
): Promise<BbmO | null> => {
  if (!property) return null

  const responses = await Promise.all([
    runBrownBagApiRequest(
      brownBagRequestBuilder(property, creditScore, low, transaction, cashoutLow, ltvLow, heloc, defaultTerm)
    ),
    runBrownBagApiRequest(
      brownBagRequestBuilder(property, creditScore, high, transaction, cashoutHigh, ltvHigh, heloc, defaultTerm)
    ),
  ])

  const combinedResponses: Array<any> = []
  if (responses[0] != undefined) {
    combinedResponses.push(...responses[0])
  }
  if (responses[1] != undefined) {
    combinedResponses.push(...responses[1])
  }
  if (combinedResponses.length == 0) {
    return null
  }

  let lowRate: number | null = null
  let highRate: number | null = null
  combinedResponses.forEach((response) => {
    const rate = parseFloat(response.RATE.replace(/%/g, ''))

    if (rate > (highRate || 0) || highRate == null) {
      highRate = rate
    }
    if (rate < (lowRate || 0) || lowRate == null) {
      lowRate = rate
    }
  })
  if (highRate == null || lowRate == null) {
    return null
  }

  let fundsLow = low + cashoutLow
  let fundsHigh = high + cashoutHigh

  if (name == 'Cash-Out Refinance') {
    // If we're cash out refinance, we just want to know the cash.
    fundsLow = cashoutLow
    fundsHigh = cashoutHigh

    if (alternativeRate != null) {
      highRate = Math.max(highRate, alternativeRate)
      lowRate = Math.min(lowRate, alternativeRate)
    }
  }

  return {
    fundsLow,
    fundsHigh,
    lowRate,
    highRate,
  }
}

export const fetchBest = async (property: Property, creditScore: number): Promise<BrownBagMortgageResponse | null> => {
  const principle = property.blended_mortgage?.principle || 0
  const results = await runBrownBagApiRequest(
    brownBagRequestBuilder(property, creditScore, principle, REFINANCE, 0, null, 'No', false)
  )
  const totalBlendedMortgageCost = Math.round(
    (property.blended_mortgage.monthly_payment || 0) * (property.blended_mortgage.term || 0) * 12
  )
  const penaltyByOutstandingPrinciple = Math.round((property.blended_mortgage.principle || 0) * 0.04)
  let best: BrownBagMortgageResponse | null = null
  if (results) {
    results.forEach((result) => {
      result.totalCost =
        parseInt(result.MONTHLY_PAYMENT.replace(/[()$,]/g, '')) *
          (determineTerm(property.blended_mortgage.term)?.years || 0) *
          12 +
        parseInt(result.FEES.replace(/[()$,]/g, '')) +
        penaltyByOutstandingPrinciple
      if (result.totalCost < totalBlendedMortgageCost && (best === null || result.totalCost < (best.totalCost || 0))) {
        best = result
      }
    })
  }
  return best
}

export const helocOption = async (
  property: Property,
  rate: number | undefined,
  creditScore: number
): Promise<BbmObject | null> => {
  let homeValueEquityDifference = property.estimated_value - property.equity
  if (property.equity == null) {
    homeValueEquityDifference = 0
  }
  let low = Math.round(0.8 * property.estimated_value - homeValueEquityDifference)
  const high = Math.round(0.9 * property.estimated_value - homeValueEquityDifference)
  if (low < 0) {
    low = 0
  }
  if (low <= 0 && high <= 0) {
    return null
  }

  return {
    name: 'Home Equity Line of Credit',
    response: await fetchFinancingOption(
      'Home Equity Line of Credit',
      property,
      rate,
      creditScore,
      low,
      high,
      PURCHASE,
      0,
      0,
      null,
      null,
      'Yes',
      false
    ),
  }
}

export const cashOutRefiOption = async (
  property: Property,
  rate: number | undefined,
  creditScore: number
): Promise<BbmObject | null> => {
  if (property.blended_mortgage.principle == null || property.blended_mortgage.principle == 0) {
    return null
  }

  const ltvLowFunds = 0.75 * property.estimated_value
  const ltvHighFunds = 0.85 * property.estimated_value
  let cashoutLow = Math.round(ltvLowFunds - property.blended_mortgage.principle)
  const cashoutHigh = Math.round(ltvHighFunds - property.blended_mortgage.principle)

  if (cashoutLow <= 0 && cashoutHigh <= 0) {
    return null
  }
  if (cashoutLow < 0) {
    cashoutLow = 0
  }

  return {
    name: 'Cash-Out Refinance',
    response: await fetchFinancingOption(
      'Cash-Out Refinance',
      property,
      rate,
      creditScore,
      property.blended_mortgage.principle,
      property.blended_mortgage.principle,
      CASH_OUT_REFINANCE,
      cashoutLow,
      cashoutHigh,
      0.75,
      0.85,
      'No',
      false
    ),
  }
}
