import { getJSON, postJSON, patchJSON, deleteJSON } from 'utils/fetch'

import { PropertyPlan as PropertyPlanBase, CPropertyPlan, UPropertyPlan } from 'recoil/propertyPlans'
import { Project, CProject, UProject, Update, UpdateResult } from 'recoil/projects'
import { ProjectTemplate } from 'recoil/projectTemplates'

export interface PropertyPlan extends PropertyPlanBase {
  projects: Array<Project>
}

function path(...pathParts: Array<string>): string {
  return (
    '/' +
    pathParts
      .map((p) => {
        const ret = p.endsWith('/') ? p.substring(0, p.length - 1) : p
        return ret.startsWith('/') ? ret.substring(1) : ret
      })
      .join('/')
  )
}

export default class PropertyPlanAPI {
  private pathPrefix: string

  constructor(propertyId: number) {
    this.pathPrefix = `/api/v1/properties/${propertyId}`
  }

  /* Property Plan */
  async listPropertyPlans(): Promise<Array<PropertyPlan>> {
    return await getJSON(path(this.pathPrefix, '/property_plans'))
  }

  async getPropertyPlan(propertyPlanId: number): Promise<PropertyPlan> {
    return await getJSON(path(this.pathPrefix, `/property_plans/${propertyPlanId}`))
  }

  async createPropertyPlan(propertyPlan: CPropertyPlan): Promise<PropertyPlan> {
    const res = await postJSON(path(this.pathPrefix, '/property_plans'), propertyPlan as Record<string, unknown>)

    if (res.isError) {
      throw new Error(`Non-200 status code: ${res.code}.`)
    }

    return res.jsonBody
  }

  async updatePropertyPlan(propertyPlanId: number, propertyPlan: UPropertyPlan): Promise<PropertyPlan> {
    const res = await patchJSON(
      path(this.pathPrefix, `/property_plans/${propertyPlanId}`),
      propertyPlan as Record<string, unknown>
    )

    if (res.isError) {
      throw new Error(`Non-200 status code: ${res.code}.`)
    }
    return res.jsonBody
  }

  async deletePropertyPlan(propertyPlanId: number): Promise<PropertyPlan | null> {
    const res = await deleteJSON(path(this.pathPrefix, `/property_plans/${propertyPlanId}`))

    // 404 on a delete is 'ok'
    if (res.isError && res.code != 404) {
      throw new Error(`Non-200 status code: ${res.code}.`)
    }
    return res.jsonBody || null
  }

  /* Projects */
  async listProjects(propertyPlanId: number): Promise<Array<Project>> {
    return await getJSON(path(this.pathPrefix, `/property_plans/${propertyPlanId}/projects`))
  }

  async createProject(propertyPlanId: number, project: CProject): Promise<Project> {
    const res = await postJSON(
      path(this.pathPrefix, `/property_plans/${propertyPlanId}/projects`),
      project as unknown as Record<string, unknown>
    )

    if (res.isError) {
      throw new Error(`Non-200 status code: ${res.code}.`)
    }
    return res.jsonBody
  }

  async updateProject(propertyPlanId: number, projectId: number, project: UProject): Promise<Project> {
    const res = await patchJSON(
      path(this.pathPrefix, `/property_plans/${propertyPlanId}/projects/${projectId}`),
      project as Record<string, unknown>
    )

    if (res.isError) {
      throw new Error(`Non-200 status code: ${res.code}.`)
    }
    return res.jsonBody
  }

  async deleteProject(propertyPlanId: number, projectId: number): Promise<Project | null> {
    const res = await deleteJSON(path(this.pathPrefix, `/property_plans/${propertyPlanId}/projects/${projectId}`))

    // 404 on a delete is 'ok'
    if (res.isError && res.code != 404) {
      throw new Error(`Non-200 status code: ${res.code}.`)
    }
    return res.jsonBody || null
  }

  async batchUpdateProjects(propertyPlanId: number, updates: Array<Update>): Promise<Array<UpdateResult>> {
    const res = await patchJSON(path(this.pathPrefix, `/property_plans/${propertyPlanId}/projects`), { batch: updates })

    if (res.isError) {
      throw new Error(`Non-200 status code: ${res.code}.`)
    }
    return res.jsonBody
  }

  async previewUpdateProject(
    propertyPlanId: number,
    projectId: number,
    project: UProject
  ): Promise<{ project: Project; property_plan: PropertyPlan }> {
    const res = await postJSON(
      path(this.pathPrefix, `/property_plans/${propertyPlanId}/projects/${projectId}/previews`),
      project as Record<string, unknown>
    )

    if (res.isError) {
      throw new Error(`Non-200 status code: ${res.code}.`)
    }
    return res.jsonBody
  }

  /* Project Templates */
  async listProjectTemplates(propertyPlanId?: number): Promise<Array<ProjectTemplate>> {
    // Decide if this is for a basic projectTemplates list based off of property
    // or if it is for a specific property plan.
    let subPath = '/project_templates'
    if (propertyPlanId) subPath = `/property_plans/${propertyPlanId}${subPath}`
    return await getJSON(path(this.pathPrefix, subPath))
  }
}
