import React, { FC, PropsWithChildren, useEffect } from 'react'
import { useRecoilValue, useSetRecoilState, useRecoilState } from 'recoil'

import {
  LoginState,
  loginState as rLoginState,
  loginChecked as rLoginChecked,
  loginError as rLoginError,
  userState,
  getUser,
  userState as rUserState,
  User,
} from 'recoil/user'
import { propertiesState, listProperties, propertiesErrorState } from 'recoil/properties'

export { LoginState } // Reexport here so people don't have to hunt for it.

interface UseAuth {
  state: LoginState
  checked: boolean
  error: Error | null
  user: User | null
}

export function useAuth(): UseAuth {
  const loginState = useRecoilValue(rLoginState)
  const loginChecked = useRecoilValue(rLoginChecked)
  const loginError = useRecoilValue(rLoginError)
  const user = useRecoilValue(rUserState)

  return {
    checked: loginChecked,
    error: loginError,
    state: loginState,
    user,
  }
}

const Authentication: FC<PropsWithChildren> = ({ children }) => {
  const setUser = useSetRecoilState(userState)
  const setProperties = useSetRecoilState(propertiesState)
  const setPropertiesError = useSetRecoilState(propertiesErrorState)
  const setLoginState = useSetRecoilState(rLoginState)
  const [loginChecked, setLoginChecked] = useRecoilState(rLoginChecked)
  const setLoginError = useSetRecoilState(rLoginError)

  // Begin the login process.
  useEffect(() => {
    // We only want to run this the first time.
    if (loginChecked) return

    const _ = async () => {
      // Start logging in.
      setLoginState(LoginState.LoggingIn)
      try {
        const user = await getUser()
        setUser(user)

        // Start downloading some data.
        setLoginState(LoginState.Loading)

        try {
          // We want to prefetch some data on login

          // Grab our properties
          const properties = await listProperties()
          setProperties(properties)
        } catch (e) {
          // Don't let these errors fall to login exception handler
          // We will log in and not have property plan data
          setPropertiesError(e)
          const win = window as any
          win.Sentry ? win.Sentry.captureException(e) : console.error(e)
        }

        // We're in!
        setLoginState(LoginState.LoggedIn)
      } catch (err) {
        // Save the error for possible use later.
        if (err['error']) setLoginError(err['error'])
        setLoginState(LoginState.LoggedOut)
      } finally {
        // At this point we've checked everything
        setLoginChecked(true)
      }
    }
    _()
  }, [loginChecked, setLoginChecked, setLoginError, setLoginState, setProperties, setPropertiesError, setUser])

  return <>{children}</>
}

export default Authentication
