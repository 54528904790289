import React, { FC, useState, useCallback } from 'react'
import { Mortgage } from 'recoil/mortgages'

import { formatCurrency } from 'utils/formatting'

import ArrowLink from 'components/ArrowLink'

import Alert from '../Alert'

import classNames from 'classnames'

import styles from './styles.module.scss'
import { useRouteMatch } from 'react-router-dom'

interface MortgageDetailsProps {
  amount: number | null
  rate: number | null
  term: number | null
  origination_date: string | null
}

interface MortgagesDetailsProps {
  mortgages: Mortgage[]
}

interface FinancialDetailsProps {
  mortgages: Mortgage[]
  equityState: 'normal' | 'low_data' | 'missing_data' | 'no_data' | 'no_mortgage'
}

// converts string of form yyyy-mm-dd to form mm / dd / yyyy
function formatDate(date: string): string {
  const regexResult = date.match(/([0-9]*)-([0-9]*)-([0-9]*)/)
  if (!regexResult) return ''
  return `${regexResult[2]} / ${regexResult[3]} / ${regexResult[1]}`
}

const MortgageDetails: FC<MortgageDetailsProps> = ({ amount, rate, term, origination_date }) => {
  return (
    <div className={styles.detailsContainer}>
      <div className={styles.financialDescription}>
        <div>{'Amount'}</div>
        <div>{'Rate'}</div>
        <div>{'Term'}</div>
        <div>{'Origination Date'}</div>
      </div>
      <div className={styles.value}>
        <div
          className={classNames({
            [styles.unknown]: amount == null,
          })}
        >
          {amount != null ? formatCurrency(amount) : 'Unknown'}
        </div>
        <div
          className={classNames({
            [styles.unknown]: rate == null,
          })}
        >
          {rate != null ? `${rate}%` : 'Unknown'}
        </div>
        <div
          className={classNames({
            [styles.unknown]: term == null,
          })}
        >
          {term != null ? `${term} years` : 'Unknown'}
        </div>
        <div
          className={classNames({
            [styles.unknown]: origination_date == null,
          })}
        >
          {origination_date != null ? formatDate(origination_date) : 'Unknown'}
        </div>
      </div>
    </div>
  )
}

const MortgagesDetails: FC<MortgagesDetailsProps> = ({ mortgages }) => {
  if (mortgages == null || mortgages.length == 0) {
    return null
  } else if (mortgages.length == 1) {
    return (
      <MortgageDetails
        amount={mortgages[0].amount || 0}
        rate={mortgages[0].rate || 0}
        term={mortgages[0].term || 0}
        origination_date={mortgages[0].origination_date || ''}
      />
    )
  } else {
    return (
      <>
        {mortgages.map((mortgage, i) => {
          return (
            <div key={i}>
              <div className={styles.mortgageNumber}>{`Loan ${i + 1}`}</div>
              <MortgageDetails
                amount={mortgage.amount || 0}
                rate={mortgage.rate || 0}
                term={mortgage.term || 0}
                origination_date={mortgage.origination_date || ''}
              />
            </div>
          )
        })}
      </>
    )
  }
}

const FinancialDetails: FC<FinancialDetailsProps> = ({ mortgages, equityState }) => {
  const { url } = useRouteMatch()

  const [isAlertVisible, setIsAlertVisible] = useState<boolean>(true)
  const CloseAlert = useCallback(() => {
    setIsAlertVisible(false)
  }, [setIsAlertVisible])

  return (
    <div className={styles.financialDetails}>
      <h2 className={styles.mainHeader}>{'Financial details'}</h2>
      {equityState === 'no_mortgage' ? (
        <>
          <p>{"Here's what we know:"}</p>
          <div className="tw-mb-4">{'No mortgage or home loan.'}</div>
        </>
      ) : (
        <>
          <p>
            {mortgages != null && mortgages.length > 1
              ? "Here's what we know about your current mortgages or loans."
              : "Here's what we know about your current mortgage or loan."}
          </p>
          <MortgagesDetails mortgages={mortgages} />
          {isAlertVisible && (
            <Alert className={styles.alert} closeAlert={CloseAlert}>
              <div className={classNames({ [styles.alertGood]: equityState == 'normal' })}>
                {equityState == 'normal' ? 'Is your loan information correct?' : 'We are missing loan details'}
              </div>
            </Alert>
          )}
        </>
      )}
      <ArrowLink className={styles.arrowLink} to={`${url}/financial-analysis`}>
        {'Update your financial details'}
      </ArrowLink>
    </div>
  )
}

export default FinancialDetails
