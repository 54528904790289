import React, { FC } from 'react'

interface InfoProps {
  className?: string
}

const Svg: FC<InfoProps> = ({ className }) => (
  <svg
    width="836"
    height="354"
    viewBox="0 0 836 354"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M770.301 103L768.908 104.393L768.88 114.822C768.88 144.085 765.945 147.049 736.697 147.049H726.252L724.859 148.442V158.558L726.252 159.951L736.681 159.979C765.945 159.979 768.908 162.915 768.908 192.162V202.607L770.301 204H780.418L781.811 202.607L781.838 192.178C781.838 162.915 784.774 159.951 814.021 159.951H824.467L825.859 158.558V158.531V148.442L824.467 147.049L814.037 147.021C784.774 147.021 781.811 144.085 781.811 114.838V104.393L780.418 103H770.301Z"
      fill="currentColor"
    />
    <path
      d="M677.26 170L675.164 172.096L675.122 187.792C675.122 231.831 668.691 238.305 624.675 238.305H608.955L606.859 240.4V251.599L608.955 253.695L624.651 253.737C668.691 253.737 675.164 260.169 675.164 304.184V319.904L677.26 322H688.459L690.555 319.904L690.597 304.208C690.597 260.169 697.028 253.695 741.044 253.695H756.763L758.859 251.6V251.558V240.4L756.763 238.305L741.068 238.263C697.028 238.263 690.555 231.831 690.555 187.816V172.096L688.459 170H677.26Z"
      fill="currentColor"
    />
    <path
      d="M397.949 1.81991V176.385L399.768 178.205H418.847L420.665 176.385V1.81991L418.847 0H399.768L397.949 1.81991Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M254.169 143.232L254.004 143.718C250.125 155.112 243.602 164.61 234.503 171.257C225.402 177.906 213.782 181.66 199.787 181.66C181.517 181.66 166.621 174.827 156.305 162.994C146.002 151.175 140.337 134.451 140.337 114.768C140.337 95.0859 146.002 78.362 156.305 66.5433C166.621 54.7102 181.517 47.8765 199.787 47.8765C218.044 47.8765 232.338 54.3319 242.048 65.9419C251.738 77.5273 256.77 94.1312 256.77 114.275V115.923L252.485 120.211H169.385L163.847 125.974C165.113 135.41 168.494 144.331 174.273 150.911C180.124 157.573 188.482 161.892 199.787 161.892C215.397 161.892 225.445 153.998 231.462 142.918L231.531 142.791L233.653 140.668H252.339L254.169 143.232ZM170.606 101.923H227.487L232.985 96.4216C231.255 87.648 228.016 80.5279 222.805 75.5757C217.51 70.5443 210.07 67.6451 199.787 67.6451C189.905 67.6451 182.321 70.7731 176.663 75.9582C171.111 81.0465 167.347 88.1769 165.139 96.4517L170.606 101.923Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M351.555 91.2356C350.777 84.0709 348.27 78.1791 344.008 74.0581C339.67 69.8635 333.403 67.3984 324.973 67.3984C317.356 67.3984 310.961 69.1523 306.01 72.4158C301.067 75.6732 297.504 80.473 295.594 86.6819L295.521 86.9193L293.327 89.1155H275.38L273.594 86.6132L273.683 86.1852C275.816 75.891 280.721 66.2968 289.095 59.2773C297.474 52.2526 309.244 47.8765 324.973 47.8765C342.086 47.8765 354.34 53.2588 362.306 62.024C370.257 70.772 373.818 82.761 373.818 95.7659V176.385L372 178.205H354.154L352.335 176.385V165.372H346.39C338.273 174.552 325.051 181.167 308.943 181.167C296.713 181.167 286.416 178.234 279.155 172.434C271.864 166.61 267.743 157.98 267.743 146.851C267.743 135.858 271.36 126.995 279.525 119.905C287.634 112.864 300.158 107.636 317.849 103.674L346.143 97.3286L351.555 91.2356ZM296.054 158.593C299.731 161.23 305.226 162.632 312.396 162.632C324.85 162.632 334.618 158.284 341.28 150.93C347.875 143.65 351.509 133.333 351.594 121.13L346.387 116.354L324.212 121.419L324.211 121.419C309.331 124.865 300.906 128.706 296.199 132.913C291.576 137.044 290.46 141.603 290.46 146.851C290.46 152.117 292.429 155.994 296.054 158.593Z"
      fill="currentColor"
    />
    <path
      d="M541.074 64.658C549.643 53.5538 561.901 47.8765 575.603 47.8765C589.369 47.8765 599.797 51.9541 606.759 60.1036C613.697 68.2234 617.049 80.2204 617.049 95.7659V176.385L615.23 178.205H596.151L594.333 176.385V98.2338C594.333 85.9889 591.878 78.5429 587.53 74.1339C583.191 69.7335 576.747 68.1387 568.204 68.1387C560.45 68.1387 554.112 70.8682 549.676 76.8066C545.203 82.7937 542.569 92.1693 542.569 105.637V176.385L540.75 178.205H521.671L519.852 176.385V98.2338C519.852 85.9889 517.398 78.5429 513.05 74.1339C508.71 69.7335 502.267 68.1387 493.724 68.1387C485.97 68.1387 479.632 70.8682 475.196 76.8066C470.723 82.7937 468.088 92.1693 468.088 105.637V176.385L466.27 178.205H447.191L445.372 176.385V53.1514L447.191 51.3315H466.27L468.088 53.1514V59.9691H473.509C479.687 52.2709 489.96 47.8765 501.122 47.8765C509.63 47.8765 516.46 49.4418 522.002 52.406C527.385 55.285 531.492 59.4508 534.73 64.658H541.074Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.09868 177.5L0 175.401V2.38261L2.09868 0.283936H72.748C90.4268 0.283936 105.271 4.73369 115.719 13.6351C126.203 22.5665 132.076 35.8237 132.076 53.0239C132.076 66.3622 128.455 77.0136 121.962 85.2174C115.622 93.2274 106.634 98.7903 95.8903 102.288L93.9312 108.166L140.712 174.296L138.424 177.5H115.455L113.149 175.853L64.1558 106.385L62.5993 105.52H30.6693L25.448 110.741V175.401L23.3493 177.5H2.09868ZM30.6693 83.2439H70.308C82.4748 83.2439 91.3937 80.8274 97.258 75.9643C103.065 71.1489 106.14 63.7097 106.14 53.0239C106.14 42.3104 103.23 34.8596 97.8006 30.052C92.3341 25.2119 84.0401 22.8039 72.748 22.8039H30.6693L25.448 28.0253V78.0226L30.6693 83.2439Z"
      fill="currentColor"
    />
  </svg>
)

export default Svg
