import React, { FC, useState, useCallback } from 'react'
import { useRouteMatch } from 'react-router-dom'

import { formatCurrency } from 'utils/formatting'

import ArrowLink from 'components/ArrowLink'
import Tooltip from 'components/Tooltip'

import Alert from '../Alert'

import styles from './styles.module.scss'

export interface MortgageDetails {
  currentMonthlyPayment: number
  currentPrinciple: number
  currentRate: number
  currentTerm: number
  alternativeMonthlyPayment?: number
  alternativeRate?: number
  alternativeTerm?: number
}

interface Props {
  equityState: 'normal' | 'low_data' | 'missing_data' | 'no_data' | 'no_mortgage'
  details: MortgageDetails
}

function CreateMonthlySavings(details: MortgageDetails) {
  if (!details.currentMonthlyPayment || !details.alternativeMonthlyPayment) {
    return null
  }

  return formatCurrency(details.currentMonthlyPayment - details.alternativeMonthlyPayment)
}

function Create15YearsSavings(details: MortgageDetails) {
  if (
    details?.currentTerm == null ||
    details?.currentMonthlyPayment == null ||
    details?.alternativeTerm == null ||
    details?.alternativeMonthlyPayment == null
  ) {
    return null
  }

  const currentTotalTerms = details.currentTerm < 15 ? details.currentTerm : 15
  const currentCost = details.currentMonthlyPayment * currentTotalTerms * 12
  const alternativeTotalTerms = details.alternativeTerm < 15 ? details.alternativeTerm : 15
  const altnerativeCost = details.alternativeMonthlyPayment * alternativeTotalTerms * 12

  // technically, this could only happen if we got a very long term for a property mortgage
  // but in that situation we wouldn't see the savings neccessarily within the first 15 years
  return currentCost - altnerativeCost >= 0 ? formatCurrency(currentCost - altnerativeCost) : null
}

const MortgageAnalysis: FC<Props> = ({ equityState, details }) => {
  const { url } = useRouteMatch()
  const [isAlertVisible, setIsAlertVisible] = useState<boolean>(true)
  const closeAlert = useCallback(() => {
    setIsAlertVisible(false)
  }, [setIsAlertVisible])

  return (
    <div className={styles.mortgageAnalysis}>
      <Tooltip
        className={styles.toolTip}
        trackAs="mortgage-analysis"
        overlay="We look at your current blended mortgage rate compared to the most competitive rates that would be available to you given your financial profile & current market rates. We include an estimate of your refinancing penalty in our savings projection."
      />
      <div>{'Monthly payment savings breakdown'}</div>
      <div className={styles.analysisBreakdown}>
        <div className={styles.calculation}>
          <div className={styles.leftHandSide}>
            <div>
              <div className={styles.description}>{'Current Rate'}</div>
              <div className={styles.badRate}>
                {details?.currentRate != null ? `${details.currentRate}%` : 'Unknown'}
              </div>
            </div>
            <div className={styles.operator}>{'-'}</div>
            <div>
              <div className={styles.description}>{'Market Rate'}</div>
              <div>{details?.alternativeRate != null ? `${details.alternativeRate}%` : 'Unknown'}</div>
            </div>
          </div>
          <div className={styles.operator}>{'='}</div>
          <div className={styles.rightHandSide}>
            <div>
              <div className={styles.description}>{'Potential Monthly Savings'}</div>
              <div className={styles.savings}>
                {CreateMonthlySavings(details) != null ? CreateMonthlySavings(details) : 'Unknown'}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.interestRateSavings}>
        <div>
          <div className={styles.description}>{'Potential Total Savings over 15 years'}</div>
          <div className={styles.savings}>
            {Create15YearsSavings(details) != null ? Create15YearsSavings(details) : 'Unknown'}
          </div>
        </div>
      </div>
      {equityState != 'normal' && isAlertVisible && (
        <Alert className={styles.alert} closeAlert={closeAlert}>
          {'Please update your loan information to see more accurate data.'}
        </Alert>
      )}
      <ArrowLink className={styles.arrowLink} to={`${url}/financial-analysis`}>
        {'Update your financial details'}
      </ArrowLink>
    </div>
  )
}

export default MortgageAnalysis
