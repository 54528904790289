import { getJSON, postJSON, patchJSON } from 'utils/fetch'

import { Package } from 'recoil/packages'
import { PackagePurchase, CPackagePurchase, UPackagePurchase } from 'recoil/packagePurchases'

function path(...pathParts: Array<string>): string {
  return (
    '/' +
    pathParts
      .map((p) => {
        const ret = p.endsWith('/') ? p.substring(0, p.length - 1) : p
        return ret.startsWith('/') ? ret.substring(1) : ret
      })
      .join('/')
  )
}

export default class ReportAPI {
  private pathPrefix: string
  private propertyPathPrefix: string

  constructor(propertyId?: number) {
    this.pathPrefix = `/api/v1`
    if (propertyId) this.propertyPathPrefix = `${this.pathPrefix}/properties/${propertyId}`
  }

  /* Reports */
  async listPackages(): Promise<Array<Package>> {
    // This path is public, and not dependent on your property
    return await getJSON(path(this.pathPrefix, '/packages'))
  }

  /* ReportPurchases */
  async listPackagePurchases(): Promise<Array<PackagePurchase>> {
    if (!this.propertyPathPrefix) throw "No 'propertyId' supplied with call"
    return await getJSON(path(this.propertyPathPrefix, '/package_purchases'))
  }

  async createPackagePurchase(package_purchase: CPackagePurchase): Promise<PackagePurchase> {
    if (!this.propertyPathPrefix) throw "No 'propertyId' supplied with call"
    const res = await postJSON(
      path(this.propertyPathPrefix, '/package_purchases'),
      package_purchase as unknown as Record<string, unknown>
    )

    if (res.isError) {
      throw new Error(`Non-200 status code: ${res.code}.`)
    }
    return res.jsonBody
  }

  async updatePackagePurchase(
    package_purchase_id: number,
    package_purchase: UPackagePurchase,
    payment_id: number
  ): Promise<PackagePurchase> {
    if (!this.propertyPathPrefix) throw "No 'propertyId' supplied with call"
    const data = { payment_id, ...package_purchase }
    const res = await patchJSON(
      path(this.propertyPathPrefix, `/package_purchases/${package_purchase_id}`),
      data as unknown as Record<string, unknown>
    )

    if (res.isError) {
      throw new Error(`Non-200 status code: ${res.code}.`)
    }
    return res.jsonBody
  }
}
