import React, { FC, PropsWithChildren } from 'react'
import { AlertType } from 'recoil/alert'
import cx from 'classnames'

import styles from './Alert.module.scss'

export interface AlertProps {
  type?: AlertType
  className?: string
}

export const Alert: FC<PropsWithChildren<AlertProps>> = ({ className, children, type }) => {
  const altClass = `alert-${(type || 'ERROR').toLowerCase()}`
  return <div className={cx(styles.alert, styles[altClass], className)}>{children}</div>
}

export default Alert
