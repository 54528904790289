import { useCallback } from 'react'
import { atom, useRecoilState } from 'recoil'
import { identify, track } from 'utils/analytics'
import { FetchResponse, getJSON, patchJSON } from 'utils/fetch'
import { postJSON } from 'utils/fetch'

import { BudgetOption, OnboardingState, OutsideBidSelection, UserGoal } from 'recoil/onboarding'

export interface UserStub {
  id: number
  email: string
  full_name: string
  homeowner_type: 'resident' | 'investment' | 'buy' | 'curious'
  phone_number: string
  entered_address: string | null
  completed_profile: boolean
  sha1_email: string
  referred_by_user: number | null
  created_at: string
}

export interface User extends UserStub {
  entered_zip5: string | null
  primary_income: number | null
  secondary_income: number | null
  misc_income: number | null
  mortgage_payment: number | null
  car_payment: number | null
  child_support: number | null
  alimony: number | null
  misc_reoccurring_debts: number | null
  has_password: boolean
  credit_score: number | null
  credit_score_high: number | null
  credit_score_low: number | null
  credit_selection: number | null
  payment_due: boolean
  profile_image: string
  unconfirmed_email: string | null
  social_facebook: string | null
  social_instagram: string | null
  social_linkedin: string | null
  social_twitter: string | null
  project_customized: boolean
  outside_bids: {
    name: string
    url: string
    id: number
    size: string
  }[]
}

export interface UUser {
  full_name?: string
  email?: string
  unconfirmed_email?: string
  phone_number?: string

  current_password?: string
  password?: string
  password_confirmation?: string

  homeowner_type?: 'resident' | 'investment' | 'buy' | 'curious'
  entered_address?: string
  hear_about_us?: string
  referred_by_user?: number
  signup_message?: string
  goals?: Array<string>
  outside_bids_status?: OutsideBidSelection

  receives_partner_communications?: boolean
  completed_profile?: boolean
  project_customized?: boolean

  social_facebook?: string
  social_instagram?: string
  social_linkedin?: string
  social_twitter?: string

  primary_income?: number
  secondary_income?: number
  misc_income?: number
  mortgage_payment?: number
  car_payment?: number
  child_support?: number
  alimony?: number
  misc_reoccurring_debts?: number
  additional_cash_to_invest?: number
  credit_score?: number
  credit_selection?: number
}

export const userState = atom<User | null>({
  key: 'User',
  default: null,
  effects_UNSTABLE: [
    ({ onSet }) => {
      onSet(async (user) => {
        if (!user) return
        identify(user)
      })
    },
  ],
})

export enum LoginState {
  LoggedOut,
  LoggingIn,
  Loading,
  LoggedIn,
}

export const loginState = atom<LoginState>({
  key: 'LoginState',
  default: LoginState.LoggedOut,
})

export const loginChecked = atom<boolean>({
  key: 'LoginChecked',
  default: false,
})

export const loginError = atom<Error | null>({
  key: 'LoginError',
  default: null,
})

export const getUser = (): Promise<User> => {
  return getJSON<User>('/api/v1/users/me', { cache: 'no-store' })
}

export const markAccessActivityFeed = (property_id?: string | number): Promise<FetchResponse> => {
  return patchJSON('/api/v1/users/mark_access_activity_feed', { property_id })
}

export type NonAppSignupType = 'homeshow' | 'non-homeshow-event' | 'deposit' | 'unserviceable' | 'other'

export interface SignUpState {
  full_name: string
  ambassador_name: string
  email: string
  password?: string
  entered_address: string

  onboarding: OnboardingState

  propertyId?: number
  attomId?: string
  placeId?: string

  phoneNumber?: string
  non_app_signup_type?: NonAppSignupType
  bypass_signin?: boolean

  utmParams?: Record<string, string>
}

interface CUser {
  full_name: string
  ambassador_name?: string
  email: string
  password?: string
  entered_address: string

  phone_number?: string
  goals?: UserGoal[]
  homeowner_type?: 'resident' | 'investment' | 'buy' | 'curious'
  referred_by_user?: number
  signup_message?: string
  non_app_signup_type?: NonAppSignupType
  outside_bids_status?: OutsideBidSelection
  budget?: BudgetOption

  completed_profile: boolean
  utm_params?: Record<string, string>
}

export interface SignUpResponse {
  user: UserStub
  property_id?: number
  pollable_job_token?: string
}

export async function signUp(state: SignUpState, campaignId?: string): Promise<SignUpResponse> {
  if (state.password && state.password.length < 8) {
    throw { password: ['Password must be at least 8 characters.'] }
  }

  const userBody: CUser = {
    full_name: state.full_name,
    ambassador_name: state.ambassador_name,
    email: state.email,
    password: state.password,
    entered_address: state.entered_address,
    non_app_signup_type: state.non_app_signup_type,
    outside_bids_status: state.onboarding.outsideBids,
    budget: state.onboarding.budget,
    completed_profile: true,
    utm_params: state.utmParams,
  }

  if (state.phoneNumber) {
    userBody.phone_number = state.phoneNumber
  }

  if (state.propertyId) {
    userBody.entered_address = `property_id:${state.propertyId}`
  } else if (state.attomId) {
    userBody.entered_address = `attom_id:${state.attomId}`
  } else if (state.placeId) {
    userBody.entered_address = `place_id:${state.placeId}`
  }

  if (!userBody['entered_address']) {
    throw { entered_address: ['Must pick a result'] }
  }

  if (state.onboarding.goals) {
    userBody.goals = state.onboarding.goals
  }

  if (state.onboarding.homeownerType) {
    userBody.homeowner_type = state.onboarding.homeownerType
  }

  if (state.onboarding.propertyShare) {
    userBody.referred_by_user = state.onboarding.propertyShare.user_id
  }

  if (state.onboarding.additionalInfo) {
    userBody.signup_message = state.onboarding.additionalInfo
  }

  const response = await postJSON('/users', {
    user: userBody,
    property_id: state.propertyId,
    campaign_id: campaignId,
    bypass_signin: state.bypass_signin,
  })
  if (response.isError) {
    await track('signup error', {
      error: 'error creating user',
      data: response.jsonBody,
    })
    throw response.jsonBody
  }

  const signupResponse: SignUpResponse = response.jsonBody

  // call identify before tracking the signup event so that we have Realm's User Id already in place.
  await identify(signupResponse.user)

  await track(
    'signup',
    {
      $email: state.email,
      $name: state.full_name,
      address: state.entered_address,
      via: 'form',
      signupDate: signupResponse.user.created_at,
      is_marketplace: state.onboarding.marketplace,
    },
    {
      id: 24230,
      args: {
        orderId: `signup-${signupResponse.user.id}`,
        customerId: signupResponse.user.id,
        customerEmail: signupResponse.user.sha1_email,
      },
    }
  )

  return signupResponse
}

function dropOnewayProperties(user: UUser): any {
  const temp = { ...user }
  delete temp.password
  delete temp.password_confirmation
  delete temp.current_password
  return temp
}

export const useUser = (): {
  user: User
  updateUser: (user: UUser) => Promise<{ user: User; pollableJobToken?: string }>
} => {
  const [user, setUser] = useRecoilState(userState)

  return {
    user: user as User,
    updateUser: useCallback(
      async (userUpdate: UUser, campaignId = null) => {
        const oldUser = { ...user }
        // Update the user object immediately with a temp
        setUser((prev) => ({ ...prev, ...dropOnewayProperties(userUpdate) }))

        let newUser: User, pollableJobToken: string | undefined
        try {
          const results = await updateUser(userUpdate, campaignId)
          newUser = results[0]
          pollableJobToken = results[1]
          setUser(newUser)
        } catch (err) {
          // If we failed to update the user, revert the temp changes.
          setUser(oldUser as User)
          throw err
        }

        return { user: newUser, pollableJobToken }
      },
      [user, setUser]
    ),
  }
}

export const updateUser = async (user: UUser, campaignId = null): Promise<[User, string | undefined]> => {
  const res = await postJSON('/api/v1/users/me', { user: user, campaign_id: campaignId })

  if (res.isError) {
    const error = new Error(`Non-200 status code: ${res.code}.`)
    throw Object.assign(error, { body: res.jsonBody })
  }

  // When updating a user, it is possible to trigger the creation of a property,
  // so we actually need to return both the new user, and a pollable token.
  return [res.jsonBody.user as User, res.jsonBody.pollable_job_token]
}
