import cx from 'classnames'
import React, { FC } from 'react'

interface TrendingUpProps {
  className?: string
}

const TrendingUp: FC<TrendingUpProps> = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    className={cx('trending-up', className)}
  >
    <path d="M0 0h24v24H0V0z" fill="none" />
    <path fill="currentColor" d="M16 6l2.29 2.29-4.88 4.88-4-4L2 16.59 3.41 18l6-6 4 4 6.3-6.29L22 12V6h-6z" />
  </svg>
)

export default TrendingUp
