import React, { FC } from 'react'

import LogoIcon from 'svgs/logo'
import InstagramIcon from 'svgs/social/instagram'
import FacebookIcon from 'svgs/social/facebook'
import TwitterIcon from 'svgs/social/twitter'
import LinkedInIcon from 'svgs/social/linkedin'

import styles from './styles.module.scss'

const Footer: FC = () => (
  <footer className={styles.footerContainer}>
    <div className={styles.footer}>
      <div className={styles.colophon}>
        <LogoIcon className={styles.logo} />
        <p>
          {'© '}
          {new Date().getFullYear()}
          {' Realm. All Rights Reserved.'}
        </p>
      </div>
      <div className={styles.social}>
        <a href="https://www.instagram.com/realm/" target="_blank" rel="noreferrer">
          <InstagramIcon />
        </a>
        <a href="https://www.facebook.com/realmhome" target="_blank" rel="noreferrer">
          <FacebookIcon />
        </a>
        <a href="https://twitter.com/realmhome" target="_blank" rel="noreferrer">
          <TwitterIcon />
        </a>
        <a href="https://www.linkedin.com/company/realmhome/" target="_blank" rel="noreferrer">
          <LinkedInIcon />
        </a>
      </div>
    </div>
  </footer>
)

export default Footer
