import React, { FC, PropsWithChildren } from 'react'
import classNames from 'classnames'
import styles from './FunkyCard.module.scss'
export interface FunkyCardProps {
  className?: string
  title: string
  description?: string
  background?: JSX.Element
}
const FunkyCard: FC<PropsWithChildren<FunkyCardProps>> = ({ children, className, title, description, background }) => (
  <div className={classNames(styles.funkyCard, className)}>
    <div className={styles.header}>
      <div className={styles.patterns}>{background}</div>
      <h2 className={styles.title}>{title}</h2>
      <h4 className={styles.description}>{description ?? <p>{description}</p>}</h4>
    </div>
    <div className={styles.body}>{children}</div>
  </div>
)
export default FunkyCard
