import { useState, useEffect } from 'react'
import { atom, selector, useRecoilState } from 'recoil'
import type { SetterOrUpdater } from 'recoil'

import { Model } from 'recoil/model'
import { useProperties } from 'recoil/properties'

import { getJSON } from 'utils/fetch'
import sharedAction from 'utils/sharedAction'

export interface Mortgage extends Model {
  id: number
  amount?: number
  origination_date?: string
  property_id?: number
  rate?: number
  term?: number
}

export const mortgagesState = atom<Mortgage[] | null>({
  key: 'Mortgages',
  default: null,
})

export const mortgagesFetchedIdState = atom<number | null>({
  key: 'MortgagesFetchedId',
  default: null,
})

export const useMortgages = (): {
  mortgages: Array<Mortgage>
  isLoading: boolean
  setMortgages: SetterOrUpdater<Mortgage[]>
} => {
  const [fetchedId, setFetchedId] = useRecoilState(mortgagesFetchedIdState)
  const [mortgages, setMortgages] = useRecoilState(mortgagesState)
  const [isLoading, setIsLoading] = useState(false)
  const { selectedProperty: property } = useProperties()

  useEffect(() => {
    if (!property) return
    const runme = async () => {
      if (mortgages == null || fetchedId != property.id) {
        setIsLoading(true)

        const newMortgages = await sharedAction('getMortgages', () => getMortgages(property.id))

        setMortgages(newMortgages)
        setFetchedId(property.id)

        setIsLoading(false)
      }
    }
    runme()
  }, [property, setMortgages, mortgages, fetchedId, setFetchedId])
  return { mortgages: mortgages || [], isLoading, setMortgages }
}

export const getMortgages = async (propertyId: number): Promise<Mortgage[]> => {
  return getJSON<Mortgage[]>(`/api/v1/properties/${propertyId}/mortgages`)
}

export const mortgagesMapState = selector({
  key: 'MortgagesMap',
  get: ({ get }) => {
    const mortgages = get(mortgagesState)
    return mortgages?.reduce((a, e) => {
      a[e.id] = e
      return a
    }, {})
  },
})
