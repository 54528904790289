import React, { FC, useCallback } from 'react'
import classnames from 'classnames'

import { styles as inputStyles } from 'components/Input'
import Tooltip from 'components/Tooltip'

import { formatMoneyNoDecimals } from 'utils/formatting'

import styles from './styles.module.scss'

interface CurrencyInputProps {
  className?: string
  label: string
  value: number
  trackAs: string
  toolTipContent: string
  onChange: (any) => void
}

const parseCurrency = (newAmount: string): number | undefined => {
  if (newAmount.match(/[^0-9$,]/g) != null) return
  const newAmountAsNumber = parseFloat(newAmount.replace(/[$,]/g, ''))
  return isNaN(newAmountAsNumber) ? 0 : newAmountAsNumber
}

const CurrencyInput: FC<CurrencyInputProps> = ({ className, label, value, trackAs, toolTipContent, onChange }) => {
  const handleAmountChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      // TODO: update with logic to keep the cursor in place on change with target selection methods
      // (ie: selectionStart and setSelectionRange)
      const newValue = parseCurrency(e.currentTarget.value)
      if (newValue != null) onChange(newValue)
    },
    [onChange]
  )

  return (
    <div className={classnames(inputStyles.input, styles.input, className)}>
      <label>{label}</label>
      <Tooltip className={styles.inputTooltip} trackAs={trackAs} overlay={toolTipContent} />
      <input
        className={styles.input}
        placeholder={'e.g. $80,000'}
        type={'text'}
        value={formatMoneyNoDecimals(value)}
        onChange={handleAmountChange}
      />
    </div>
  )
}

export default CurrencyInput
