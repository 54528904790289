import React, { FC } from 'react'

interface Props {
  className?: string
}

const Svg: FC<Props> = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="771" height="771" viewBox="0 0 771 771" className={className}>
    <g fill="none" fillRule="evenodd" opacity="1">
      <g stroke="currentColor" strokeWidth="2.4">
        <g>
          <g>
            <g transform="translate(-207 -2174) translate(200 2511) translate(7 -337)">
              <circle cx="385.5" cy="385.5" r="26.793" />
              <circle cx="385.5" cy="385.5" r="77.98" />
              <circle cx="385.5" cy="385.5" r="129.166" />
              <circle cx="385.5" cy="385.5" r="180.353" />
              <circle cx="385.5" cy="385.5" r="231.54" />
              <circle cx="385.5" cy="385.5" r="282.727" />
              <circle cx="385.5" cy="385.5" r="333.913" />
              <circle cx="385.5" cy="385.5" r="385.1" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default Svg
