// TODO: This component is huuuuuge
// It should have a serious examination with the intent to
// break into into individual components.

import React, { FC, useCallback, useEffect, useReducer } from 'react'

import { FormErrors } from 'utils/forms'
import { track } from 'utils/analytics'
import { userState } from 'recoil/user'
import { LifecycleStatuses } from 'recoil/model'
import { mortgagesState, Mortgage, useMortgages, getMortgages } from 'recoil/mortgages'
import { useProperties } from 'recoil/properties'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { v4 as uuidv4 } from 'uuid'

import PropertyDetails from './PropertyDetails'
import FinancialForm from './Forms/FinancialForm'

import PageTitle from 'components/PageTitle'
import Spinner from 'components/Spinner'
import FunkyCard from 'components/FunkyCard'
import FancyButton from 'components/FancyButton'
import IntercomCta from 'components/IntercomCta'

import Circles from 'svgs/edit-property/patterns-circles'
import Waves from 'svgs/edit-property/patterns-waves'
import Lines from 'svgs/edit-property/patterns-lines'
import Privacy from 'svgs/finance-hub/privacy'

import Card from 'components/Card'
import ArrowLink from 'components/ArrowLink'
import { postJSON } from 'utils/fetch'
import { alertState } from 'recoil/alert'
import MortgageCard from 'pages/FinanceHub/FinancialAnalysis/MortgageCard'

import styles from './styles.module.scss'

export type FinancialAnalysisFormAction = {
  type:
    | 'addMortgage'
    | 'removeMortgage'
    | 'setLoading'
    | 'setNoMortgage'
    | 'setMortgage'
    | 'setMortgages'
    | 'reallySetMortgages'
    | 'setMortgageField'
    | 'setState'
    | 'setFields'
  payload?: Record<string, unknown>
}

export interface FinancialAnalysisFormState {
  user: {
    additional_cash_to_invest: string
    alimony: string
    car_payment: string
    child_support: string
    credit_score: string
    credit_selection: string
    misc_income: string
    misc_reoccurring_debts: string
    mortgage_payment: string
    primary_income: string
    secondary_income: string
  }
  errors: FormErrors
  isLoading: boolean
  mortgages: Mortgage[]
}

export const userRemoteToForm = (user: FinancialAnalysisFormState['user']): FinancialAnalysisFormState['user'] => ({
  additional_cash_to_invest: user.additional_cash_to_invest || '',
  alimony: user.alimony || '',
  car_payment: user.car_payment || '',
  child_support: user.child_support || '',
  credit_score: user.credit_score ? user.credit_score.toString() : '',
  credit_selection: user.credit_selection ? user.credit_selection.toString() : '',
  misc_income: user.misc_income || '',
  misc_reoccurring_debts: user.misc_reoccurring_debts || '',
  mortgage_payment: user.mortgage_payment || '',
  primary_income: user.primary_income || '',
  secondary_income: user.secondary_income || '',
})

// Flexible parser that removes any commas and converts into a float
export const parseNumber = (value: string | number): number | null => {
  return value != null ? parseFloat(value.toString().replace(',', '')) : null
}

const initState = ({ mortgages, property, user }) => ({
  user: userRemoteToForm(user),
  mortgages:
    mortgages !== null && mortgages.length > 0
      ? mortgages
      : property.equity_state !== 'no_mortgage'
        ? [{ id: uuidv4(), lifecycle_status: LifecycleStatuses.NeedsCreated, amount: 0 }]
        : [],
  errors: {},
  isLoading: false,
})

const reducer = (
  state: FinancialAnalysisFormState,
  action: FinancialAnalysisFormAction
): FinancialAnalysisFormState => {
  switch (action.type) {
    case 'addMortgage':
      return {
        ...state,
        mortgages: [...state.mortgages, { id: uuidv4(), lifecycle_status: LifecycleStatuses.NeedsCreated, amount: 0 }],
      }
    case 'removeMortgage': {
      const i = state.mortgages.findIndex((m) => m.id == action.payload?.mortgageId)
      if (i == -1) {
        return state
      }

      const updatedMortgages = [...state.mortgages]
      updatedMortgages.splice(i, 1)
      return { ...state, mortgages: updatedMortgages }
    }
    case 'setLoading':
      return { ...state, isLoading: true }
    case 'setMortgages':
      return {
        ...state,
        mortgages:
          (action.payload?.mortgages as Mortgage[]).length > 0
            ? (action.payload?.mortgages as Mortgage[])
            : state.mortgages,
      }
    case 'reallySetMortgages':
      return {
        ...state,
        mortgages: action.payload?.mortgages as Mortgage[],
      }
    case 'setMortgage': {
      const i = state.mortgages.findIndex((m) => m.id == action.payload?.originalMortgageId)
      if (i == -1) {
        return state
      }

      const updatedMortgages = [...state.mortgages]
      updatedMortgages[i] = action.payload?.mortgage as Mortgage
      return { ...state, mortgages: updatedMortgages }
    }
    case 'setMortgageField': {
      const i = state.mortgages.findIndex((m) => m.id == action.payload?.mortgageId)
      if (i == -1) {
        return state
      }

      const updatedMortgages = [...state.mortgages]
      const updatedMortgage = { ...updatedMortgages[i] }
      updatedMortgage[action.payload?.f as string] = action.payload?.val
      updatedMortgages[i] = updatedMortgage
      return { ...state, mortgages: updatedMortgages }
    }
    case 'setState':
      return { ...state, ...action.payload }
    case 'setFields':
      return {
        ...state,
        user: {
          ...state.user,
          [action.payload?.f as string]: action.payload?.val,
        },
      }
    default:
      throw new Error()
  }
}

const FinancialAnalysis: FC = () => {
  const user = useRecoilValue(userState)
  const { selectedProperty: property, refreshProperty } = useProperties()
  const { mortgages, isLoading: isLoadingMortgages } = useMortgages()

  const [state, dispatch] = useReducer(
    reducer,
    {
      mortgages,
      property,
      user,
    },
    initState
  )

  useEffect(() => {
    const fetchMortgages = async () => {
      if (isLoadingMortgages == false) {
        dispatch({
          type: 'setMortgages',
          payload: {
            mortgages: mortgages,
          },
        })
      }
    }
    fetchMortgages()
  }, [mortgages, isLoadingMortgages])

  const handleChange = useCallback(
    (f: keyof FinancialAnalysisFormState['user']) => {
      return (val) => {
        dispatch({
          type: 'setFields',
          payload: { f, val },
        })
      }
    },
    [dispatch]
  )

  const fieldProps = useCallback(
    (f: keyof FinancialAnalysisFormState['user']): { onChange: (value: any) => void; value: any } => {
      return {
        onChange: handleChange(f),
        value: state.user[f],
      }
    },
    [handleChange, state.user]
  )

  const handleAddMortgage = useCallback(() => {
    track('submit form', { section: 'finance', form: 'mortgage-details', action: 'add' })

    dispatch({
      type: 'addMortgage',
    })
  }, [])

  const setMortgages = useSetRecoilState(mortgagesState)
  const setAlert = useSetRecoilState(alertState)

  const handleSetNoMortgage = useCallback(async () => {
    if (!property?.id) return

    track('submit form', { section: 'finance', form: 'mortgage-details', action: 'no-mortgage' })

    await postJSON(`/api/v1/properties/${property.id}/set_free_of_mortgage`, {})
    await postJSON(`/api/v1/properties/${property.id}/finances`, {})
    await refreshProperty(property)
    const mortgages = await getMortgages(property.id)
    setMortgages(mortgages)
    dispatch({
      type: 'reallySetMortgages',
      payload: {
        mortgages: mortgages,
      },
    })
    setAlert({ type: 'SUCCESS', message: 'Successfully updated your loan info.', id: Date.now() })
  }, [property, refreshProperty, setAlert, setMortgages])

  if (isLoadingMortgages) {
    return (
      <div className="tw-text-center tw-mt-5">
        <Spinner size="xl" />
      </div>
    )
  }

  if (!property) return null

  return (
    <div className={styles.financialAnalysis}>
      <PageTitle title="Financial Analysis" />
      <h2 className={styles.title}>{'Financial Analysis'}</h2>
      <p className={styles.titleDescription}>{'Learn what you can do with your home equity!'}</p>
      <div className={styles.content}>
        <div className={styles.propertyAndPrivacyContainer}>
          <div className={styles.propertyAndPrivacy}>
            <PropertyDetails className={styles.propertyDetails} />
            {property.marketplace && (
              <div className={styles.helpBox}>
                {'Questions about your home wealth?'}
                <IntercomCta className={styles.advisorCta} location="in-page">
                  {'Chat with a Realm Advisor'}
                </IntercomCta>
              </div>
            )}
            <Card className={styles.privacy}>
              <h2>
                <Privacy />
                {'Our Privacy Promise'}
              </h2>
              <p>
                {
                  'We use your financial data to provide personalized recommendations for your projects. We keep this data private and do not share it with other customers or 3rd party partners.'
                }
              </p>
            </Card>
          </div>
        </div>
        <div className={styles.editDetails}>
          {state.mortgages
            .filter((m) => m.lifecycle_status !== LifecycleStatuses.NeedsDeleted)
            .map((mortgage, index) => (
              <MortgageCard
                key={mortgage.id}
                state={state}
                dispatch={dispatch}
                mortgage={mortgage}
                index={index}
                handleAddMortgage={handleAddMortgage}
                handleSetNoMortgage={handleSetNoMortgage}
              />
            ))}
          {state.mortgages.length == 0 && (
            <FunkyCard
              className={styles.card}
              title="Mortgage details"
              description="Add or edit your existing mortgage details, add additional loans if necessary"
              background={<Circles className={styles.circles} />}
            >
              <div className={styles.noMortgage}>{'No mortgage or home loan listed on this property'}</div>
              <div className={styles.addMortgageContainer}>
                <FancyButton onClick={handleAddMortgage} className={styles.addMortgageLarge}>
                  {'Add a mortgage or home loan'}
                </FancyButton>
                <FancyButton onClick={handleAddMortgage} className={styles.addMortgageSmall}>
                  {'Add'}
                </FancyButton>
                {property.equity_state !== 'no_mortgage' && (
                  <FancyButton onClick={handleSetNoMortgage}>{'No mortgage or home loan'}</FancyButton>
                )}
              </div>
            </FunkyCard>
          )}
          <FunkyCard
            className={styles.card}
            title="Income"
            description="Your current monthly income and credit score"
            background={<Waves className={styles.waves} />}
          >
            <FinancialForm
              formType={'income'}
              dispatch={dispatch}
              incomeProps={fieldProps}
              property={property}
              user={state.user}
              refreshProperty={refreshProperty}
              loading={state.isLoading}
            />
          </FunkyCard>
          <FunkyCard
            className={styles.card}
            title="Debts"
            description="Your current monthly recurrent debts"
            background={<Lines className={styles.lines} />}
          >
            <FinancialForm
              formType={'debt'}
              dispatch={dispatch}
              incomeProps={fieldProps}
              property={property}
              user={state.user}
              refreshProperty={refreshProperty}
              loading={state.isLoading}
            />
          </FunkyCard>
          <div className={styles.financeHubRedirect}>
            <h2>{'Done editing?'}</h2>
            <p>
              {
                'Hit save and see how these edits to your financial analysis impact your equity details and recommendations on your financing page.'
              }
            </p>
            <ArrowLink to="../finance-hub">{'View your financial details'}</ArrowLink>
          </div>
          <Card className={styles.privacy}>
            <h2>
              <Privacy />
              {'Our Privacy Promise'}
            </h2>
            <p>
              {
                'We use your financial data to provide personalized recommendations for your projects. We keep this data private and do not share it with other customers or 3rd party partners.'
              }
            </p>
          </Card>
        </div>
      </div>
    </div>
  )
}

export default FinancialAnalysis
