import React, { FC, PropsWithChildren } from 'react'
import { Redirect } from 'react-router-dom'

import propertyPath from 'utils/propertyPath'
import { useProperties, usePropertyLoader, Property } from 'recoil/properties'

import Loading from 'components/Loading'

export function missingInformation(property: Property): boolean {
  return (
    !property.bedrooms ||
    !property.bathrooms ||
    typeof property.half_bathrooms == 'undefined' ||
    !property.square_footage
  )
}

interface Props {
  loadingContent?: () => React.ReactNode
  noPropertyContent?: () => React.ReactNode
}

const HasProperty: FC<PropsWithChildren<Props>> = ({ loadingContent, noPropertyContent, children }) => {
  const { selectedProperty, properties } = useProperties()
  const { isLoading, redirect } = usePropertyLoader()

  if (isLoading) {
    if (loadingContent) return <> {loadingContent()} </>
    else return <Loading />
  } else if (!selectedProperty) {
    if (noPropertyContent) return <> {noPropertyContent()} </>
    else return <Redirect to="/properties" />
  } else if (properties && missingInformation(selectedProperty)) {
    return <Redirect to={`${propertyPath(properties, selectedProperty)}/missing-information`} />
  } else if (redirect) {
    // If we've been asked to redirect by the hook, do so.
    return <Redirect to={redirect} />
  }

  return <>{children}</>
}

export default HasProperty
