import React, { FC, ReactNode } from 'react'

import classNames from 'classnames'

import Close from 'svgs/close'

import styles from './styles.module.scss'

interface Props {
  className?: string
  closeAlert: () => void
  children?: ReactNode
}

const Alert: FC<Props> = ({ className, closeAlert, children }) => (
  <div className={classNames(styles.alert, className)}>
    <div className={styles.content}>{children}</div>
    <button type="button" className={styles.close} onClick={closeAlert}>
      <Close />
    </button>
  </div>
)

export default Alert
