import React, { FC } from 'react'

import { useProperties } from 'recoil/properties'

import classnames from 'classnames'

import Card from 'components/Card'
import BubbleList from 'components/BubbleList'
import ArrowLink from 'components/ArrowLink'

import styles from './styles.module.scss'
import { PropertyAvatar } from 'components/PropertyAvatar'

const PropertyDetails: FC<{ className?: string }> = ({ className }) => {
  const { selectedProperty: property } = useProperties()

  if (!property) return null

  return (
    <Card className={classnames(className, styles.propertyDetails)}>
      <PropertyAvatar className={styles.avatar} property={property} width={160} height={160} mapbox />
      <div className={styles.address}>{property.delivery_line_1}</div>
      <div className={styles.cityStateZip}>
        {property.city}
        {', '}
        {property.state}
        {', '}
        {property.zip5}
      </div>
      <BubbleList className={styles.steps}>
        <li>{'Confirm your existing mortgage & loan details'}</li>
        <li>{'Enter your monthly income and reoccuring debts'}</li>
        <li>{"We'll calculate accessible funds for you"}</li>
      </BubbleList>
      <div className={styles.explanation}>
        {'Confirming and providing these details help us calculate the right financial '}
        {'details and increase the accuracy of our recommendations.'}
      </div>
      <ArrowLink className={styles.link} to="../finance-hub">
        {'View your financial details'}
      </ArrowLink>
    </Card>
  )
}

export default PropertyDetails
