import React, { FC, useCallback } from 'react'
import cx from 'classnames'

import { useProperties, PropertyHead } from 'recoil/properties'

import { track } from 'utils/analytics'

export interface Props {
  className?: string
  location: 'in-page' | 'header'
}

const IntercomCta: FC<React.PropsWithChildren<Props>> = ({ children, className, location }) => {
  const { selectedProperty } = useProperties()

  // Fire event based on location and market.
  const handleClick = useCallback(() => {
    const property = selectedProperty as PropertyHead
    const market = property.marketplace ? 'marketplace' : null
    track('click cta', { cta: 'intercom', location: location, market: market })
  }, [selectedProperty, location])

  // Don't show if we don't have a property, or we're a national user.
  if (!selectedProperty || !selectedProperty.marketplace) return null

  let intercomCtaClass = '_intercom-cta'
  if (selectedProperty.marketplace) intercomCtaClass += '-marketplace'

  intercomCtaClass += `-${location}`

  return (
    <button type="button" className={cx(className, intercomCtaClass)} onClick={handleClick}>
      {children}
    </button>
  )
}
export default IntercomCta
