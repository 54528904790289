import { getJSON, postJSON, patchJSON, deleteJSON } from 'utils/fetch'

import { poll } from 'utils/poll'
import { PropertyHead, Property, CProperty, UProperty, CreatePropertyErrorType } from 'recoil/properties'

export function path(...pathParts: Array<string>): string {
  return (
    '/' +
    pathParts
      .map((p) => {
        const ret = p.endsWith('/') ? p.substring(0, p.length - 1) : p
        return ret.startsWith('/') ? ret.substring(1) : ret
      })
      .join('/')
  )
}

export interface PropertyPollResponse {
  error?: Record<string, unknown>
  result?: { id: number }
}

const QualificationStatuses = {
  qualified: 'qualified',
  unserviceable: 'unserviceable',
}

export default class PropertyAPI {
  private pathPrefix: string

  constructor() {
    this.pathPrefix = `/api/v1`
  }

  /* Property */
  async listProperties(): Promise<Array<PropertyHead>> {
    return await getJSON(path(this.pathPrefix, `/properties`))
  }

  async getProperty(propertyId: number): Promise<Property> {
    return await getJSON(path(this.pathPrefix, `/properties/${propertyId}`))
  }

  async createProperty(property: CProperty): Promise<Property> {
    // Creating a property is a multi-step process involving polling.

    // First start the property creation.
    const res = await postJSON(path(this.pathPrefix, `/properties`), property as Record<string, unknown>)

    if (res.isError) {
      const error = new Error(`Non-200 status code: ${res.code}.`)
      throw Object.assign(error, { type: CreatePropertyErrorType.CreateStartError, body: res.jsonBody })
    }
    // Also an error state. We need to be able to poll for our results.
    else if (!res.jsonBody.pollable_job_token) {
      const error = new Error(`Create Property result missing 'pollable_job_token'.`)
      throw Object.assign(error, { type: CreatePropertyErrorType.MissingPollToken, body: res.jsonBody })
    }

    // Then begin polling for completion
    const token = res.jsonBody.pollable_job_token

    let pollResultResponse: PropertyPollResponse
    try {
      pollResultResponse = await poll<PropertyPollResponse>({
        fn: async () => await this.getPropertyPoll(token),
        validate: (pollResponse: PropertyPollResponse) => !!(pollResponse.error || pollResponse.result),
        interval: 2000,
        maxAttempts: 10,
      })
    } catch (err) {
      const error = new Error(`Create Property poll timed out: ${err}`)
      throw Object.assign(error, { type: CreatePropertyErrorType.PollTimeout })
    }

    if (pollResultResponse.error) {
      const error = new Error(`Create Property poll returned failure: ${pollResultResponse.error}`)
      throw Object.assign(error, { type: CreatePropertyErrorType.PollError })
    }

    // Then get the new property.
    const id = pollResultResponse.result?.id
    if (!id) {
      const error = new Error(`Create Property poll returned no id: ${pollResultResponse.result}`)
      throw Object.assign(error, { type: CreatePropertyErrorType.PollError })
    }
    let newProperty
    try {
      newProperty = await this.getProperty(id)
    } catch (err) {
      const error = new Error(`Failed to retrieve new property: ${err}.`)
      throw Object.assign(error, { type: CreatePropertyErrorType.RetrievalFailure })
    }

    // Make sure the property is servicable.
    if (newProperty.qualification_status === QualificationStatuses.unserviceable) {
      const error = new Error(`Property is unservicable.`)
      throw Object.assign(error, { type: CreatePropertyErrorType.UnservicableProperty })
    }

    return newProperty
  }

  async updateProperty(propertyId: number, property: UProperty): Promise<Property> {
    const res = await patchJSON(path(this.pathPrefix, `/properties/${propertyId}`), property as any)

    if (res.isError) {
      const error = new Error(`Non-200 status code: ${res.code}.`)
      throw Object.assign(error, { body: res.jsonBody })
    }
    return res.jsonBody
  }

  async deleteProperty(propertyId: number): Promise<Property | null> {
    const res = await deleteJSON(path(this.pathPrefix, `/properties/${propertyId}`))

    // 404 on a delete is 'ok'
    if (res.isError && res.code != 404) {
      const error = new Error(`Non-200 status code: ${res.code}.`)
      throw Object.assign(error, { body: res.jsonBody })
    }
    return res.jsonBody || null
  }

  private async getPropertyPoll(token: string): Promise<PropertyPollResponse> {
    return await getJSON(path(this.pathPrefix, `/pollable_jobs/${token}`))
  }
}
