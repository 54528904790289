import React, { FC, useCallback } from 'react'

import { useSetRecoilState } from 'recoil'

import { Property } from 'recoil/properties'
import { alertState } from 'recoil/alert'
import { userState } from 'recoil/user'

import { FinancialAnalysisFormAction, FinancialAnalysisFormState, parseNumber, userRemoteToForm } from '..'

import GeneralForm from 'components/GeneralForm'

import { postJSON } from 'utils/fetch'
import { track } from 'utils/analytics'

interface FinancialFormProps {
  formType: 'income' | 'debt'
  dispatch: React.Dispatch<FinancialAnalysisFormAction>
  incomeProps: (f: keyof FinancialAnalysisFormState['user']) => { onChange: (value: any) => void; value: any }
  property: Property
  user: FinancialAnalysisFormState['user']
  refreshProperty: (property: Property) => Promise<Property>
  loading?: boolean
}

const FinancialForm: FC<FinancialFormProps> = ({
  formType,
  dispatch,
  incomeProps,
  property,
  user,
  refreshProperty,
  loading,
}) => {
  const setUser = useSetRecoilState(userState)

  const setAlert = useSetRecoilState(alertState)

  const userUpdate = useCallback(async () => {
    dispatch({ type: 'setLoading' })

    track('submit form', { section: 'finance', form: formType })

    // Update the user info
    const userResponse = await postJSON('/api/v1/users/me', {
      user: {
        additional_cash_to_invest: user.additional_cash_to_invest,
        alimony: parseNumber(user.alimony),
        car_payment: parseNumber(user.car_payment),
        child_support: parseNumber(user.child_support),
        credit_score: user.credit_score,
        credit_selection: user.credit_selection,
        misc_income: parseNumber(user.misc_income),
        misc_reoccurring_debts: parseNumber(user.misc_reoccurring_debts),
        mortgage_payment: parseNumber(user.mortgage_payment),
        primary_income: parseNumber(user.primary_income),
        secondary_income: parseNumber(user.secondary_income),
      },
    })

    if (userResponse.isError && userResponse.jsonBody) {
      dispatch({
        type: 'setState',
        payload: {
          errors: userResponse.jsonBody,
          isLoading: false,
        },
      })
      const errors = { ...userResponse.jsonBody }
      track('equity calc estimates error', { errors, propertyId: property.id })
      setAlert({ type: 'ERROR', message: 'Error updating your loan info.', id: Date.now() })
      return
    }
    setUser(userResponse.jsonBody.user)
    dispatch({
      type: 'setState',
      payload: {
        errors: {},
        user: userRemoteToForm(userResponse.jsonBody.user),
      },
    })

    // Post an empty update to finances, so we can do any necessary recalculations.
    await postJSON(`/api/v1/properties/${property.id}/finances`, {})

    dispatch({
      type: 'setState',
      payload: {
        errors: {},
        isLoading: false,
      },
    })

    track('equity save calc estimates', { propertyId: property.id })
    setAlert({
      type: 'SUCCESS',
      message: 'Successfully updated your loan info.',
      id: Date.now(),
      ctaText: 'View details',
      ctaTo: '../finance-hub',
    })

    await refreshProperty(property)
  }, [dispatch, property, user, setUser, refreshProperty, setAlert, formType])

  if (formType == 'income') {
    return (
      <GeneralForm
        inputProps={incomeProps}
        onSave={userUpdate}
        formFields={[
          {
            name: 'primary_income',
            labelName: 'Primary Income',
            dataType: 'currency',
            tooltipContent:
              'Your main source of income, most often your monthly salary from a full-time job. Use $0 if this does not apply. Divide yearly salary by 12, to estimate.',
            trackAsTag: 'edit-income-primary',
          },
          {
            name: 'secondary_income',
            labelName: 'Secondary Income',
            dataType: 'currency',
            tooltipContent:
              "Your second source of monthly income. You can use this box to input your spouse's income (if married) or use for things like 'Rental income' from an investment property. Use $0 if this does not apply.",
            trackAsTag: 'edit-income-secondary',
          },
          {
            name: 'misc_income',
            labelName: 'Misc. Income',
            dataType: 'currency',
            tooltipContent:
              'Any additional, steady monthly income that you make. This can include long term investment returns, rental property income, alimony payments you receive, etc. Use $0 if this does not apply.',
            trackAsTag: 'edit-income-misc',
          },
          {
            name: 'additional_cash_to_invest',
            labelName: 'Additional Cash to Invest',
            dataType: 'currency',
            tooltipContent:
              'Input the ammount of cash you have to put toward the project, seperate from funds that can be accessed through a loan.',
            trackAsTag: 'edit-income-additional',
          },
          {
            name: 'credit_score',
            labelName: 'Credit Score',
            dataType: 'number',
            tooltipContent: 'Please input your current (precise) credit score.',
            trackAsTag: 'edit-income-credit',
            placeholder: 'Enter your credit score...',
            maxVal: 850,
            minVal: 0,
          },
        ]}
        loading={loading}
      />
    )
  } else {
    return (
      <GeneralForm
        inputProps={incomeProps}
        onSave={userUpdate}
        formFields={[
          {
            name: 'mortgage_payment',
            labelName: 'Mortgage Payment',
            dataType: 'currency',
            tooltipContent: 'The amount you currently pay monthly for your mortgage. Use $0 if this does not apply.',
            trackAsTag: 'edit-debt-mortgage',
          },
          {
            name: 'car_payment',
            labelName: 'Car Payment',
            dataType: 'currency',
            tooltipContent:
              'If you have a loan for a vehicle, or multiple, input total monthly spend here, or $0 if this does not apply.',
            trackAsTag: 'edit-debt-car',
          },
          {
            name: 'child_support',
            labelName: 'Child Support',
            dataType: 'currency',
            tooltipContent: 'Any amount you pay to someone else for child support. Use $0 if this does not apply.',
            trackAsTag: 'edit-debt-child',
          },
          {
            name: 'alimony',
            labelName: 'Alimony',
            dataType: 'currency',
            tooltipContent:
              'Any amount you pay to someone else for alimony (commonly, payment to ex-spouse from divorce settlements). Use $0 if this does not apply.',
            trackAsTag: 'edit-debt-alimony',
          },
          {
            name: 'misc_reoccurring_debts',
            labelName: 'Misc. Reoccuring Debts',
            dataType: 'currency',
            tooltipContent:
              'Any other recurring debts you make monthly payments on (perhaps, outstanding credit card bills, other personal loans you have taken out, college tuition payments for a child, etc.) Use $0 if this does not apply.',
            trackAsTag: 'edit-debt-misc',
          },
        ]}
        loading={loading}
      />
    )
  }
}

export default FinancialForm
