import { atom } from 'recoil'
import { ReactNode } from 'react'

export type AlertType = 'ERROR' | 'WARNING' | 'SUCCESS'

export interface Alert {
  type: AlertType
  message: ReactNode
  id: number | string
  ctaText?: string
  ctaTo?: string
}

export const alertState = atom<Alert | null>({
  key: 'Alert',
  default: null,
})
