import React, { FC, ReactNode, useCallback } from 'react'
import cx from 'classnames'
import RcTooltip from 'rc-tooltip'

import { track } from 'utils/analytics'
import Help from 'svgs/help'

import styles from './styles.module.scss'

interface TooltipProps {
  className?: string
  overlay: ReactNode | (() => ReactNode)
  placement?: string
  trackAs?: string
}

const Tooltip: FC<TooltipProps> = ({ overlay, className, placement = 'topLeft', trackAs }) => {
  const handleVisibleChange = useCallback(
    (visible: boolean) => {
      if (!trackAs || !visible) return
      track('hover tooltip', { tooltip: trackAs })
    },
    [trackAs]
  )

  return (
    <div className={cx(styles.tooltip, className)}>
      <RcTooltip
        transitionName="zoom-big-fast"
        overlayClassName={styles.overlay}
        placement={placement}
        overlay={overlay}
        onVisibleChange={handleVisibleChange}
      >
        <div>
          <Help />
        </div>
      </RcTooltip>
    </div>
  )
}

export default Tooltip
